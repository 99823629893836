import { RouteObject } from "react-router-dom";
import { DashboardRouteGenerator } from "../../../core/layout/dashboard/domain/implementation/Route";
import { MainRouteGenerator } from "../../../core/layout/main/domain/implementation/Route";
import { AppComponent } from "../../presentation/component/AppComponent";

export const AppRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppComponent />,
    children: new DashboardRouteGenerator().mix(new MainRouteGenerator()),
  },
];
