import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppCustomer } from "../../../app/hook/Customer";
import { useLoginViewModel } from "./ViewModel";
import { notification } from "antd";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomerLogo } from "../../../core/presentation/component/CustomerLogo";
import { FindCustomerByEmailForm } from "./component/FindCustomer";
import { CodeVerificationForm } from "./component/VerificationForm";
import { LoginForm } from "./component/LoginForm";
import { DashboardPaths } from "../../../core/layout/dashboard/domain/implementation/Paths";

export const AuthPage: FC = () => {
  const navigate = useNavigate();
  const { setCustomer, customer } = useAppCustomer();
  const {
    login,
    loginState,
    postLoginState,
    onLoginStateReceived,
    onPostLoginStateReceived,
    verificateCode,
    resendCode,
    resendCodeState,
    onResendCodeStateReceived,
    fetchCustomerDomain,
    fetchCustomerState,
    onFetchCustomerDomainStateReceived,
    loginEmail,
    resetLoginEmail,
    codeDuration,
  } = useLoginViewModel();
  const [postLoginEnabled, setPostLoginEnabled] = useState<boolean>();

  const onLoginFormBackPressed = () => {
    resetLoginEmail();
    setPostLoginEnabled(false);
    setCustomer(null!);
  };

  const onCodeFormBackPressed = () => {
    setPostLoginEnabled(false);
  };

  useEffect(() => {
    if (!!loginEmail && !customer) {
      fetchCustomerDomain({
        email: loginEmail,
      });
    }
  }, []);

  useEffect(() => {
    if (!!loginState && !loginState.loading) {
      if (loginState.hasError) {
        notification.error({
          message: "Inicio de Sesión",
          description: loginState.error?.message,
        });
      } else {
        setPostLoginEnabled(true);
      }
      onLoginStateReceived();
    }
  }, [loginState]);

  useEffect(() => {
    if (!!fetchCustomerState && !fetchCustomerState.loading) {
      if (fetchCustomerState.hasError) {
        notification.error({
          message: "Inicio de Sesión",
          description: fetchCustomerState.error?.message,
        });
      } else {
        setCustomer(fetchCustomerState.data!!);
      }
      onFetchCustomerDomainStateReceived();
    }
  }, [fetchCustomerState, onFetchCustomerDomainStateReceived, setCustomer]);

  useEffect(() => {
    if (!!postLoginState && !postLoginState.loading) {
      if (postLoginState.hasError) {
        notification.error({
          message: "Verificación",
          description: postLoginState.error?.message,
        });
      } else {
        navigate(DashboardPaths.overview);
      }
      onPostLoginStateReceived();
    }
  }, [postLoginState]);

  useEffect(() => {
    if (!!resendCodeState && !resendCodeState.loading) {
      if (resendCodeState.hasError) {
        notification.error({
          message: "Reenviar código de verificación",
          description: resendCodeState.error?.message,
        });
      } else {
        notification.success({
          message: "Código Reenviado",
          description: "Se ha enviado un nuevo código a su correo.",
        });
      }
      onResendCodeStateReceived();
    }
  }, [resendCodeState]);

  return (
    <div className="flex flex-col items-start md:max-w-sm lg:max-w-md px-6 py-10 h-fit w-full gap-4 md:card">
      <AppLoader
        loading={
          (!!loginState && loginState.loading) ||
          (!!postLoginState && postLoginState.loading) ||
          (!!resendCodeState && resendCodeState.loading) ||
          (!!fetchCustomerState && fetchCustomerState.loading)
        }
      />
      <div className="max-w-xs h-12 overflow-hidden">
        <CustomerLogo />
      </div>
      {!customer && !loginEmail ? (
        <FindCustomerByEmailForm onSubmit={fetchCustomerDomain} />
      ) : postLoginEnabled ? (
        <CodeVerificationForm
          codeDuration={codeDuration}
          onBack={onCodeFormBackPressed}
          onSubmit={verificateCode}
          onResend={resendCode}
        />
      ) : (
        <LoginForm onSubmit={login} onBack={onLoginFormBackPressed} />
      )}
    </div>
  );
};
