import { FC, useEffect, useMemo } from "react";
import { GaugeChart } from "./Gauge";
import { APP_CHART_TYPES, ChartData } from "../../../domain/type/ChartConfig";
import { AppEmptyContentStateComponent } from "../State";
import { VehicleChart } from "../../../../features/gateway/domain/type/VehicleChart";
import { useComponentSize } from "../../../../app/hook/Size";
import { LiquidLevelChart } from "./LiquidLevel";
import { LinearChart } from "./Linear";
import { HistoricalDeviceData } from "../../../domain/type/DeviceData";
import { LinearHistoricalChart } from "./LinearHistorical";
import { BarHistoricalChart } from "./BarHistorical";
type Props = {
  chart: VehicleChart;
  data: HistoricalDeviceData[] | null;
};

export type HistoricalChartProps<T = {}, E = {}> = {
  chart: VehicleChart<T>;
  data: HistoricalDeviceData[] | null;
  width: number;
  height: number;
};

export function HistoricalDeviceDataRender({ chart, data }: Props) {
  const { ref, width, height } = useComponentSize();
  const Element = useMemo(() => {
    const chartType = chart.ChartDefinition.ChartSchema.type;
    switch (chartType) {
      case APP_CHART_TYPES.historical_linear:
        return LinearHistoricalChart;
      case APP_CHART_TYPES.top_end:
        return BarHistoricalChart;
      default:
        return InvalidGraphic;
    }
  }, [chart]);

  return (
    <div ref={ref} className="flex flex-row flex-wrap">
      <Element
        chart={chart as any}
        data={data as any}
        width={width}
        height={height}
      />
    </div>
  );
}

const InvalidGraphic: FC<HistoricalChartProps> = ({ chart }) => {
  return (
    <div className={"flex flex-col items-center justify-center-4"}>
      <AppEmptyContentStateComponent
        description={`La configuración para ${chart.ChartDefinition.name} no es válida.`}
        title={"Gráfico inválido"}
      />
    </div>
  );
};
