export type AppRegularColorPalette = {
  colorPrimary: string;
  colorSecondary: string;
  colorTertiary: string;
  colorQuaternary: string;
};

export type AppColorPalette = AppRegularColorPalette & {
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
  textOnPrimary: string;
  textOnSecondary: string;
  textOnTertiary: string;
  textOnQuaternary: string;
  background: string;
  textOnBackground: string;
  footerBackground: string;
  textOnFooterBackground: string;
  headerBackground: string;
  textOnHeaderBackground: string;
  headerElevatedBackground: string;
  textOnHeaderElevatedBackground: string;
  sidebarBackground: string;
  textOnSidebarBackground: string;
  cardBackground: string;
  textOnCardBackground: string;
  cardSecondaryBackground: string;
  textOnSecondaryCardBackground: string;
  textTitle: string;
  textSubtitle: string;
  textLabel: string;
  textBody: string;
  textLink: string;
  textLinkHover: string;
  textLinkActive: string;
  textLinkVisited: string;
  buttonPrimary: string;
  buttonSecondary: string;
  textButtonPrimary: string;
  textButtonSecondary: string;
  menuItemBackground: string;
  textOnMenuItemBackground: string;
  menuItemHoverBackground: string;
  menuItemActiveBackground: string;
  textOnMenuItemHoverBackground: string;
  textOnMenuItemActiveBackground: string;
  border: string;
  borderSecondary: string;
  inputBorder: string;
  inputBackground: string;
  textOnInputBackground: string;
};

export const kDEFAULT_REGULAR_COLOR_PALETTE: AppRegularColorPalette = {
  colorPrimary: "#f8aa19",
  colorSecondary: "#1a2a53",
  colorTertiary: "#f18c27",
  colorQuaternary: "#ffffff",
};
