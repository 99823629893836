import {
  lighten,
  toRgba,
  hasBadContrast,
  transparentize,
  darken,
} from "color2k";
import { DarkThemeGenerator } from "../implementation/DarkThemeGenerator";
import { ThemeConfig } from "antd";
import { LightThemeGenerator } from "../implementation/LightThemeGenerator";
import { PrimaryMenuThemeGenerator } from "../implementation/PrimaryMenuThemeGenerator";
import { SecondaryMenuThemeGenerator } from "../implementation/SecondaryMenuThemeGenerator";
import {
  AppColorPalette,
  AppRegularColorPalette,
  kDEFAULT_REGULAR_COLOR_PALETTE,
} from "../type/AppPalette";
import { SecondaryThemeGenerator } from "../implementation/SecondaryThemeGenerator";

export class AppThemeUseCase {
  static byDefaultUseDarkTheme() {
    if (!!window.matchMedia) {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      return mediaQuery.matches;
    }
    return false;
  }

  private static parseColor(color: string, defaultColor: string) {
    try {
      return toRgba(color);
    } catch (e) {
      return toRgba(defaultColor);
    }
  }

  static getPrimaryThemeConfig(
    isDark: boolean,
    palette: AppColorPalette
  ): ThemeConfig {
    return isDark
      ? new DarkThemeGenerator(palette).build()
      : new LightThemeGenerator(palette).build();
  }

  static getSecondaryThemeConfig(palette: AppColorPalette): ThemeConfig {
    return new SecondaryThemeGenerator(palette).build();
  }

  static getMenuThemeConfig(palette: AppColorPalette): ThemeConfig {
    return new PrimaryMenuThemeGenerator(palette).build();
  }

  static getSecondaryMenuThemeConfig(palette: AppColorPalette): ThemeConfig {
    return new SecondaryMenuThemeGenerator(palette).build();
  }

  static getReadableColor(color: string) {
    if (hasBadContrast("white", "readable", color)) {
      return "#000";
    } else {
      return "#FFFFFF";
    }
  }
  static redableColorIsDark(color: string) {
    return this.getReadableColor(color) === "#000";
  }

  static createPalette(palette: AppRegularColorPalette): AppColorPalette {
    const primaryColor = this.parseColor(
      palette.colorPrimary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorPrimary
    );
    const secondaryColor = this.parseColor(
      palette.colorSecondary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorSecondary
    );
    const tertiaryColor = this.parseColor(
      palette.colorTertiary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorTertiary
    );
    const quaternaryColor = this.parseColor(
      palette.colorQuaternary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorQuaternary
    );

    const textOnPrimary = this.getReadableColor(primaryColor);
    const textOnSecondary = this.getReadableColor(secondaryColor);
    const textOnTertiary = this.getReadableColor(tertiaryColor);
    const textOnQuaternary = this.getReadableColor(quaternaryColor);
    const siderColor = this.parseColor(
      palette.colorTertiary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorTertiary
    );
    const textOnSider = this.getReadableColor(siderColor);
    const headerColor = this.parseColor(
      palette.colorTertiary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorTertiary
    );
    const textOnHeader = this.getReadableColor(headerColor);
    const backgroundColor = this.parseColor(
      palette.colorQuaternary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorQuaternary
    );
    const textOnBackground = this.getReadableColor(backgroundColor);
    const cardBackgroundColor = lighten(backgroundColor, 0.1);
    const cardSecondaryBackgroundColor = darken(backgroundColor, 0.1);
    const textOnCardBackground = this.getReadableColor(cardBackgroundColor);
    const textOnSecondaryCardBackground = this.getReadableColor(
      cardSecondaryBackgroundColor
    );
    const borderColor = transparentize(
      this.getReadableColor(backgroundColor),
      0.5
    );
    const secondaryBorderColor = transparentize(
      this.getReadableColor(cardBackgroundColor),
      0.5
    );
    const footerColor = darken(backgroundColor, 0.1);
    const textOnFooter = this.getReadableColor(footerColor);
    const titleTextColor = transparentize(textOnBackground, 0.05);
    const subtitleTextColor = transparentize(textOnBackground, 0.1);
    const bodyTextColor = transparentize(textOnBackground, 0.2);
    const labelTextColor = transparentize(textOnBackground, 0.3);
    const inputBackgroundColor = lighten(backgroundColor, 0.1);
    const inputTextColor = this.getReadableColor(inputBackgroundColor);
    const inputBorderColor = transparentize(inputTextColor, 0.5);
    const textLink = primaryColor;
    const textLinkActive = darken(primaryColor, 0.1);
    const textLinkHover = lighten(primaryColor, 0.2);
    const textLinkVisited = darken(secondaryColor, 0.1);
    const menuItemBackground = siderColor;
    const menuItemActiveBackground = primaryColor;
    const menuItemHoverBackground = darken(primaryColor, 0.1);
    const textOnMenuItemActiveBackground = this.getReadableColor(
      menuItemActiveBackground
    );
    const textOnMenuItemBackground = transparentize(
      this.getReadableColor(menuItemBackground),
      0.3
    );
    const textOnMenuItemHoverBackground = this.getReadableColor(
      menuItemHoverBackground
    );
    const headerElevatedBackground = transparentize(headerColor, 0.6);
    const textOnHeaderElevatedBackground = transparentize(textOnHeader, 0.3);

    return {
      primary: primaryColor,
      colorPrimary: primaryColor,
      secondary: secondaryColor,
      colorSecondary: secondaryColor,
      tertiary: tertiaryColor,
      colorTertiary: tertiaryColor,
      quaternary: quaternaryColor,
      colorQuaternary: quaternaryColor,
      textOnPrimary: textOnPrimary,
      textOnSecondary: textOnSecondary,
      textOnTertiary: textOnTertiary,
      textOnQuaternary: textOnQuaternary,
      sidebarBackground: siderColor,
      textOnSidebarBackground: textOnSider,
      headerBackground: headerColor,
      textOnHeaderBackground: textOnHeader,
      cardBackground: cardBackgroundColor,
      textOnCardBackground: textOnCardBackground,
      cardSecondaryBackground: cardSecondaryBackgroundColor,
      textOnSecondaryCardBackground: textOnSecondaryCardBackground,
      background: backgroundColor,
      textOnBackground: textOnBackground,
      border: borderColor,
      borderSecondary: secondaryBorderColor,
      buttonPrimary: primaryColor,
      buttonSecondary: secondaryColor,
      textButtonPrimary: textOnPrimary,
      textButtonSecondary: textOnSecondary,
      footerBackground: footerColor,
      textOnFooterBackground: textOnFooter,
      textTitle: titleTextColor,
      textSubtitle: subtitleTextColor,
      textBody: bodyTextColor,
      textLabel: labelTextColor,
      inputBackground: inputBackgroundColor,
      textOnInputBackground: inputTextColor,
      inputBorder: inputBorderColor,
      textLink,
      textLinkActive,
      textLinkHover,
      textLinkVisited,
      menuItemBackground,
      menuItemActiveBackground,
      menuItemHoverBackground,
      textOnMenuItemActiveBackground,
      textOnMenuItemBackground,
      textOnMenuItemHoverBackground,
      headerElevatedBackground,
      textOnHeaderElevatedBackground,
    };
  }
}
