import { useState } from "react";
import { AppTaskState } from "../../../app/domain/type/State";
import { Vehicle } from "../../vehicle/domain/type/Vehicle";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { VehicleRepository } from "../../vehicle/data/repository/Vehicle";

export function useFleetHistoricalPageViewModel() {
  const [fetchFleetState, setFetchUnitListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fleet, setFleet] = useState<Vehicle[]>([]);

  async function fetchFleet() {
    if (fetchFleetState?.loading) {
      return;
    }
    try {
      const fleet = await VehicleRepository.getVehicleList();
      if (fleet.ok) {
        setFleet(fleet.data!!);
        setFetchUnitListState(AppTaskStateG.success(true));
      } else {
        setFetchUnitListState(AppTaskStateG.error(new Error(fleet.message)));
      }
    } catch (error: any) {
      setFetchUnitListState(AppTaskStateG.error(error));
    }
  }

  const onFetchFleetStateReceived = () => {
    setFetchUnitListState(null);
  };

  return {
    fetchFleet,
    fetchFleetState,
    onFetchFleetStateReceived,
    fleet,
  };
}
