import { FC, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {
  Gateway,
  GatewayInfo,
  GatewayStatItem,
} from "../../domain/type/Gateway";
import { SocketIOService } from "../../../../app/service/SocketIO";
import { isEmpty } from "lodash";
import { Typography } from "antd";
import { DateUtil } from "../../../../core/util/Date";
type Props = {
  gateway: Gateway;
};

export const GatewayStats: FC<Props> = ({ gateway }) => {
  const [gatewayInfo, setGatewayInfo] = useState<GatewayInfo | null>(null);
  const [online, setOnline] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onTick = useCallback(() => {
    if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
    setOnline(false);
  }, [setOnline, timeoutRef]);

  const listener1 = useCallback(
    (data: any) => {
      setGatewayInfo(data);
      setOnline(true);
      if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(onTick, 5000);
    },
    [setOnline, timeoutRef, onTick]
  );

  useEffect(() => {
    const key = `dashboard/data/${gateway.key}/info`;
    SocketIOService.socketOn(key, listener1);
    return () => {
      SocketIOService.socketOff(key, listener1);
      if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [gateway, listener1]);

  return (
    <div
      className={classNames(
        {
          hidden: !online,
        },
        "flex flex-col items-start justify-start"
      )}
    >
      <Typography.Paragraph>
        <Typography.Text>Métricas ({gateway.key}) </Typography.Text>
        <Typography.Text type="secondary">
          {DateUtil.toHumanReadable(gatewayInfo?.date ?? 0)}
        </Typography.Text>
      </Typography.Paragraph>
      {!!gatewayInfo && !isEmpty(gatewayInfo.stats) && (
        <ItempPills gateway={gateway} items={gatewayInfo!.stats!} />
      )}
    </div>
  );
};

const ItempPills: FC<{ gateway: Gateway; items: GatewayStatItem[] }> = ({
  gateway,
  items,
}) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <div
          key={item.key}
          className="flex flex-row flex-wrap rounded-full px-4 py-2 bg-card-secondary items-center justify-center gap-2"
        >
          <span
            className="material-symbols-outlined bg-secondary rounded-full p-0.5"
            style={{
              color: item.color,
            }}
          >
            {item.icon || "🔧"}
          </span>
          <div className="flex flex-col">
            <Typography.Text className="leading-none">
              {item.title}
            </Typography.Text>
            <Typography.Text type="secondary" className="leading-none">
              {item.value}
            </Typography.Text>
          </div>
        </div>
      ))}
    </div>
  );
};
