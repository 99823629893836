import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Map, Marker, divIcon } from "leaflet";
import stringToColor from "string-to-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { renderToString } from "react-dom/server";
import { ReactComponent } from "../../../../assets/image/marker.svg";
import { Gateway } from "../../../../features/gateway/domain/type/Gateway";
import { GatewayLocation } from "../../../domain/type/DeviceData";
import { SocketIOService } from "../../../../app/service/SocketIO";
import { UnitDescriptionControl } from "./UnitDescriptionControl";
import { UnitsMapfleetListControl } from "./FleetListControl";
import { UnitsMapFullScreenControl } from "./FullscreenControl";
import { Card } from "antd";
import { ImageHOC } from "../Image";

export const UnitsMap: FC<{
  gatewayList?: Array<Gateway>;
  selectedGateway?: Gateway | null;
  onGatewaySelected?: (gateway: Gateway | null) => void;
}> = ({ gatewayList, selectedGateway, onGatewaySelected }) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const map = useRef<Map>(null);
  const markers = useRef<{ [key: string]: Marker }>({});
  const [restoreSize, setRestoreSize] = useState<boolean>(false);

  const listeners = useMemo(() => {
    const currentGatewayList = gatewayList;
    return currentGatewayList?.map((it) => {
      return {
        event: `dashboard/data/${it.key}/gps`,
        callback: (data: GatewayLocation) => {
          const currentMarker = markers.current[it.key];
          if (!!currentMarker) {
            currentMarker.setLatLng([data.latitude, data.longitude]);
          } else {
            const marker = new Marker([data.latitude, data.longitude], {
              icon: divIcon({
                iconSize: [32, 32],
                iconAnchor: [16, 32],
                html: renderToString(
                  <div className="w-full h-full relative">
                    <ReactComponent
                      style={{
                        fill: stringToColor(it!!.key),
                      }}
                      className="w-full h-full"
                    />
                    <div className="inset-0 mx-auto mt-1 absolute h-1/2 bg-transparent rounded-full aspect-square fill-white text-white overflow-hidden">
                      <ImageHOC
                        imgProps={{
                          src: it.Unit?.photo,
                          className: "object-contain w-full h-full",
                        }}
                        errorComponent={
                          <div className="bg-primary w-full h-full flex place-content-center place-items-center text-white">
                            <FontAwesomeIcon icon={faTruck} size="sm" />
                          </div>
                        }
                      />
                    </div>
                  </div>
                ),
                className: "bg-transparent",
              }),
              pane: "markerPane",
              title: it.Unit?.name,
            });
            marker.addTo(map.current!);
            markers.current[it.key] = marker;
          }
        },
      };
    });
  }, [gatewayList, markers, map]);

  useEffect(() => {
    const currentListeners = listeners;
    currentListeners?.forEach((it) => {
      SocketIOService.socketOn(it.event, it.callback);
    });
    return () => {
      currentListeners?.forEach((it) => {
        SocketIOService.socketOff(it.event, it.callback);
      });
    };
  }, [listeners]);

  const containerRef = useRef<HTMLDivElement>(null);

  const onCenter = useCallback(
    (gateway: Gateway) => {
      const currentMarker = markers.current[gateway.key!];
      if (!!currentMarker) {
        map.current?.panTo(currentMarker.getLatLng());
      }
    },
    [markers, map]
  );

  useEffect(() => {
    setRestoreSize(true);
    return () => {
      setRestoreSize(false);
      (map as MutableRefObject<Map | null>).current = null;
    };
  }, []);

  useEffect(() => {
    if (!!map?.current) {
      const current = timeoutRef.current;
      if (!!current) {
        clearTimeout(current);
      }
      timeoutRef.current = setTimeout(() => {
        if (map?.current?.invalidateSize) {
          map?.current?.invalidateSize();
        }
      }, 200);
    }
  }, [restoreSize]);

  const onCloseDescription = useCallback(() => {
    onGatewaySelected?.(null);
  }, [onGatewaySelected]);

  return (
    <Card
      className={"w-full h-full overflow-hidden"}
      style={{
        maxHeight: "none",
      }}
    >
      <div ref={containerRef}>
        <MapContainer
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "none",
            zIndex: 10,
            position: "absolute",
            top: 0,
            left: 0,
          }}
          className="w-full h-full"
          zoom={7}
          scrollWheelZoom={false}
          ref={map}
          center={[-12.04318, -77.02824]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <UnitDescriptionControl
            gateway={selectedGateway}
            onClose={onCloseDescription}
            onCenter={onCenter}
          />
          <UnitsMapfleetListControl
            gatewayList={gatewayList}
            onGatewaySelected={onGatewaySelected}
            onCenterMap={onCenter}
          />
          <UnitsMapFullScreenControl containerRef={containerRef} />
        </MapContainer>
      </div>
    </Card>
  );
};
