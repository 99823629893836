import { Radio as AntdRadio, RadioChangeEvent } from "antd";
import { RadioGroupProps } from "antd/lib/radio";
import { useFormikContext } from "formik";
import { useCallback } from "react";

type Props = Omit<RadioGroupProps, "name" | "status" | "value" | "onChange"> & {
  name: string;
  onChangeSecondary?: (event: RadioChangeEvent) => void;
};

export function RadioGroup({ name, onChangeSecondary, ...props }: Props) {
  const { initialValues, values, setFieldValue, setFieldTouched } =
    useFormikContext();

  const defValue = (initialValues as any)[name] || (values as any)[name] || [];
  const onChangeCaptured = useCallback(
    (event: RadioChangeEvent) => {
      onChangeSecondary && onChangeSecondary(event);
      setFieldTouched(name, true);
      setFieldValue(name, event.target.value);
    },
    [setFieldValue, name, setFieldTouched, onChangeSecondary]
  );

  return (
    <AntdRadio.Group
      {...props}
      name={name}
      value={defValue}
      onChange={onChangeCaptured}
    />
  );
}
