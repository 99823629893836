import { FC, useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, notification } from "antd";
import { useGatewayViewModel } from "../../gateway_list/presentation/ViewModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRefresh,
  faServer,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { toNumber } from "lodash";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { GatewayDataComponent } from "./component/Data";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { AppLoader } from "../../../core/presentation/component/AppLoader";

export const GatewayPage: FC = () => {
  const {
    gateway,
    onFetchGatewayStateReceived,
    fetchGateway,
    fetchGatewayState,
  } = useGatewayViewModel();
  const { id } = useParams();

  const [fromVehicle, setShowFromVehicle] = useState<boolean>(false);
  const fetchGatewayData = useCallback(() => {
    void fetchGateway(toNumber(id));
  }, [fetchGateway]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    void fetchGateway(Number(id));
    setShowFromVehicle(searchParams.get("fromVehicle") === "true");
  }, []);

  useEffect(() => {
    if (!!fetchGatewayState && !fetchGatewayState.loading) {
      if (fetchGatewayState.hasError) {
        notification.error({
          message:
            fetchGatewayState.error?.message || "Error al obtener el gateway.",
        });
      }
      onFetchGatewayStateReceived();
    }
  }, [fetchGatewayState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchGatewayState && fetchGatewayState.loading} />
      {fromVehicle ? (
        <CustomBreadCrumb
          items={[
            {
              pathname: "/dashboard/vehicles",
              title: "Clientes",
              icon: <FontAwesomeIcon icon={faTruck} />,
            },
            {
              pathname: `/dashboard/vehicles/${gateway?.Unit?.id}?tab=gateways`,
              title: `${gateway?.Unit?.name} / Gateways`,
            },
            {
              active: true,
              title: gateway?.key,
            },
          ]}
        />
      ) : (
        <CustomBreadCrumb
          items={[
            {
              pathname: "/dashboard/gateways",
              title: "Gateways",
              icon: <FontAwesomeIcon icon={faServer} />,
            },
          ]}
        />
      )}

      <ContentHeader
        title={"Gateway"}
        subtitle={`${gateway?.key}`}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchGatewayData}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      {!!gateway && <GatewayDataComponent gateway={gateway} />}
    </ResponsiveContainer>
  );
};
