import {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { ChartData } from "../../../../../../../core/domain/type/ChartConfig";
import { SocketIOService } from "../../../../../../../app/service/SocketIO";
import { Badge, Spin, Typography } from "antd";
import { LiveDot } from "../../../../../../../core/presentation/component/LiveDot";
import { DeviceDataRender } from "../../../../../../../core/presentation/component/charts/Render";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { VehicleChart } from "../../../../../../gateway/domain/type/VehicleChart";
import { DateUtil } from "../../../../../../../core/util/Date";

type Props = {
  chart: VehicleChart;
};
export const DashboardChartRenderer: FC<Props> = ({ chart }) => {
  const [data, setData] = useState<ChartData | null>(null);
  const [date, setDate] = useState<string | undefined>();
  const timerRef = useRef<NodeJS.Timeout>(
    null
  ) as MutableRefObject<NodeJS.Timeout>;
  const [isLive, setIsLive] = useState<boolean>(false);

  const timerCallback = useCallback(() => {
    setIsLive(false);
  }, [setIsLive]);

  const listener = useCallback(
    (nData: ChartData) => {
      if (timerRef.current) clearTimeout(timerRef?.current);
      if (!!nData) {
        setData(nData);
        if (!!nData.timestamp) {
          setDate(DateUtil.fastFormatDate(nData.timestamp, "HH:mm:ss"));
          setIsLive(true);
        }
      }
      timerRef.current = setTimeout(timerCallback, 10000);
    },
    [setData, setDate, timerRef, setIsLive, timerCallback]
  );
  useEffect(() => {
    const key = `dashboard/data/${chart?.Unit.id}/${chart.ChartDefinition.key}`;
    SocketIOService.socketOn(key, listener);
    return () => {
      SocketIOService.socketOff(key, listener);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [chart, listener]);

  return (
    <Badge.Ribbon
      color={isLive ? "green" : "red"}
      text={
        <div className="flex flex-row gap-1 place-items-center">
          {isLive ? (
            <>
              <LiveDot />
              {date}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faXmark} />
              Desconectado
            </>
          )}
        </div>
      }
      rootClassName="w-full h-full"
    >
      <div className="w-full h-full overflow-hidden rounded-lg shadow p-3 gap-2 flex flex-col bg-card">
        {chart.ChartDefinition.name && (
          <Typography.Text className="w-full text-center font-secondary">
            {chart.ChartDefinition.name}
          </Typography.Text>
        )}

        <div
          className={
            "w-full flex-1 overflow-hidden items-center justify-center flex flex-col"
          }
        >
          {isLive ? (
            <DeviceDataRender chart={chart} data={data} />
          ) : (
            <Spin
              indicator={
                <FontAwesomeIcon
                  icon={faSpinner}
                  style={{ fontSize: 36 }}
                  spin
                />
              }
            />
          )}
        </div>
      </div>
    </Badge.Ribbon>
  );
};
