import { FC, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Spin } from "antd";
import { useAppResponsiveScreen } from "../../../app/hook/Breakpoint";

type Props = {
  loading?: boolean;
};
export const AppLoader: FC<Props> = ({ loading = true }) => {
  const { isMobileScreen } = useAppResponsiveScreen();
  return (
    <LoaderWrapper>
      {loading && !isMobileScreen ? (
        <div
          className={
            "absolute w-full h-full bg-black bg-opacity-20 backdrop-blur-sm"
          }
          style={{ zIndex: 99998 }}
        />
      ) : null}
      {loading ? (
        <div
          className={`absolute w-full h-full bg-black bg-opacity-20 flex items-center justify-center gap-4 flex-col`}
          style={{ zIndex: 99999 }}
        >
          <Spin size={"large"} tip={"Cargando..."} />
        </div>
      ) : null}
    </LoaderWrapper>
  );
};

const LoaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  return createPortal(children, document.getElementById("loader_container")!);
};
