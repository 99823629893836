import { useState } from "react";
import { AppTaskState } from "../../../../../../app/domain/type/State";
import { AppTaskStateG } from "../../../../../../app/domain/model/State";
import { UnitAssetRepository } from "../../../../data/repository/Asset";
import { UnitAsset } from "../../../../domain/type/Asset";

export function useUnitGalleryViewModel() {
  const [fetchState, setFetchState] = useState<AppTaskState<boolean> | null>(
    null
  );
  const [gallery, setGallery] = useState<UnitAsset[] | null | undefined>(null);
  async function fetchGallery(vehicleId: number) {
    if (fetchState?.loading) return;
    setFetchState(AppTaskStateG.loading());
    try {
      const response = await UnitAssetRepository.getGallery(vehicleId);
      setGallery(response.data);
      setFetchState(AppTaskStateG.success(true));
    } catch (e: any) {
      setFetchState(AppTaskStateG.error(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  return {
    fetchState,
    gallery,
    fetchGallery,
    onFetchStateReceived,
  };
}
