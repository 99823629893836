import { FC, useCallback } from "react";
import { Button, Form, Modal, Typography } from "antd";
import { Formik, FormikHelpers } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faKey, faTerminal } from "@fortawesome/free-solid-svg-icons";
import Terminal, { TerminalInput } from "react-terminal-ui";
import ButtonGroup from "antd/es/button/button-group";
import * as Yup from "yup";
import { GatewayAction } from "../../../../domain/type/GatewayActions";
import { FormItem } from "../../../../../../core/presentation/component/fields/FormItem";
import ExtendedInput from "../../../../../../core/presentation/component/fields/ExtendedInput";
import { SubmitButton } from "../../../../../../core/presentation/component/fields/SubmitButton";

type FormData = {
  password: string;
};

const validator = Yup.object({
  password: Yup.string()
    .required("Es necesario que ingrese su contraseña")
    .min(1, "Es necesario ingresar su contraseña"),
});
type Props = {
  open?: boolean;
  action?: GatewayAction | null;
  onSubmit: (value: FormData) => Promise<boolean | undefined>;
  onCancel: () => void;
};

export const GatewayActionConfirmationModal: FC<Props> = ({
  open,
  action,
  onSubmit,
  onCancel,
}) => {
  const onSubmitForm = useCallback(
    (values: FormData, helpers: FormikHelpers<FormData>) => {
      onSubmit(values).then((it) => {
        if (it) {
          helpers.resetForm();
          onCancel();
        }
        helpers.setSubmitting(false);
      });
    },
    [onSubmit, onCancel]
  );

  return (
    <Modal
      title={"Enviar acción al Gateway"}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <div className="flex flex-col gap-2">
        <Typography.Text type="secondary">
          Es necesario que se confirme la contraseña del administrador para
          enviar el comando.
        </Typography.Text>
        <Typography.Text type="secondary" strong>
          Detalles de ejecución
        </Typography.Text>

        <Terminal height={"80px"}>
          {/* <TerminalInput>{`usar '${
            action?.GatewayDevice.Device.key ?? ""
          }'`}</TerminalInput> */}
          <TerminalInput>{`ejecutar '${action?.path}'`}</TerminalInput>
        </Terminal>
        <Formik<FormData>
          initialValues={{
            password: "",
          }}
          onSubmit={onSubmitForm}
          validationSchema={validator}
        >
          <Form>
            <FormItem name={"password"}>
              <ExtendedInput
                role="presentation"
                autoComplete="off"
                name="password"
                placeholder="Contraseña"
                prefix={<FontAwesomeIcon icon={faKey} />}
              />
            </FormItem>
            <ButtonGroup>
              <SubmitButton icon={<FontAwesomeIcon icon={faTerminal} />}>
                Enviar
              </SubmitButton>
              <Button
                onClick={onCancel}
                type="default"
                icon={<FontAwesomeIcon icon={faCancel} />}
              >
                Cancelar
              </Button>
            </ButtonGroup>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};
