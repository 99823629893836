import { Button, ButtonProps } from "antd";
import { useFormikContext } from "formik";
import { FC } from "react";

export const SubmitButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { isSubmitting, isValid, submitForm } = useFormikContext();
  return (
    <Button
      loading={isSubmitting}
      disabled={!isValid}
      onClick={submitForm}
      {...props}
    >
      {children}
    </Button>
  );
};
