import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Carousel, notification, Typography } from "antd";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { TabContentBox } from "../../../../../../core/presentation/component/TabContentBox";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { AppStateComponent } from "../../../../../../core/presentation/component/State";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEye,
  faEyeSlash,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import animationData from "../../../../../../assets/animation/animation_d1.json";

import { isEmpty, isNull } from "lodash";
import { useUnitGalleryViewModel } from "./ViewModel";
import { AssetClientHostConfig } from "../../../../../../app/service/RemoteAssetClient";
import { UnitAsset } from "../../../../domain/type/Asset";
import { ImageHOC } from "../../../../../../core/presentation/component/Image";
import classnames from "classnames";
import { CarouselRef } from "antd/lib/carousel";

type Props = {
  vehicle: Vehicle;
  size?: "sm" | "lg";
};

export const VehicleGalleryTab: FC<Props> = ({ vehicle, size = "lg" }) => {
  const { fetchGallery, fetchState, gallery, onFetchStateReceived } =
    useUnitGalleryViewModel();
  const carouselRef = useRef<CarouselRef>(null);
  useEffect(() => {
    fetchGallery(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchState && !fetchState.loading) {
      if (fetchState.hasError) {
        notification.error({
          message: "Error al obtener galería de la unidad.",
          description: fetchState.error?.message,
        });
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  const twHeight = size === "sm" ? "h-64" : "h-screen";

  const onNextArrow = useCallback(() => {
    carouselRef.current?.next();
  }, [carouselRef]);

  const onPrevArrow = useCallback(() => {
    carouselRef.current?.prev();
  }, [carouselRef]);

  return (
    <TabContentBox>
      <AppLoader loading={!!fetchState && fetchState.loading} />
      {!gallery || isEmpty(gallery) ? (
        <AppStateComponent
          description={"No hay imágenes en la galería de esta unidad."}
          title={"Galería vacía"}
          animation={animationData}
        />
      ) : (
        <div className={classnames("w-full bg-transparent relative", twHeight)}>
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            className={classnames(
              "absolute left-0 top-1/2 z-50 -translate-y-1/2",
              {
                "ml-2": size === "sm",
                "ml-8": size === "lg",
                hidden: isEmpty(gallery) || gallery.length === 1,
              }
            )}
            onClick={onPrevArrow}
          />
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            className={classnames(
              "absolute right-0 top-1/2 z-50 -translate-y-1/2",
              {
                "mr-2": size === "sm",
                "mr-8": size === "lg",
                hidden: isEmpty(gallery) || gallery.length === 1,
              }
            )}
            onClick={onNextArrow}
          />
          <Carousel
            infinite
            ref={carouselRef}
            className={classnames("w-full", twHeight)}
            rootClassName={classnames("relative", twHeight)}
          >
            {gallery.map((asset) => (
              <GalleryItem
                size={size}
                rootClassName={twHeight}
                asset={asset}
                key={`asset-${asset.id}`}
              />
            ))}
          </Carousel>
        </div>
      )}
    </TabContentBox>
  );
};

const GalleryItem: FC<{
  asset: UnitAsset;
  rootClassName?: string;
  size: "sm" | "lg";
}> = ({ asset, rootClassName, size }) => {
  const imageUrl = `${AssetClientHostConfig.host}${asset.fileName}`;
  const [checked, setChecked] = useState(true);
  const handleCheck = useCallback(() => {
    setChecked((old) => !old);
  }, [setChecked]);
  return (
    <div
      className={classnames(
        "relative w-full flex flex-col items-center justify-center",
        rootClassName
      )}
    >
      <ImageHOC
        imgProps={{
          src: imageUrl,
          alt: asset.title,
          className: "object-contain w-full h-full",
        }}
        allowZooming={true}
        errorComponent={
          <div className="flex flex-col items-center justify-center gap-2 w-full h-full">
            <FontAwesomeIcon icon={faImage} size="3x" />
            <Typography.Text type="secondary">
              Error al cargar imagen
            </Typography.Text>
          </div>
        }
      />
      {!isEmpty(asset.title) || !isNull(asset.description) ? (
        <div className="absolute w-full inset-0 h-full">
          <Button
            icon={<FontAwesomeIcon icon={checked ? faEyeSlash : faEye} />}
            className="absolute bottom-0 right-0 m-3 shadow z-50"
            onClick={handleCheck}
          />
          <div
            className={classnames(
              "transition-all absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent duration-1000 flex flex-col",
              {
                "opacity-100": checked,
                "opacity-0": !checked,
                "px-12 py-10": size === "lg",
                "px-4 py-6": size === "sm",
              }
            )}
          >
            <Typography.Title
              className="p-0 m-0 leading-none"
              level={size === "sm" ? 4 : 1}
            >
              {asset.title}
            </Typography.Title>
            <Typography.Text
              className={classnames("p-0 m-0 leading-none", {
                "text-xs leading-none": size === "sm",
              })}
            >
              {asset.description}
            </Typography.Text>
          </div>
        </div>
      ) : null}
    </div>
  );
};
