import { Checkbox as AntdCheckbox, InputProps } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import { useFormikContext } from "formik";
import { Fragment, useCallback } from "react";

type Props<T extends CheckboxValueType> = Omit<
  CheckboxGroupProps<T>,
  "name" | "status" | "value" | "onChange"
> & {
  name: string;
};

export function CheckboxGroup<T extends CheckboxValueType>({
  name,
  ...props
}: Props<T>) {
  const {
    errors,
    initialTouched,
    initialErrors,
    isValidating,
    isInitialValid,
    isSubmitting,
    isValid,
    touched,
    initialValues,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const fieldTouched = (initialTouched as any)[name] || (touched as any)[name];
  const fieldError = (initialErrors as any)[name] || (errors as any)[name];
  const readyToShowStatus = !isValidating || !isSubmitting;
  const fieldValid = isInitialValid || isValid;

  const status: InputProps["status"] = readyToShowStatus
    ? fieldTouched
      ? fieldError
        ? "error"
        : fieldValid
        ? ""
        : "warning"
      : ""
    : "";

  const defValue =
    (initialValues as any)[name] || (values as any)[name] || ([] as T[]);
  const onChangeCaptured = useCallback(
    (event: T[]) => {
      setFieldTouched(name, true);
      setFieldValue(name, event);
    },
    [setFieldValue, name, setFieldTouched]
  );

  return (
    <AntdCheckbox.Group<T>
      name={name}
      value={defValue}
      onChange={onChangeCaptured}
      {...props}
    />
  );
}
