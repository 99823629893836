import { FC, Key, MouseEventHandler, useCallback } from "react";
import { Avatar, Button, Card, Typography } from "antd";
import stringToColor from "string-to-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation, faTruck } from "@fortawesome/free-solid-svg-icons";
import { Gateway } from "../../../../features/gateway/domain/type/Gateway";
import { ImageHOC } from "../Image";
import { isEmpty } from "lodash";

type Props = {
  gatewayList?: Array<Gateway> | null;
  onGatewaySelected?: (gateway: Gateway) => void;
  onCenterMap?: (gateway: Gateway) => void;
};

export const UnitsMapfleetListControl: FC<Props> = ({
  gatewayList,
  onGatewaySelected,
  onCenterMap,
}) => {
  const onItemSelected = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const key = e.currentTarget.getAttribute("data-key") as Key;
      const gateway = gatewayList?.find((g) => g.key === key);
      onGatewaySelected?.(gateway!);
    },
    [onGatewaySelected, gatewayList]
  );

  const onCenterMapClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const key = e.currentTarget.getAttribute("data-key") as Key;
      const gateway = gatewayList?.find((g) => g.key === key);
      onCenterMap?.(gateway!);
    },
    [onCenterMap, gatewayList]
  );
  if (!gatewayList || isEmpty(gatewayList)) return null;
  return (
    <div className={"leaflet-top leaflet-right"}>
      <div className="leaflet-control">
        <Card size="small">
          <Card.Meta
            title={"Flota Seleccionada"}
            description={
              <div className="overflow-y-auto divide-y divide-x-0 divide-neutral-200 divide-solid ">
                {gatewayList.map((it) => (
                  <div
                    key={it.key + "sg"}
                    data-key={it.key}
                    className="pt-2 flex flex-row place-items-center gap-2 cursor-pointer"
                    onClick={onItemSelected}
                  >
                    <Button
                      shape="circle"
                      onClick={onCenterMapClick}
                      icon={<FontAwesomeIcon icon={faLocation} />}
                      data-key={it.key}
                      className="text-white"
                      style={{
                        backgroundColor: stringToColor(it.key),
                      }}
                    />
                    <Avatar
                      size={"large"}
                      icon={
                        <ImageHOC
                          imgProps={{
                            src: it.Unit?.photo,
                            className: "object-cover w-full h-full",
                          }}
                          errorComponent={
                            <div className="bg-primary w-full h-full flex place-content-center place-items-center text-white">
                              <FontAwesomeIcon icon={faTruck} size="sm" />
                            </div>
                          }
                        />
                      }
                    />
                    <div className="flex flex-col place-content-center">
                      <Typography.Text className="leading-tight">
                        {it.Unit?.name}
                      </Typography.Text>
                      <Typography.Paragraph
                        type="secondary"
                        className="leading-tight"
                      >
                        A través de{" "}
                        <Typography.Text
                          style={{
                            color: stringToColor(it.key),
                          }}
                        >
                          {it.key}
                        </Typography.Text>
                      </Typography.Paragraph>
                    </div>
                  </div>
                ))}
              </div>
            }
          />
        </Card>
      </div>
    </div>
  );
};
