import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { Card, notification, Form, Typography, Descriptions } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { FormItem } from "../../../../../../core/presentation/component/fields/FormItem";
import { ExtendedDatePicker } from "../../../../../../core/presentation/component/fields/ExtendedDatePicker";
import { SubmitButton } from "../../../../../../core/presentation/component/fields/SubmitButton";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarChart } from "@fortawesome/free-solid-svg-icons";
import { useAppTheme } from "../../../../../../app/hook/Theme";
import { UnitEventCountItem } from "../../../../../../core/domain/type/UnitEvent";
import { EChartsOption } from "echarts-for-react";
import { SeriesOption } from "echarts";
import { isEmpty, isInteger, isNumber } from "lodash";
import ReactECharts from "echarts-for-react";
import { useUnitAlertViewModel } from "./UnitAlertViewModel.js";
import stringTocolor from "string-to-color";
import { AppStateComponent } from "../../../../../../core/presentation/component/State";
type Props = {
  vehicle: Vehicle;
};

type FetchData = {
  dateRange?: [Date, Date];
};

const FORM_VALIDATION = Yup.object({
  dateRange: Yup.array(Yup.date())
    .length(2)
    .required("Selecciona un rango válido."),
});

export const VehicleAlertsReportTab: FC<Props> = ({ vehicle }) => {
  const {
    eventCount,
    fetchEventCount,
    fetchEventCountState,
    onFetchCountStateReceived,
  } = useUnitAlertViewModel();

  const onSubmit = useCallback(
    async (data: FetchData) => {
      return fetchEventCount(
        vehicle.id,
        data.dateRange?.[0]!,
        data.dateRange?.[1]!
      );
    },
    [fetchEventCount]
  );

  useEffect(() => {
    if (!!fetchEventCountState && !fetchEventCountState.loading) {
      if (fetchEventCountState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchEventCountState.error?.message,
        });
      }
      onFetchCountStateReceived();
    }
  }, [fetchEventCountState]);

  return (
    <ResponsiveContainer>
      <AppLoader
        loading={!!fetchEventCountState && fetchEventCountState.loading}
      />
      <div className={"w-full relative overflow-x-hidden flex flex-col gap-2"}>
        <Card bordered>
          <Formik<FetchData>
            initialValues={{}}
            onSubmit={onSubmit}
            validationSchema={FORM_VALIDATION}
          >
            <Form layout={"vertical"}>
              <FormItem name={"dateRange"} label={"Periodo"}>
                <ExtendedDatePicker.RangePicker
                  name={"dateRange"}
                  presets={[
                    {
                      label: "Hoy",
                      value: [dayjs().startOf("day"), dayjs().endOf("day")],
                    },
                    {
                      label: "Esta semana",
                      value: [dayjs().add(-7, "d"), dayjs()],
                    },
                    {
                      label: "Últimas 2 semanas",
                      value: [dayjs().add(-14, "d"), dayjs()],
                    },
                    {
                      label: "Este mes",
                      value: [dayjs().add(-30, "d"), dayjs()],
                    },
                    {
                      label: "Últimos 3 meses",
                      value: [dayjs().add(-90, "d"), dayjs()],
                    },
                  ]}
                />
              </FormItem>

              <SubmitButton icon={<FontAwesomeIcon icon={faBarChart} />}>
                Generar Reporte
              </SubmitButton>
            </Form>
          </Formik>
        </Card>
        {isEmpty(eventCount) ? (
          <AppStateComponent
            title="No hay items"
            description="No hay items para el rango de fecha seleccionado"
          />
        ) : (
          <BarChart data={eventCount!} />
        )}
      </div>
    </ResponsiveContainer>
  );
};

const BarChart: FC<{ data: Array<UnitEventCountItem> }> = ({ data }) => {
  const { colorPalette } = useAppTheme();

  const baseCharts = useMemo<Array<EChartsOption>>(() => {
    let series1: Array<SeriesOption> = [];
    let series2: Array<SeriesOption> = [];
    const charts: Array<EChartsOption> = [];
    const currentArray: any[] = [];
    const dates: Array<string> = [];
    const pieData: any[] = [];

    data?.forEach((it) => {
      const itemColor = stringTocolor(it.name);
      currentArray.push({
        value: it.count,
        itemStyle: {
          color: itemColor,
          borderRadius: [15, 15, 0, 0],
        },
      });
      dates.push(it.name);
      pieData.push({
        value: it.count,
        name: it.name,
        itemStyle: {
          color: itemColor,
          borderRadius: [15, 15, 0, 0],
        },
      });
    });

    series1.push({
      type: "bar",
      data: currentArray,
    });

    series2.push({
      name: "Eventos",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: colorPalette.textOnSecondaryCardBackground,
        borderWidth: 2,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: pieData,
    });
    charts.push({
      xAxis: {
        type: "category",
        data: dates,
        boundaryGap: true,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: colorPalette.textOnCardBackground,
          },
        },
      },

      yAxis: {
        type: "value",

        axisLine: {
          lineStyle: {
            color: colorPalette.textOnCardBackground,
          },
        },
        axisLabel: {
          formatter(value: string | number) {
            return `${
              isNumber(value)
                ? isInteger(value)
                  ? value
                  : value.toFixed(2)
                : value
            }`;
          },
        },
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: colorPalette.cardSecondaryBackground,
        borderColor: colorPalette.borderSecondary,
        textStyle: {
          color: colorPalette.textOnSecondaryCardBackground,
        },
        appendToBody: true,
        formatter(params: any) {
          let html = "<div>";
          params.forEach((it: any) => {
            html += `
              <div class="my-1">
                <p class="gap-1 items-center rounded-full px-1 py-0.5" style="background-color: ${
                  it.color
                }">
                  <span class="font-secondary mix-blend-difference" >${
                    params?.[0]?.axisValue ?? ""
                  }</span>             
                </p>  
                <p class="font-primary px-1">${it.value}</p>
              </div>`;
          });
          html += "</div>";
          return html;
        },
      },
      series: series1,
    });
    charts.push({
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
        textStyle: {
          color: colorPalette.textOnSecondaryCardBackground,
        },
      },

      series: series2,
    });
    return charts;
  }, [data]);

  return (
    <div className="flex flex-col">
      <Descriptions bordered>
        <Descriptions.Item label={"Total"}>
          {data.reduce((acc, it) => acc + it.count, 0)}
        </Descriptions.Item>
        {data.map((it, idx) => (
          <Fragment key={`alert-count-${idx}`}>
            <Descriptions.Item label={it.name}>{it.count}</Descriptions.Item>
          </Fragment>
        ))}
      </Descriptions>
      {baseCharts.map((it, idx) => (
        <ReactECharts key={`alert-chart-${idx}`} option={it} />
      ))}
    </div>
  );
};
