import { FC } from "react";
import { isEmpty } from "lodash";
import { HistoricalUnitDeviceData } from "../../../../../../core/domain/type/DeviceData";
import { AppEmptyContentStateComponent } from "../../../../../../core/presentation/component/State";
import { VehicleChart } from "../../../../../gateway/domain/type/VehicleChart";
import { Card, Typography } from "antd";
import { HistoricalDeviceDataRender } from "../../../../../../core/presentation/component/charts/RenderHistorical";
type Props = {
  deviceData: HistoricalUnitDeviceData | null;
  charts: VehicleChart[] | null;
};

export const DataViewer: FC<Props> = ({ deviceData, charts }) => {
  return !deviceData || isEmpty(deviceData) ? (
    <AppEmptyContentStateComponent
      description={
        "No se encontraron datos para mostrar. Seleccione otro rango de fecha. "
      }
    ></AppEmptyContentStateComponent>
  ) : (
    <ChartsItemsRenderer charts={charts} deviceData={deviceData} />
  );
};

const ChartsItemsRenderer: FC<Required<Props>> = ({ deviceData, charts }) => {
  console.log(deviceData);
  return (
    <div className={"w-full flex flex-col gap-3"}>
      {charts!!.map((it, i) => (
        <div
          key={`log-${it.ChartDefinition.key}`}
          className="w-full h-full overflow-hidden rounded-lg shadow p-3 gap-2 flex flex-col bg-card"
        >
          {it.ChartDefinition.name && (
            <Typography.Text className="w-full text-center font-secondary">
              {it.ChartDefinition.name}
            </Typography.Text>
          )}
          {it.ChartDefinition.description && (
            <Typography.Paragraph>
              {it.ChartDefinition.description}
            </Typography.Paragraph>
          )}
          <HistoricalDeviceDataRender
            chart={it}
            data={deviceData![it.ChartDefinition.key]}
          />
        </div>
      ))}
    </div>
  );
};
