import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  notification,
  Tree,
  Typography,
} from "antd";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { TabContentBox } from "../../../../../../core/presentation/component/TabContentBox";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { AppStateComponent } from "../../../../../../core/presentation/component/State";
import { FullscreenToggle } from "../../../../../../core/presentation/component/Fullscreen";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faSearch,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactGridLayout from "react-grid-layout";
import { ChartLayoutUseCase } from "../../../../domain/usecase/ChartLayout";
import { useComponentSize } from "../../../../../../app/hook/Size";
import animationData from "../../../../../../assets/animation/animation_d1.json";
import { useTabVehicleDashboardViewModel } from "./ViewModel";
import { TabVehicleModalScan } from "./components/ModalScan";
import {
  Gateway,
  OnlineGateway,
} from "../../../../../gateway/domain/type/Gateway";
import { difference, isEmpty, orderBy, toNumber } from "lodash";
import { DashboardChartRenderer } from "./components/ChartRenderer";
import useLocalStorageState from "use-local-storage-state";
import { VehicleGalleryTab } from "../gallery/TabGallery";
import { GatewayStats } from "../../../../../gateway/presentation/component/Stats";

type Props = {
  vehicle: Vehicle;
};

export const VehicleDeviceTab: FC<Props> = ({ vehicle }) => {
  const [scan, setScan] = useState<boolean>(false);
  const [reorder, setReorder] = useState<boolean>(false);
  const { ref: c1Ref, width: c1Width } = useComponentSize();
  const [unitSelectedChartsIds, setUnitSelectedChartsIds] =
    useLocalStorageState<number[] | null>(`dashboard_charts_${vehicle.id}`, {
      defaultValue: null,
    });
  const {
    dashboardChartList,
    dashboardScannedChartList,
    fetchDashboardChartList,
    fetchDashboardChartListState,
    fetchGatewayList,
    fetchGatewayListState,
    fetchUnitGalleryState,
    gatewayList,
    onFetchDashboardChartListStateReceived,
    onFetchGatewayListStateReceived,
    onScanDashboardChartListStateReceived,
    scanDashboardChartList,
    scanDashboardChartListState,
    onFetchUnitGalleryStateReceived,
  } = useTabVehicleDashboardViewModel();

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchDashboardChartList(vehicle.id);
    fetchGatewayList(vehicle.id);
  }, []);

  useEffect(() => {
    if (
      !!fetchDashboardChartListState &&
      !fetchDashboardChartListState.loading
    ) {
      if (fetchDashboardChartListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchDashboardChartListState.error?.message,
        });
      }
      onFetchDashboardChartListStateReceived();
    }
  }, [fetchDashboardChartListState]);

  useEffect(() => {
    if (!!fetchUnitGalleryState && !fetchUnitGalleryState.loading) {
      if (fetchUnitGalleryState.hasError) {
        notification.error({
          message: "Error al obtener la imagen del dispositivo.",
          description: fetchUnitGalleryState.error?.message,
        });
      }
      onFetchUnitGalleryStateReceived();
    }
  }, [fetchUnitGalleryState]);

  useEffect(() => {
    if (!!scanDashboardChartListState && !scanDashboardChartListState.loading) {
      if (scanDashboardChartListState.hasError) {
        notification.error({
          description: scanDashboardChartListState.error?.message,
          message: "Escanear Variables",
        });
      }
      onSwitchScan();
      onScanDashboardChartListStateReceived();
    }
  }, [scanDashboardChartListState]);

  useEffect(() => {
    if (!!fetchGatewayListState && !fetchGatewayListState.loading) {
      if (fetchGatewayListState.hasError) {
        notification.error({
          description: fetchGatewayListState.error?.message,
          message: "Obtener Gateways",
        });
      }
      onFetchGatewayListStateReceived();
    }
  }, [fetchGatewayListState]);

  useEffect(() => {
    if (!unitSelectedChartsIds || unitSelectedChartsIds.length <= 0) {
      setUnitSelectedChartsIds(dashboardChartList.map((it) => it.id));
    }
  }, [dashboardChartList]);

  const onChartsSelected = useCallback(
    (value: CheckboxValueType[]) => {
      setUnitSelectedChartsIds(value as any);
    },
    [setUnitSelectedChartsIds]
  );
  const selectedChartsIds = useMemo(() => {
    return (
      dashboardChartList
        ?.filter((it) => unitSelectedChartsIds?.includes(it.id))
        .map((it) => it.id) ?? []
    );
  }, [unitSelectedChartsIds, dashboardChartList]);
  const selectedCharts = useMemo(
    () =>
      orderBy(
        dashboardChartList?.filter((it) => selectedChartsIds?.includes(it.id)),
        (it) => unitSelectedChartsIds?.indexOf(it.id)
      ),
    [selectedChartsIds, dashboardChartList]
  );

  const layout = useMemo(() => {
    if (!!selectedCharts && !!c1Width) {
      return ChartLayoutUseCase.generateLayout(c1Width, selectedCharts);
    }
    return { layout: [], rows: 0, cols: 0, rowHeight: 0 };
  }, [selectedCharts, c1Width]);

  const onSwitchReorder = useCallback(() => {
    setReorder((old) => !old);
  }, [setReorder]);

  const onSwitchScan = useCallback(() => {
    setScan((old) => !old);
  }, [setScan]);

  const onScanConfirm = useCallback(
    (gateway: Gateway) => {
      scanDashboardChartList(vehicle.id, gateway.id);
    },
    [scanDashboardChartList, vehicle]
  );

  const allChartsSelected = useMemo(
    () => difference(dashboardChartList, selectedCharts).length === 0,
    [dashboardChartList, selectedCharts]
  );

  const allScannedChartsSelected = useMemo(() => {
    const diff = difference(selectedCharts, dashboardScannedChartList);
    return diff.length === dashboardScannedChartList.length;
  }, [dashboardScannedChartList, selectedCharts]);

  const selectAllCharts = useCallback(() => {
    if (allChartsSelected) {
      setUnitSelectedChartsIds([]);
    } else {
      setUnitSelectedChartsIds(dashboardChartList.map((it) => it.id));
    }
  }, [setUnitSelectedChartsIds, allChartsSelected, dashboardChartList]);

  const selectAllScannedCharts = useCallback(() => {
    if (allScannedChartsSelected) {
      setUnitSelectedChartsIds([]);
    } else {
      setUnitSelectedChartsIds(dashboardScannedChartList.map((it) => it.id));
    }
  }, [
    setUnitSelectedChartsIds,
    allScannedChartsSelected,
    dashboardScannedChartList,
  ]);

  const onReorderDrop = useCallback(
    (info: any) => {
      const movedChartId = info.dragNode.key;
      const initialPosition = selectedChartsIds!!.indexOf(movedChartId);
      const finalPosition = Math.max(0, info.dropPosition);

      const tempSelectedChartIdsCopy = [...selectedChartsIds!!];
      const tempFutureId = tempSelectedChartIdsCopy[finalPosition];

      tempSelectedChartIdsCopy[initialPosition] = tempFutureId;
      tempSelectedChartIdsCopy[finalPosition] = movedChartId;
      setUnitSelectedChartsIds(tempSelectedChartIdsCopy);
    },
    [selectedChartsIds, setUnitSelectedChartsIds]
  );

  return (
    <TabContentBox>
      <TabVehicleModalScan
        gatewayList={gatewayList}
        onCancel={onSwitchScan}
        onConfirm={onScanConfirm}
        open={scan}
      />
      <AppLoader
        loading={
          (!!fetchDashboardChartListState &&
            fetchDashboardChartListState.loading) ||
          (!!scanDashboardChartListState &&
            scanDashboardChartListState.loading) ||
          (!!fetchGatewayListState && fetchGatewayListState.loading)
        }
      />
      {!dashboardChartList || isEmpty(dashboardChartList) ? (
        <AppStateComponent
          description={
            "No hay variables configuradas para monitoreo en esta unidad."
          }
          title={"No hay variables"}
          animation={animationData}
        />
      ) : (
        <div
          className={
            "flex flex-col gap-2 overflow-x-hidden overflow-y-scroll pt-2"
          }
          ref={divRef}
        >
          <div className="flex flex-row flex-wrap px-4 mb-4 lg:px-6">
            {gatewayList &&
              !isEmpty(gatewayList) &&
              gatewayList.map((gateway) => (
                <GatewayStats
                  gateway={gateway as OnlineGateway}
                  key={gateway.key}
                />
              ))}
          </div>

          <div className="grid grid-cols-1 md:gap-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="col-span-1">
              <VehicleGalleryTab vehicle={vehicle} size="sm" />
            </div>
            <div className="col-span-1 lg:col-span-2 flex flex-col gap-1">
              <Typography.Text type={"secondary"}>
                Monitorear las siguientes variables:
              </Typography.Text>
              <div
                className={
                  "rounded-lg shadow px-4 pt-3 bg-card md:bg-transparent md:shadow-none md:h-full"
                }
              >
                <Form
                  layout={"vertical"}
                  className="md:h-full md:flex md:flex-col"
                >
                  <Form.Item
                    label={
                      <Checkbox
                        onChange={selectAllCharts}
                        checked={allChartsSelected}
                      >
                        <Typography.Text strong>
                          Todas las variables
                        </Typography.Text>
                      </Checkbox>
                    }
                  >
                    <Checkbox.Group
                      value={selectedChartsIds}
                      onChange={onChartsSelected}
                    >
                      {dashboardChartList?.map((it) => {
                        return (
                          <Checkbox key={`cb-${it.id}`} value={it.id}>
                            {it.ChartDefinition.name}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                  {!isEmpty(dashboardScannedChartList) && (
                    <Fragment>
                      <Divider />
                      <Form.Item
                        label={
                          <Checkbox
                            onChange={selectAllScannedCharts}
                            checked={allScannedChartsSelected}
                          >
                            <Typography.Text strong>
                              Variables escaneadas
                            </Typography.Text>
                          </Checkbox>
                        }
                      >
                        <Checkbox.Group
                          value={selectedChartsIds}
                          onChange={onChartsSelected}
                        >
                          {dashboardScannedChartList?.map((it) => {
                            return (
                              <Checkbox key={`cb-scan-${it.id}`} value={it.id}>
                                {it.ChartDefinition.name}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </Fragment>
                  )}
                  <Form.Item className="md:mt-auto">
                    <Button.Group>
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faSearch} />}
                        shape="round"
                        onClick={onSwitchScan}
                      >
                        Escanear Variables
                      </Button>
                      {!isEmpty(selectedCharts) && !reorder && (
                        <Button
                          type="default"
                          icon={<FontAwesomeIcon icon={faUpDownLeftRight} />}
                          shape="round"
                          onClick={onSwitchReorder}
                        >
                          Reordenar
                        </Button>
                      )}
                    </Button.Group>
                  </Form.Item>
                </Form>
              </div>
              {reorder && !isEmpty(selectedCharts) && (
                <Card
                  title={
                    <div className="flex flex-row gap-1">
                      <Button
                        type="primary"
                        size="small"
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faClose} />}
                        onClick={onSwitchReorder}
                      />
                      Disposición de los gráficos
                    </div>
                  }
                  size="small"
                >
                  <Tree
                    className="draggable-tree"
                    draggable
                    blockNode
                    onDrop={onReorderDrop}
                    treeData={selectedCharts!!.map((it) => ({
                      title: it.ChartDefinition.name,
                      key: it.id,
                    }))}
                  />
                </Card>
              )}
            </div>
          </div>

          {selectedChartsIds && !isEmpty(selectedChartsIds) ? (
            <div
              className={
                "overflow-x-visible w-full flex-wrap flex flex-row justify-stretch gap-2"
              }
              ref={c1Ref}
            >
              <div className="bg-background">
                <FullscreenToggle
                  containerRef={divRef}
                  className={"absolute right-2 top-0"}
                />

                {!!layout && (
                  <ReactGridLayout
                    layout={layout.layout}
                    cols={layout.cols}
                    width={c1Width}
                    rowHeight={layout.rowHeight}
                    className="layout"
                  >
                    {layout.layout.map((it, i) => {
                      const currentChart =
                        selectedCharts!![toNumber(it.i.split("_")[1])];
                      return (
                        <div
                          key={it.i}
                          className="w-full h-full overflow-visible"
                        >
                          <DashboardChartRenderer chart={currentChart} />
                        </div>
                      );
                    })}
                  </ReactGridLayout>
                )}
              </div>
            </div>
          ) : (
            <AppStateComponent
              description={"Selecciona variables para visualizar."}
              title={"Selecciona las variables"}
              animation={animationData}
            />
          )}
        </div>
      )}
    </TabContentBox>
  );
};
