import { useState } from "react";
import {
  VehicleChart,
  VehicleChartSelection,
} from "../../../../../gateway/domain/type/VehicleChart";
import { VehicleChartRepository } from "../../../../../../core/data/repository/VehicleChart";
import { AppTaskState } from "../../../../../../app/domain/type/State";
import { AppTaskStateG } from "../../../../../../app/domain/model/State";

export function useUnitChartViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [unitChartList, setUnitChartList] = useState<VehicleChart[] | null>(
    null
  );

  async function fetchChartList(id: number, selection?: VehicleChartSelection) {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await VehicleChartRepository.getVehicleCharts(id, selection);
      if (list.ok) {
        setUnitChartList(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  return {
    fetchListState,
    fetchChartList,
    onFetchListStateReceived,
    unitChartList,
  };
}
