import { FC, useCallback } from "react";
import { Modal, Typography } from "antd";

type SessionExpiredModalProps = {
  visible: boolean;
};

export const SessionExpiredModal: FC<SessionExpiredModalProps> = ({
  visible,
}) => {
  const onOk = useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <Modal
      title="La sesión ha expirado"
      okText={"Actualizar"}
      open={visible}
      okType="primary"
      onOk={onOk}
      cancelText={null}
      cancelButtonProps={{
        className: "hidden",
      }}
    >
      <Typography.Paragraph>
        Tu sesión ha expirado. Por favor, vuelve a iniciar sesión o recarga la
        página.
      </Typography.Paragraph>
    </Modal>
  );
};
