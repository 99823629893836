import { ThemeConfig, theme } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";
import { toHex } from "color2k";
export class LightThemeGenerator extends AppAntThemeGenerator {
  override build(): ThemeConfig {
    const {
      colorPrimary,
      colorSecondary,
      cardBackground,
      cardSecondaryBackground,
      textOnCardBackground,
      textOnSecondaryCardBackground,
      textOnSecondary,
      textOnPrimary,
    } = this.palette;

    return {
      algorithm: theme.compactAlgorithm,
      token: {
        colorPrimary: colorPrimary,
        colorBorderSecondary: "rgba(28,44,84,0.15)",
        colorBorder: "rgba(28,44,84,0.15)",
        colorBgBase: "#FFF",
        colorLink: colorPrimary,
        linkDecoration: "underline",
        colorText: "#595959",
        colorTextSecondary: "#959595",
        fontFamily: "var(--font-primary), sans-serif",
        colorPrimaryText: textOnPrimary,
      },
      components: {
        Button: {
          primaryColor: textOnPrimary,
          primaryShadow: "0 2px 0 rgba(0,0,0,0.045)",
        },
        Layout: {
          headerBg: "transparent",
          triggerBg: "transparent",
          colorBgContainer: "#fafafa",
          siderBg: "transparent",
          lightSiderBg: "#fbfbfb",
          lightTriggerBg: "transparent",
          lightTriggerColor: "#424242",
          triggerHeight: 65,
          footerBg: "#EFEFEF",
        },
        Drawer: {
          colorIcon: "#212121",
          colorText: "#212121",
          colorIconHover: "#424242",
          colorSplit: "rgba(255,255,255,0.5)",
          colorBgElevated: "#fff",
        },
        Table: {
          colorBorder: "rgba(28,44,84,0.15)",
          colorBorderBg: "rgba(28,44,84,0.15)",
          colorBorderSecondary: "rgba(28,44,84,0.15)",
          colorBgTextHover: colorPrimary,
          colorText: "#424242",
          headerBg: colorSecondary,
          headerColor: textOnSecondary,
          fontWeightStrong: 400,
          rowHoverBg: "rgba(28,44,84,0.1)",
          headerFilterHoverBg: "#424242",
          colorIcon: textOnSecondary,
          colorPrimaryTextHover: "#424242",
        },
        Input: {
          colorText: "#505050",
        },
        Alert: {
          colorInfoBg: "#ccf7ff",
          colorErrorBg: "#ffe0e0",
          colorErrorTextActive: "#640000",
          colorError: "#640000",
          colorErrorBorder: "#640000",
          colorWarningBg: "#f5eac5",
          colorInfoText: "#212121",
          colorTextBase: "#212121",
        },
        Card: {
          colorBgContainer: cardBackground,
          colorBgElevated: cardSecondaryBackground,
          boxShadowTertiary: "1px 1px 3px rgba(0,0,0,0.13)",
          zIndexBase: 20,
          zIndexPopupBase: 20,
          colorText: textOnCardBackground,
        },

        Progress: {
          colorText: textOnPrimary,
          colorTextBase: textOnPrimary,
          circleTextColor: textOnPrimary,
          colorInfoText: textOnPrimary,
          colorPrimaryText: textOnPrimary,
          colorTextLabel: textOnPrimary,
          colorTextSecondary: textOnPrimary,
        },
      },
    };
  }
}
