import { RouteObject } from "react-router-dom";
import { RouteGenerator } from "../../../../domain/model/RouteGenerator";
import { MainLayout } from "../../presentation/Layout";

import { MainPaths } from "./Paths";
import { AuthPage } from "../../../../../features/auth/presentation/Page";
import { ForgotPasswordPage } from "../../../../../features/forgot_password/presentation/Page";
import { PasswordUpdatePage } from "../../../../../features/update_password/presentation/Page";

export class MainRouteGenerator extends RouteGenerator {
  override build(): RouteObject[] {
    return [
      {
        path: MainPaths.root,
        element: <MainLayout />,
        children: [
          {
            path: MainPaths.auth,
            element: <AuthPage />,
          },
          {
            path: MainPaths.password_recovery,
            element: <ForgotPasswordPage />,
          },
          {
            path: MainPaths.password_update,
            element: <PasswordUpdatePage />,
          },
        ],
      },
    ];
  }
}
