import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faIndustry } from "@fortawesome/free-solid-svg-icons";
import { toNumber } from "lodash";
import { useVehicleViewModel } from "./ViewModel";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { ResponsiveStateContainer } from "../../../core/presentation/component/StateContainer";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { VehicleDataComponent } from "./component/Data";
import { DashboardPaths } from "../../../core/layout/dashboard/domain/implementation/Paths";

type Props = {};
export const VehiclePage: FC<Props> = () => {
  const { id } = useParams();
  const {
    vehicle,
    fetchVehicleState,
    fetchVehicle,
    onFetchVehicleStateReceived,
  } = useVehicleViewModel();

  const fetchVehicleData = useCallback(() => {
    void fetchVehicle(toNumber(id));
  }, [vehicle]);

  useEffect(() => {
    void fetchVehicleData();
  }, []);

  useEffect(() => {
    if (!!fetchVehicleState && !fetchVehicleState.loading) {
      if (fetchVehicleState.hasError) {
        notification.error({
          message: "Error al obtener el vehículo",
        });
      }
      onFetchVehicleStateReceived();
    }
  }, [fetchVehicleState]);

  return (
    <Fragment>
      <AppLoader loading={!!fetchVehicleState && fetchVehicleState?.loading} />
      <ResponsiveStateContainer
        state={fetchVehicleState}
        className={"w-full h-fit overflow-hidden"}
      >
        <CustomBreadCrumb
          items={[
            {
              pathname: DashboardPaths.fleet_list!!,
              title: "Flota",
              icon: <FontAwesomeIcon icon={faIndustry} />,
            },

            {
              active: true,
              title: vehicle?.name,
            },
          ]}
        />
        <ContentHeader
          title={"Unidad"}
          subtitle={vehicle?.name}
          extra={
            <Button.Group>
              <Button
                type={"primary"}
                icon={<FontAwesomeIcon icon={faRefresh} />}
                onClick={fetchVehicleData}
              >
                Actualizar
              </Button>
            </Button.Group>
          }
        />
        {vehicle ? <VehicleDataComponent vehicle={vehicle} /> : null}
      </ResponsiveStateContainer>
    </Fragment>
  );
};
