import { useState } from "react";
import { AppTaskState } from "../../../../../../app/domain/type/State";
import { AppTaskStateG } from "../../../../../../app/domain/model/State";
import { UnityEventRepository } from "../../../../../../core/data/repository/UnityEvent";
import {
  UnitEventCountItem,
  UnitEventItem,
} from "../../../../../../core/domain/type/UnitEvent";

export function useUnitAlertViewModel() {
  const [fetchEventListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchEventCountState, setFetchCountState] =
    useState<AppTaskState<boolean> | null>(null);
  const [eventCount, setEventCount] = useState<UnitEventCountItem[] | null>(
    null
  );
  const [eventList, setEventList] = useState<UnitEventItem[]>();

  async function fetchEventCount(id: number, dateStart: Date, dateEnd: Date) {
    if (fetchEventCountState?.loading) return;
    setFetchCountState(AppTaskStateG.loading());
    try {
      const list = await UnityEventRepository.getUnityEventCount(
        id,
        dateStart,
        dateEnd
      );
      if (list.ok) {
        setEventCount(list.data!!);
        setFetchCountState(AppTaskStateG.success(true));
      } else setFetchCountState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchCountState(AppTaskStateG.error(error));
    }
  }

  function onFetchCountStateReceived() {
    setFetchCountState(null);
  }

  async function fetchEventList(id: number, limit: number) {
    if (fetchEventListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await UnityEventRepository.getUnityLastEvents(id, limit);
      if (list.ok) {
        setEventList(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  function onFetchEventListStateReceived() {
    setFetchListState(null);
  }

  return {
    fetchEventListState,
    fetchEventCountState,
    eventCount,
    eventList,
    fetchEventCount,
    onFetchCountStateReceived,
    fetchEventList,
    onFetchEventListStateReceived,
  };
}
