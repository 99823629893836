import { RouteObject } from "react-router-dom";
import { RouteGenerator } from "../../../../domain/model/RouteGenerator";
import { DashboardLayout } from "../../presentation/Layout";
import { DashboardPaths } from "./Paths";
import { VehicleListPage } from "../../../../../features/vehicle_list/presentation/Page";
import { GatewayListPage } from "../../../../../features/gateway_list/presentation/Page";
import { UserListPage } from "../../../../../features/user_list/presentation/Page";
import { CustomerPage } from "../../../../../features/customer/presentation/Page";
import { VehiclePage } from "../../../../../features/vehicle/presentation/Page";
import { ParamTypeValidatorLoader } from "../../../../util/Params";
import { CustomerUserPage } from "../../../../../features/customer_user/presentation/Page";
import { GatewayPage } from "../../../../../features/gateway/presentation/Page";
import { FleetTrackingPage } from "../../../../../features/fleet_tracking/presentation/Page";
import { FleetDashboardPage } from "../../../../../features/fleet_dashboard/presentation/Page";
import { FleetHistoricalPage } from "../../../../../features/fleet_historical/presentation/Page";
import { FleetReportPage } from "../../../../../features/fleet_report/presentation/Page";
import { FleetAlertPage } from "../../../../../features/fleet_alert/presentation/Page";

export class DashboardRouteGenerator extends RouteGenerator {
  override build(): RouteObject[] {
    return [
      {
        path: DashboardPaths.dashboard,
        element: <DashboardLayout />,
        children: [
          // {
          //   path: DashboardPaths.overview,
          //   element: <OverviewPage />,
          // },
          {
            path: DashboardPaths.overview,
            element: <FleetDashboardPage />,
          },
          {
            path: DashboardPaths.fleet_list,
            element: <VehicleListPage />,
          },
          {
            path: DashboardPaths.gateway_list,
            element: <GatewayListPage />,
          },
          {
            path: DashboardPaths.fleet_tracking,
            element: <FleetTrackingPage />,
          },
          {
            path: DashboardPaths.fleet_dashboard,
            element: <FleetDashboardPage />,
          },
          {
            path: DashboardPaths.fleet_historical,
            element: <FleetHistoricalPage />,
          },
          {
            path: DashboardPaths.fleet_reports,
            element: <FleetReportPage />,
          },
          {
            path: DashboardPaths.fleet_events,
            element: <FleetAlertPage />,
          },
          {
            path: DashboardPaths.user_list,
            element: <UserListPage />,
          },
          {
            path: DashboardPaths.customer,
            element: <CustomerPage />,
          },
          {
            path: DashboardPaths.unit_p_id,
            element: <VehiclePage />,
            loader: ParamTypeValidatorLoader("id"),
          },
          {
            path: DashboardPaths.user_p_id,
            element: <CustomerUserPage />,
            loader: ParamTypeValidatorLoader("id"),
          },
          {
            path: DashboardPaths.gateway_p_id,
            element: <GatewayPage />,
            loader: ParamTypeValidatorLoader("id"),
          },
        ],
      },
    ];
  }
}
