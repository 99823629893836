import { FC, useEffect } from "react";
import { Button, Tabs, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faWarning } from "@fortawesome/free-solid-svg-icons";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { useFleetDashboardPageViewModel } from "./ViewModel";
import { isEmpty } from "lodash";
import { AppEmptyContentStateComponent } from "../../../core/presentation/component/State";
import { VehicleAlertsTab } from "../../vehicle/presentation/component/tabs/alerts/TabAlerts";

export const FleetAlertPage: FC = () => {
  const { fetchFleet, fetchFleetState, fleet, onFetchFleetStateReceived } =
    useFleetDashboardPageViewModel();
  useEffect(() => {
    fetchFleet();
  }, []);

  useEffect(() => {
    if (!!fetchFleetState && !fetchFleetState.loading) {
      if (fetchFleetState.error) {
        notification.error({
          message: "Error al actualizar la lista de unidades.",
          description: fetchFleetState.error.message,
        });
      }
      onFetchFleetStateReceived();
    }
  }, [fetchFleetState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={fetchFleetState?.loading ?? false} />
      <CustomBreadCrumb
        items={[
          {
            pathname: "/dashboard/events",
            title: "Eventos",
            active: true,
            icon: <FontAwesomeIcon icon={faWarning} />,
          },
        ]}
      />

      <ContentHeader
        title={"Eventos de flota"}
        subtitle={"Visualiza un historial  de eventos ocurridos en la flota"}
        extra={
          <Button.Group>
            <Button
              onClick={fetchFleet}
              type={"primary"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      {isEmpty(fleet) ? (
        <AppEmptyContentStateComponent description="No hay unidades disponibles para visualizar." />
      ) : (
        <Tabs
          items={fleet.map((it) => ({
            key: `vehicle-${it.id}`,
            label: it.name,
            children: <VehicleAlertsTab vehicle={it} />,
          }))}
        />
      )}
    </ResponsiveContainer>
  );
};
