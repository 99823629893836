import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { UnitAsset } from "../../domain/type/Asset";
import { CustomerApiService } from "../../../../app/service/RemoteClient";

export class RemoteUnitAssetSource extends BaseRemoteSource {
  static async getGallery(
    vehicleId: number | undefined
  ): Promise<RemoteSourceResponse<UnitAsset[]>> {
    try {
      const gallery = await CustomerApiService.getWithAuth(
        `/units/${vehicleId}/assets`
      );
      this.checkResponseCredentials(gallery);
      return gallery.data as RemoteSourceResponse<UnitAsset[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
