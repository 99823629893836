import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import {
  VehicleChart,
  VehicleChartSelection,
} from "../../../features/gateway/domain/type/VehicleChart";
import { CustomerApiService } from "../../../app/service/RemoteClient";

export class RemoteVehicleChartSource extends BaseRemoteSource {
  static async getVehicleCharts(
    id: number,
    selection?: VehicleChartSelection
  ): Promise<RemoteSourceResponse<Array<VehicleChart>>> {
    try {
      let type = !!selection?.dashboard
        ? "dashboard"
        : !!selection?.report
        ? "report"
        : !!selection?.event
        ? "event"
        : "historical";
      const response = await CustomerApiService.getWithAuth(
        `/units/${id}/charts/${type}`
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<VehicleChart>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerCharts(
    selection?: VehicleChartSelection
  ): Promise<RemoteSourceResponse<Array<VehicleChart>>> {
    try {
      const response = await CustomerApiService.postWithAuth(
        "/vehicle_chart/list_by_customer",
        {
          selection,
        }
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<VehicleChart>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async scanVehicleCharts(
    gatewayId: number,
    vehicleId: number
  ): Promise<RemoteSourceResponse<Array<VehicleChart>>> {
    try {
      const response = await CustomerApiService.getWithAuth(
        `/units/${vehicleId}/gateways/${gatewayId}/scan`,
        {
          gatewayId,
          vehicleId,
        }
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<VehicleChart>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
