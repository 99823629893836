import { FC } from "react";
import { Tabs } from "antd";

import { Vehicle } from "../../../../domain/type/Vehicle";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarChart, faHistory } from "@fortawesome/free-solid-svg-icons";
import { VehicleAlertsHistoryTab } from "./TabAlertsHistory";
import { VehicleAlertsReportTab } from "./TabAlertsGraphics";

type Props = {
  vehicle: Vehicle;
};

export const VehicleAlertsTab: FC<Props> = ({ vehicle }) => {
  return (
    <ResponsiveContainer>
      <Tabs
        type="card"
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Reporte",
            icon: <FontAwesomeIcon icon={faBarChart} />,
            children: <VehicleAlertsReportTab vehicle={vehicle} />,
          },
          {
            key: "2",
            icon: <FontAwesomeIcon icon={faHistory} />,
            label: "Historial",
            children: <VehicleAlertsHistoryTab vehicle={vehicle} />,
          },
        ]}
      />
    </ResponsiveContainer>
  );
};
