import { FC, MouseEventHandler, useCallback, useMemo } from "react";
import { Button, ConfigProvider, Switch, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faMoon,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { useAppTheme } from "../../../../../app/hook/Theme";
import { useAppUser } from "../../../../../app/hook/User";
import { CustomerLogo } from "../../../../presentation/component/CustomerLogo";

type Props = {
  onActionClick: (action: string) => void;
  elevated: boolean;
  collapsed: boolean;
};

export const DashboardLayoutHeaderLarge: FC<Props> = ({
  onActionClick,
  elevated,
  collapsed,
}) => {
  const { isDarkThemeEnabled, switchDarkTheme, colorPalette } = useAppTheme();
  const { customerUser } = useAppUser();
  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      onActionClick(e.currentTarget.dataset["key"] as string);
    },
    [onActionClick]
  );

  const avatarName = useMemo(() => {
    if (!!customerUser?.username) {
      return customerUser?.username;
    } else if (!!customerUser?.name) {
      return `${customerUser?.name} ${customerUser?.lastname}`;
    } else {
      return "User";
    }
  }, [customerUser]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: colorPalette.headerBackground,
            primaryColor: colorPalette.textOnHeaderBackground,
            defaultColor: colorPalette.textOnPrimary,
            defaultBg: colorPalette.primary,
            colorText: colorPalette.textOnHeaderBackground,
          },
        },
      }}
    >
      <div className="absolute left-0 top-0 w-full h-full">
        <div
          className={`${
            elevated
              ? "bg-header-elevated backdrop-blur-sm dark:backdrop-blur-sm shadow-md border-transparent"
              : "bg-transparent border-header-elevated"
          } w-full h-full flex flex-row place-items-center place-content-start transition-all border-0 border-b border-solid`}
          style={{
            zIndex: 9999,
          }}
        >
          <div
            className={`h-full flex-1 overflow-hidden my-auto flex flex-row justify-end items-center`}
          >
            <div className="flex flex-1 items-center justify-start pl-36">
              <span
                className={`text-xl font-secondary p-0 m-0 font-normal transition-all ${
                  elevated
                    ? "text-header-elevated-text"
                    : "text-background-text"
                }`}
              >
                Dashboard
              </span>
            </div>
            <div
              className={`h-full max-w-xs relative overflow-visible mr-3 transition-all duration-500 ${
                collapsed ? "opacity-100 delay-500" : "opacity-0"
              }`}
            >
              <div
                className={`-skew-x-20 h-full transition-all w-full duration-500 absolute m-auto inset-0 ${
                  elevated ? "bg-transparent" : "bg-header"
                }`}
              />
              <div className="w-full h-full p-2.5 overflow-hidden">
                <CustomerLogo />
              </div>
            </div>
          </div>

          <div
            className={
              "flex flex-row h-full place-items-center pl-2 pr-8 gap-2 overflow-hidden py-2.5"
            }
          >
            <div
              onClick={onClick}
              data-key={"user"}
              className="h-full rounded-full flex flex-row gap-1 pl-1 py-1 pr-2 items-center bg-header text-header-text"
            >
              <div className="h-full aspect-square bg-primary rounded-full flex p-0 items-center justify-center">
                {avatarName[0]}
              </div>
              <div className="flex flex-col overflow-hidden leading-none">
                <span
                  className="font-secondary p-0"
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  {avatarName}
                </span>
                {customerUser?.Role.description && (
                  <span
                    style={{
                      fontSize: "0.70rem",
                      opacity: 0.5,
                    }}
                    className="font-primary text-xs text-opacity-25 p-0"
                  >
                    {customerUser?.Role.description}
                  </span>
                )}
              </div>
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faChevronDown} />}
              />
            </div>

            <Switch
              checked={isDarkThemeEnabled}
              checkedChildren={<FontAwesomeIcon icon={faSun} />}
              unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
              onChange={switchDarkTheme}
            />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};
