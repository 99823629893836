import { FC, PropsWithChildren } from "react";
import { DashboardLayoutComponent } from "./component/DahboardLayout";
import { DashboardHeaderProvider } from "./context/Header";
import { DashboardMenuProvider } from "./context/Menu";
import { ConfigProvider } from "antd";
import { useAppTheme } from "../../../../app/hook/Theme";

export const DashboardLayout: FC<PropsWithChildren> = () => {
  const { themes } = useAppTheme();
  return (
    <ConfigProvider theme={themes.secondaryTheme}>
      <DashboardMenuProvider>
        <DashboardHeaderProvider>
          <DashboardLayoutComponent />
        </DashboardHeaderProvider>
      </DashboardMenuProvider>
    </ConfigProvider>
  );
};
