import { FC, useCallback } from "react";
import { DatePicker as AntdDatePicker, DatePickerProps } from "antd";
import { useFormikContext } from "formik";
import { Dayjs } from "dayjs";
import { isNil } from "lodash";
type Props = Omit<DatePickerProps, "name" | "value" | "onChange" | "status"> & {
  name: string;
};

export const DatePicker: FC<Props> = ({ name, ...props }) => {
  const {
    errors,
    initialTouched,
    initialErrors,
    isValidating,
    isInitialValid,
    isSubmitting,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const fieldTouched = (initialTouched as any)[name] || (touched as any)[name];
  const fieldError = (initialErrors as any)[name] || (errors as any)[name];
  const readyToShowStatus = !isValidating || !isSubmitting;
  const fieldValid = isInitialValid || isValid;

  const value = !isNil((values as any)[name])
    ? new Dayjs((values as any)[name])
    : null;
  const onChangeCaptured = useCallback(
    (value: Dayjs, _: string | string[]) => {
      setFieldTouched(name, true);
      setFieldValue(name, value.toISOString());
    },
    [setFieldValue, name, setFieldTouched]
  );

  const status: DatePickerProps["status"] = readyToShowStatus
    ? fieldTouched
      ? fieldError
        ? "error"
        : fieldValid
        ? ""
        : "warning"
      : ""
    : "";

  return (
    <AntdDatePicker
      name={name}
      value={value}
      onChange={onChangeCaptured}
      status={status}
      {...props}
    />
  );
};
