import { RemoteUnityEventSource } from "../source/RemoteUnityEvent";

export class UnityEventRepository {
  static async getUnityEventCount(
    vehicleId: number,
    dateStart: Date,
    dateEnd: Date
  ) {
    return await RemoteUnityEventSource.getUnityEventCount(
      vehicleId,
      dateStart,
      dateEnd
    );
  }
  static async getUnityLastEvents(vehicleId: number, limit: number) {
    return await RemoteUnityEventSource.getUnityLastEvents(vehicleId, limit);
  }
}
