import { FC, useCallback } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { RangePickerProps } from "antd/es/date-picker";
type Props = Omit<
  RangePickerProps,
  "name" | "value" | "onChange" | "status"
> & {
  name: string;
};

export const RangePicker: FC<Props> = ({ name, ...props }) => {
  const {
    errors,
    initialTouched,
    initialErrors,
    isValidating,
    isInitialValid,
    isSubmitting,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const fieldTouched = (initialTouched as any)[name] || (touched as any)[name];
  const fieldError = (initialErrors as any)[name] || (errors as any)[name];
  const readyToShowStatus = !isValidating || !isSubmitting;
  const fieldValid = isInitialValid || isValid;

  const value = !isNil((values as any)?.[name])
    ? [dayjs((values as any)?.[name]?.[0]), dayjs((values as any)?.[name]?.[1])]
    : null;
  const onChangeCaptured: any = useCallback(
    (value: [Dayjs, Dayjs] | null, _: [string, string]) => {
      setFieldTouched(name, true);
      const newValue = value
        ? [
            value[0] ? value[0].toDate() : null,
            value[1] ? value[1].toDate() : null,
          ]
        : null;
      setFieldValue(name, newValue);
    },
    [setFieldValue, name, setFieldTouched]
  );

  const status: RangePickerProps["status"] = readyToShowStatus
    ? fieldTouched
      ? fieldError
        ? "error"
        : fieldValid
        ? ""
        : "warning"
      : ""
    : "";

  const onFocused = useCallback(() => {
    setFieldTouched(name, true);
  }, [setFieldTouched, name]);

  return (
    <AntdDatePicker.RangePicker
      name={name}
      value={value as any}
      onChange={onChangeCaptured}
      status={status}
      onFocus={onFocused}
      showTime
      {...props}
    />
  );
};
