import { useState } from "react";
import { AppTaskStateG } from "../../../../app/domain/model/State";
import { CustomerRepository } from "../../../../features/customer/data/repository/Customer";
import { CustomerUser } from "../../../../features/customer_user/domain/type/CustomerUser";
import { AuthRepository } from "../../../../features/auth/data/repository/Auth";
import { Customer } from "../../../../features/customer/domain/type/Customer";
import { AppTaskState } from "../../../../app/domain/type/State";
import { DashboardMenu } from "../domain/type/Menu";
import { DashboardRepository } from "../data/repository/Dashboard";

export function useDashboardLayoutViewModel() {
  const [fetchCustomerState, setFetchCustomerState] =
    useState<AppTaskState<Customer> | null>(null);
  const [fetchCurrentUserState, setFetchCurrentUserState] =
    useState<AppTaskState<CustomerUser> | null>(null);
  const [fetchMenuState, setFetchMenuState] =
    useState<AppTaskState<DashboardMenu> | null>(null);
  async function fetchCustomer() {
    if (fetchCustomerState?.loading) return;
    setFetchCustomerState(AppTaskStateG.loading());
    try {
      const customer = await CustomerRepository.getCustomer();
      if (customer.ok) {
        setFetchCustomerState(AppTaskStateG.success(customer.data!!));
      } else {
        setFetchCustomerState(
          AppTaskStateG.error(new Error(customer.message!!))
        );
      }
    } catch (e: any) {
      setFetchCustomerState(AppTaskStateG.error(e));
    }
  }

  function onFetchCustomerStateReceived() {
    setFetchCustomerState(null);
  }

  async function fetchCurrentUser() {
    if (fetchCurrentUserState?.loading) return;
    setFetchCurrentUserState(AppTaskStateG.loading());
    try {
      const session = await AuthRepository.getSession();
      if (session?.ok) {
        setFetchCurrentUserState(AppTaskStateG.success(session.data!!));
      } else {
        setFetchCurrentUserState(
          AppTaskStateG.error(new Error(session?.message))
        );
      }
    } catch (e: any) {
      setFetchCurrentUserState(AppTaskStateG.error(e));
    }
  }

  function onFetchCurrentUserStateReceived() {
    setFetchCurrentUserState(null);
  }

  async function fetchMenu() {
    if (fetchMenuState?.loading) return;
    setFetchMenuState(AppTaskStateG.loading());
    try {
      const menuResult = await DashboardRepository.getMenu();
      if (menuResult?.ok) {
        setFetchMenuState(AppTaskStateG.success(menuResult!!.data!!));
      } else {
        setFetchMenuState(
          AppTaskStateG.error(new Error(menuResult?.message!!))
        );
      }
    } catch (e: any) {
      setFetchMenuState(AppTaskStateG.error(e));
    }
  }

  function onFetchMenuStateReceived() {
    setFetchMenuState(null);
  }

  return {
    fetchCustomerState,
    fetchCustomer,
    onFetchCustomerStateReceived,
    fetchCurrentUser,
    fetchCurrentUserState,
    onFetchCurrentUserStateReceived,
    fetchMenu,
    onFetchMenuStateReceived,
    fetchMenuState,
  };
}
