import { FC, useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownload,
  faTimeline,
  faChartSimple,
  faMapLocation,
  faMap,
  faTriangleExclamation,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
import { Vehicle } from "../../domain/type/Vehicle";
import { VehicleLogsTab } from "./tabs/logs/TabLogs";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { VehicleDeviceTab } from "./tabs/device/TabDevice";
import { VehicleTrackingTab } from "./tabs/tracking/TabTracking";
import { VehicleDeviceHistoryTab } from "./tabs/device_history/TabDeviceHistory";
import { useSearchParams } from "react-router-dom";
import { VehicleAlertsTab } from "./tabs/alerts/TabAlerts";

type Props = {
  vehicle: Vehicle;
};

export const VehicleDataComponent: FC<Props> = ({ vehicle }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onTabClicked = useCallback(
    (activeKey: string) => {
      setSearchParams({ tab: activeKey });
    },
    [setSearchParams]
  );

  const items = useMemo<TabsProps["items"]>(() => {
    return [
      {
        key: "dashboard",
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        label: "Dashboard",
        children: <VehicleDeviceTab vehicle={vehicle} />,
      },
      {
        key: "tracking",
        icon: <FontAwesomeIcon icon={faMapLocation} />,
        label: "Tracking",
        children: <VehicleTrackingTab vehicle={vehicle} />,
      },
      // {
      //   key: "tracking_historical",
      //   icon: <FontAwesomeIcon icon={faMap} />,
      //   label: "Tracking Histórico",
      //   children: <VehicleTrackingHistoryTab vehicle={vehicle} />,
      // },
      {
        key: "logs",
        icon: <FontAwesomeIcon icon={faCloudDownload} />,
        label: "Reportes",
        children: <VehicleLogsTab vehicle={vehicle} />,
      },
      {
        key: "history",
        icon: <FontAwesomeIcon icon={faTimeline} />,
        label: "Histórico",
        children: <VehicleDeviceHistoryTab vehicle={vehicle} />,
      },
      {
        key: "alerts",
        icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
        label: "Alertas",
        children: <VehicleAlertsTab vehicle={vehicle} />,
      },
      // {
      //   key: "gallery",
      //   icon: <FontAwesomeIcon icon={faImages} />,
      //   label: "Galería",
      //   children: <VehicleGalleryTab vehicle={vehicle} />,
      // },
    ];
  }, [vehicle]);

  return (
    <Tabs
      size="small"
      items={items}
      activeKey={searchParams.get("tab") || "dashboard"}
      onTabClick={onTabClicked}
    />
  );
};
