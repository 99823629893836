import { io } from "socket.io-client";

import { LocalTokenUseCase } from "../domain/usecase/Token";
export const wsHostConfig = {
  host: process.env.REACT_APP_REMOTE_HOST || "localhost",
  path: "/api/customer_ws",
};
const socket = io(wsHostConfig.host, {
  autoConnect: false,
  path: wsHostConfig.path,
  query: {
    token: LocalTokenUseCase.getAccessToken(),
  },
  transports: ["websocket", "polling"],
});

export class SocketIOService {
  static async socketConnect(uid: number) {
    if (!socket || socket.disconnected) {
      socket.disconnect();
      if (!!socket.io.opts && !!socket.io.opts.query) {
        socket.io.opts.query = {
          token: LocalTokenUseCase.getAccessToken(),
          uid,
        };
      }
      socket.connect();
    }
  }

  static async socketDisconnect() {
    if (socket && socket.connected) {
      socket.disconnect();
    }
  }

  static async socketOn(event: string, listener: (data: any) => void) {
    socket.on(event, listener);
  }

  static async socketOff(event: string, listener: (data: any) => void) {
    socket.off(event, listener);
  }

  static async socketSend<T = any>(eventName: string, body: T) {
    socket.emit(eventName, body);
  }
}
