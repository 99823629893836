import axios, { AxiosRequestConfig } from "axios";
import FileDownload from "js-file-download";
import { LocalTokenUseCase } from "../domain/usecase/Token";
export const hostConfig = {
  host: `${process.env.REACT_APP_REMOTE_HOST}/api/customer` || "localhost",
  apiVersion: 1,
};

axios.defaults.withCredentials = true;

const RemoteApiClient = axios.create({
  baseURL: `${hostConfig.host}/v${hostConfig.apiVersion}`,
  withCredentials: true,
});
RemoteApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const event = new Event("session-expired");
      window?.dispatchEvent(event);
    }
    return Promise.reject(error);
  }
);

export const CustomerApiService = {
  get: async (url: string, config?: AxiosRequestConfig) =>
    await RemoteApiClient.get(url, config),
  getWithAuth: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.get(url, {
      ...config,
      withCredentials: true,
      headers: {
        Authorization: LocalTokenUseCase.getAccessToken(),
        "X-RefreshToken": LocalTokenUseCase.getRefreshToken(),
      },
      data,
    }),

  downloadWithAuth: async <T>(url: string, config?: AxiosRequestConfig) => {
    const result = await RemoteApiClient.get(url, {
      ...config,
      withCredentials: true,
      headers: {
        Authorization: LocalTokenUseCase.getAccessToken(),
        "X-RefreshToken": LocalTokenUseCase.getRefreshToken(),
      },
    });
    const contentDisposition = result.headers["content-disposition"];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    const filename =
      matches && matches[1] ? matches[1].replace(/['"]/g, "") : "noname";
    FileDownload(result.data, filename);
    return result;
  },
  post: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.post(url, data, config),
  postWithAuth: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.post(url, data, {
      ...config,
      withCredentials: true,
      headers: {
        Authorization: LocalTokenUseCase.getAccessToken(),
        "X-RefreshToken": LocalTokenUseCase.getRefreshToken(),
      },
    }),
  put: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.put(url, data, config),
  putWithAuth: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.put(url, data, {
      ...config,
      withCredentials: true,
      headers: {
        Authorization: LocalTokenUseCase.getAccessToken(),
        "X-RefreshToken": LocalTokenUseCase.getRefreshToken(),
      },
    }),
  delete: async (url: string, config?: AxiosRequestConfig) =>
    await RemoteApiClient.delete(url, config),
  deleteWithAuth: async (url: string, config?: AxiosRequestConfig) =>
    await RemoteApiClient.delete(url, {
      ...config,
      withCredentials: true,
      headers: {
        Authorization: LocalTokenUseCase.getAccessToken(),
        "X-RefreshToken": LocalTokenUseCase.getRefreshToken(),
      },
    }),
};
