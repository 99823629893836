import { useState, useEffect, useCallback } from "react";
import LogoAcme from "../../../assets/image/Logo-ACME.png";
import { useAppCustomer } from "../../../app/hook/Customer";
import { Spin } from "antd";
import { AssetClientHostConfig } from "../../../app/service/RemoteAssetClient";
export const CustomerLogo: React.FC = () => {
  const { customer } = useAppCustomer();
  const logoUrl = customer?.CustomerStyle?.logoFileName
    ? `${AssetClientHostConfig.host}${customer?.CustomerStyle?.logoFileName}`
    : null;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleImageError = () => {
    setError(true);
  };

  useEffect(() => {
    if (!!logoUrl) {
      setError(false);
    } else {
      setError(true);
    }
  }, [logoUrl]);

  const onLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <div className="w-fit h-full overflow-hidden flex relative">
      <Spin
        className="absolute z-50 inset-0 w-fit h-fit m-auto"
        spinning={loading}
      />
      <img
        // className="object-contain w-full text-center h-full overflow-hidden
        // filter dark:brightness-0 dark:invert"
        className="object-contain w-full text-center h-full overflow-hidden"
        src={error ? LogoAcme : logoUrl!}
        alt={"Cliente"}
        onError={handleImageError}
        loading="lazy"
        onLoad={onLoad}
        onLoadCapture={onLoad}
      />
    </div>
  );
};
