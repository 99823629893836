import { Form, FormItemProps } from "antd";
import { useFormikContext } from "formik";
import { FC } from "react";

export const FormItem: FC<Omit<FormItemProps, "validateStatus" | "help">> = ({
  name,
  ...props
}) => {
  const {
    errors,
    initialTouched,
    initialErrors,
    isValidating,
    isInitialValid,
    isSubmitting,
    isValid,
    touched,
  } = useFormikContext();

  const fieldTouched = (initialTouched as any)[name] || (touched as any)[name];
  const fieldError = (initialErrors as any)[name] || (errors as any)[name];
  const readyToShowStatus = !isValidating || !isSubmitting;
  const fieldValid = isInitialValid || isValid;
  const status: FormItemProps["status"] = readyToShowStatus
    ? fieldTouched
      ? fieldError
        ? "error"
        : fieldValid
        ? "success"
        : "warning"
      : ""
    : "validating";
  return (
    <Form.Item validateStatus={status} help={fieldError} {...props}></Form.Item>
  );
};
