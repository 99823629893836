import { FC, MouseEventHandler } from "react";
import { Avatar, Card, Typography } from "antd";
import { Vehicle } from "../../../../features/vehicle/domain/type/Vehicle";
import { ImageHOC } from "../../../../core/presentation/component/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImagePortrait } from "@fortawesome/free-solid-svg-icons";

type Props = {
  data: Vehicle[];
  onItemClicked: (item: Vehicle) => void;
};

export const VehicleListGrid: FC<Props> = ({ data, onItemClicked }) => {
  const onDivClicked = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const index = parseInt(event.currentTarget.getAttribute("data-index")!!);
    onItemClicked(data!![index]);
  };
  return (
    <div>
      {data.map((item) => {
        return (
          <CardItem
            key={`vehicle_card_${item.id}`}
            item={item}
            onClick={onDivClicked}
          />
        );
      })}
    </div>
  );
};

type CardItemProps = {
  item: Vehicle;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const CardItem: FC<CardItemProps> = ({ item, onClick }) => {
  return (
    <div className={"p-2"} data-id={item.id} onClick={onClick}>
      <Card
        className="overflow-hidden"
        cover={
          <ImageHOC
            imgProps={{ src: item.photo }}
            errorComponent={
              <div className="bg-secondary flex">
                <Avatar
                  className="m-auto inset-0"
                  icon={<FontAwesomeIcon icon={faImagePortrait} />}
                />
              </div>
            }
          />
        }
      >
        <Card.Meta title={item.name} />
      </Card>
    </div>
  );
};
