import { ThemeConfig } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";
import { theme } from "antd";

export class SecondaryMenuThemeGenerator extends AppAntThemeGenerator {
  build(): ThemeConfig {
    const { secondary, textOnSecondary, background } = this.palette;
    return {
      algorithm: theme.compactAlgorithm,
      components: {
        Menu: {
          itemSelectedBg: secondary,
          itemSelectedColor: textOnSecondary,
          colorBgElevated: background,
          colorBgBase: "transparent",
          colorBgContainer: "transparent",
        },
      },
    };
  }
}
