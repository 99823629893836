import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { useAppCustomer } from "../../../../../app/hook/Customer";
import { Typography } from "antd";
import { CustomerIcon } from "../../../../presentation/component/CustomerIcon";

type Props = {};
export const DashboardLayoutFooter: FC<Props> = ({}) => {
  const { customer } = useAppCustomer();
  return (
    <div className={"w-full relative"}>
      <div className={"w-fit flex justify-start absolute right-4 bottom-4"}>
        <Typography.Text className={"text-sm font-normal font-header"}>
          Powered by
          <Typography.Link
            href="https://acmecia.com"
            rel="_noreferrer"
            target="_blank"
            className="mx-1 font-quicksand"
          >
            ACME & CIA
            <FontAwesomeIcon className="mx-1" icon={faExternalLink} />
          </Typography.Link>
        </Typography.Text>
      </div>

      <div
        className={
          "flex flex-col md:flex-row gap-2 md:gap-12 justify-center w-fit flex-wrap overflow-x-hidden mx-auto md:mr-auto md:ml-0 px-2 md:px-8 lg:px-12 pb-12 md:mb-0 md:pb-8 lg:pb-10"
        }
      >
        <div
          className={
            "mx-auto w-24 md:w-24 lg:w-32 object-contain overflow-hidden"
          }
        >
          <CustomerIcon />
        </div>
        <div className={"flex flex-col gap-3 justify-center"}>
          <div
            className={
              "flex flex-col gap-1 text-neutral-500 dark:text-neutral-200"
            }
          >
            <Typography.Title
              className={
                "font-regular font-bold font-header text-2xl uppercase"
              }
            >
              Contacto
            </Typography.Title>
            <Typography.Text className={"uppercase text-md font-normal"}>
              <FontAwesomeIcon className=" mr-2" icon={faPhone} />{" "}
              {customer?.phone}
            </Typography.Text>
            <Typography.Text className={"uppercase text-md font-normal"}>
              <FontAwesomeIcon className=" mr-2" icon={faEnvelope} />{" "}
              {customer?.email}
            </Typography.Text>
            <Typography.Text className={"uppercase text-md font-normal"}>
              <FontAwesomeIcon className=" mr-2" icon={faMapMarker} />{" "}
              {customer?.address}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};
