import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppResponsiveScreen } from "../../../../../app/hook/Breakpoint";
import { DashboardMobileLayout } from "./DashboardMobileLayout";
import { DashboardLargeLayout } from "./DashboardLargeLayout";
import { useDashboardLayoutViewModel } from "../ViewModel";
import { useAppCustomer } from "../../../../../app/hook/Customer";
import { useAppUser } from "../../../../../app/hook/User";
import { useDashboardMenu } from "../hook/Menu";
import { SocketIOService } from "../../../../../app/service/SocketIO";
import { Outlet } from "react-router-dom";
import { AppLoader } from "../../../../presentation/component/AppLoader";
import { DashboardLayoutUserModal } from "./User";
import { Alert, Descriptions, List, Modal, notification } from "antd";
import { SessionExpiredModal } from "./SessionExpiredModal";
import useStorageState from "use-local-storage-state";
import { UnitEventContent } from "../../../../domain/type/UnitEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useSound } from "use-sound";
//@ts-ignore
import notificationSound from "../../../../../assets/sound/notification.mp3";
export const DashboardLayoutComponent: FC = () => {
  const { isMobileScreen } = useAppResponsiveScreen();
  const [unityEventList, setUnityEventList] = useState<UnitEventContent[]>([]);
  const [openEvent, setOpenEvent] = useState<number | null>(null);
  const [collapsed, setCollapsed] = useStorageState("menuCollapsed", {
    defaultValue: false,
  });
  const [playSound] = useSound(notificationSound);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const BaseLayout = useMemo(() => {
    return isMobileScreen ? DashboardMobileLayout : DashboardLargeLayout;
  }, [isMobileScreen]);
  const [sessionExpired, setSessionExpired] = useState(false);

  const sessionExpiredListener = useCallback(() => {
    setSessionExpired(true);
  }, [setSessionExpired]);

  useEffect(() => {
    window.addEventListener("session-expired", sessionExpiredListener);
    return () => {
      window.removeEventListener("session-expired", sessionExpiredListener);
    };
  }, [sessionExpiredListener]);

  const onPaginationChange = useCallback(
    (item: number) => {
      setOpenEvent(item - 1);
    },
    [setOpenEvent]
  );

  const {
    fetchCustomer,
    fetchCustomerState,
    onFetchCustomerStateReceived,
    fetchMenu,
    onFetchMenuStateReceived,
    fetchCurrentUser,
    fetchCurrentUserState,
    onFetchCurrentUserStateReceived,
    fetchMenuState,
  } = useDashboardLayoutViewModel();

  const { setCustomer } = useAppCustomer();
  const { setCustomerUser, customerUser } = useAppUser();
  const { setMenu } = useDashboardMenu();
  useEffect(() => {
    fetchCurrentUser();
    fetchCustomer();
    fetchMenu();
  }, []);

  useEffect(() => {
    if (!!fetchCustomerState && !fetchCustomerState.loading) {
      if (fetchCustomerState.hasError) {
        console.log(fetchCustomerState.error);
        notification.error({
          message: "Error",
          description: fetchCustomerState.error?.message,
        });
      } else {
        setCustomer(fetchCustomerState.data!!);
      }
      onFetchCustomerStateReceived();
    }
  }, [fetchCustomerState]);

  useEffect(() => {
    if (!!customerUser?.id) {
      SocketIOService.socketConnect(customerUser.id);
    }
    return () => {
      SocketIOService.socketDisconnect();
    };
  }, [customerUser]);

  const onCloseEventDialog = useCallback(() => {
    setOpenEvent(null);
  }, [setOpenEvent]);

  const onEventCallback = useCallback(
    (data: UnitEventContent) => {
      setUnityEventList((old) => [data, ...old]);
      setOpenEvent(0);
      playSound({
        forceSoundEnabled: true,
      });
    },
    [setUnityEventList, setOpenEvent, playSound]
  );

  useEffect(() => {
    SocketIOService.socketOn("warning_event", onEventCallback);
    return () => {
      SocketIOService.socketOff("warning_event", onEventCallback);
    };
  }, []);

  useEffect(() => {
    if (!!fetchMenuState && !fetchMenuState.loading) {
      if (fetchMenuState.hasError) {
        console.log(fetchMenuState.error);
        notification.error({
          message: "Error",
          description: fetchMenuState.error?.message,
        });
      } else {
        setMenu(fetchMenuState.data!!);
      }
      onFetchMenuStateReceived();
    }
  }, [fetchMenuState]);

  useEffect(() => {
    if (!!fetchCurrentUserState && !fetchCurrentUserState.loading) {
      if (fetchCurrentUserState.hasError) {
        notification.error({
          message: "Error",
          description: fetchCurrentUserState.error?.message,
        });
      } else {
        setCustomerUser(fetchCurrentUserState.data!!);
      }
      onFetchCurrentUserStateReceived();
    }
  }, [fetchCurrentUserState]);

  const switchUserModal = useCallback(() => {
    setUserModalVisible((old) => !old);
  }, [setUserModalVisible]);

  const onActionClick = useCallback(
    (key: string) => {
      switch (key) {
        case "menu":
          setCollapsed((old) => !old);
          break;
        case "user":
          switchUserModal();
          break;
      }
    },
    [setCollapsed, switchUserModal]
  );

  return (
    <BaseLayout onActionClick={onActionClick} collapsed={collapsed}>
      {userModalVisible ? (
        <DashboardLayoutUserModal onClose={switchUserModal} />
      ) : null}

      <SessionExpiredModal visible={sessionExpired} />
      <AppLoader
        loading={
          (!!fetchCustomerState && fetchCurrentUserState?.loading) ||
          (!!fetchCurrentUserState && fetchCurrentUserState.loading) ||
          (!!fetchMenuState && fetchMenuState.loading)
        }
      />
      <Outlet />
      <Modal
        open={openEvent !== null}
        title={
          <Alert
            type="error"
            icon={<FontAwesomeIcon icon={faWarning} />}
            description={"Nuevo Evento"}
            showIcon
          />
        }
        onOk={onCloseEventDialog}
        onCancel={onCloseEventDialog}
        cancelButtonProps={{ className: "hidden" }}
        okText={"Cerrar"}
      >
        <List
          renderItem={EventItem}
          pagination={{
            pageSize: 1,
            current: openEvent ? openEvent + 1 : 1,
            onChange: onPaginationChange,
          }}
          dataSource={unityEventList}
        />
      </Modal>
    </BaseLayout>
  );
};

const EventItem: FC<UnitEventContent> = (item) => {
  return (
    <Descriptions layout="vertical" column={1}>
      <Descriptions.Item label="Fecha">{item.date}</Descriptions.Item>
      <Descriptions.Item label="Titulo">{item.title}</Descriptions.Item>
      <Descriptions.Item label="Descripcion">
        {item.description}
      </Descriptions.Item>
      <Descriptions.Item label="Unidad">{item.unity}</Descriptions.Item>
    </Descriptions>
  );
};
