import {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toNumber } from "lodash";
import { useAppQuery } from "../../../app/hook/Query";
import { Tabs } from "antd";

export type TabContainerItem = {
  label: ReactNode;
  key: string;
  icon?: ReactNode;
};

type Props = {
  items: Array<TabContainerItem>;
  defaultSelectedKey: string;
  onSelected: (key: string) => ReactNode;
};

export const TabContainer: FC<Props> = ({
  items,
  defaultSelectedKey,
  onSelected,
}) => {
  const query = useAppQuery().get("tab");
  const [selected, setSelected] = useState<string>(query || defaultSelectedKey);
  const navigate = useNavigate();
  const Element = useMemo(() => {
    if (!!query) {
      return onSelected(query);
    } else {
      return onSelected(defaultSelectedKey);
    }
  }, [query]);

  const onInternalSelected: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const index = toNumber(e.currentTarget.dataset["index"]);
      const item = items[index];
      navigate(`?tab=${item.key}`);
      setSelected(item.key);
    },
    [navigate]
  );

  return (
    <div
      className={"w-full h-fit overflow-x-hidden overflow-y-auto flex flex-col"}
    >
      <div className="flex flex-row md:flex-col justify-start shadow-md md:pr-4 md:pt-4 relative">
        {items.map((it, index) => (
          <button
            key={`t-${it.key}`}
            className={`items-center justify-start flex flex-row gap-3 px-3 py-2.5  border-none transition-all font-semibold cursor-pointer font-quicksand ${
              selected === it.key
                ? "bg-secondary text-secondary-text rounded-lg"
                : "bg-transparent text-neutral-400 dark:text-neutral-100 rounded-none"
            }`}
            data-index={index}
            onClick={onInternalSelected}
          >
            {it.icon}
            {it.label}
          </button>
        ))}
      </div>

      <div className={"w-full p-1 overflow-x-hidden overflow-y-auto"}>
        {Element}
      </div>
    </div>
  );
};
