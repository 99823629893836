import { FC, Fragment, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { isInteger, isNil, isNumber } from "lodash";
import { EChartsOption, SeriesOption } from "echarts";
import { BarChartItemConfig } from "../../../domain/type/ChartConfig";
import { Opts } from "echarts-for-react/lib/types";
import { useAppTheme } from "../../../../app/hook/Theme";
import { HistoricalChartProps } from "./RenderHistorical";

export const BarHistoricalChart: FC<
  HistoricalChartProps<BarChartItemConfig, BarChartItemConfig>
> = ({ chart, data, width, height }) => {
  const { colorPalette } = useAppTheme();
  const opts: Opts = useMemo(() => {
    return {
      width,
      height,
    };
  }, [width, height, chart]);

  const baseLines = useMemo<Array<EChartsOption> | EChartsOption>(() => {
    const usage = chart.ChartDefinition.schema.settings.usage;
    const dates: { [k: string]: string } = {};
    const xData: string[] = [];
    let series: Array<SeriesOption> = [];
    const currentArray: any[] = [];

    if (!!usage) {
      usage.forEach((it) => {
        const { dependency_key, title, color, description } = it;

        data?.forEach((it) => {
          const current = it[dependency_key];
          currentArray.push(
            !isNil(current)
              ? {
                  value: current.y,
                  itemStyle: { color, borderRadius: [15, 15, 0, 0] },
                }
              : 0
          );
          if (!isNil(current.x)) dates[title] = current.x;
        });
        xData.push(title);
      });
    }

    series.push({
      type: "bar",
      data: currentArray,
    });

    return {
      xAxis: {
        type: "category",
        data: xData,
        boundaryGap: true,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: colorPalette.textOnCardBackground,
          },
        },
      },

      yAxis: {
        type: "value",

        axisLine: {
          lineStyle: {
            color: colorPalette.textOnCardBackground,
          },
        },
        axisLabel: {
          formatter(value: string | number) {
            return `${
              isNumber(value)
                ? isInteger(value)
                  ? value
                  : value.toFixed(2)
                : value
            }`;
          },
        },
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: colorPalette.cardSecondaryBackground,
        borderColor: colorPalette.borderSecondary,
        textStyle: {
          color: colorPalette.textOnSecondaryCardBackground,
        },
        appendToBody: true,
        formatter(params: any) {
          let html = `<div>
            <p class="font-secondary">${dates[params?.[0]?.axisValue ?? ""]}</p>
            `;
          params.forEach((it: any) => {
            html += `
              <div class="my-1">
                <p class="gap-1 items-center rounded-full px-1 py-0.5" style="background-color: ${
                  it.color
                }">
                  <span class="font-secondary mix-blend-difference" >${
                    params?.[0]?.axisValue ?? ""
                  }</span>             
                </p>  
                <p class="font-primary px-1">${it.value}</p>
              </div>`;
          });
          html += "</div>";
          return html;
        },
      },
      series,
    };
  }, [data]);

  return (
    <Fragment>
      <ReactECharts option={baseLines} opts={opts} />
    </Fragment>
  );
};
