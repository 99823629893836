import { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Row, notification } from "antd";
import {
  faList,
  faRefresh,
  faSquare,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Vehicle } from "../../vehicle/domain/type/Vehicle";
import { useVehicleViewModel } from "../../vehicle/presentation/ViewModel";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { VehicleListData } from "./component/Data";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { DashboardPaths } from "../../../core/layout/dashboard/domain/implementation/Paths";
import { DataPresentationSelector } from "../../../core/presentation/component/DataPresentationSelector";

export const VehicleListPage: FC = () => {
  const [selectedView, setSelectedView] = useState({
    table: true,
    grid: false,
  });
  const params = useParams<Record<string, any>>();
  const { fetchList, vehicleList, fetchListState, onFetchListStateReceived } =
    useVehicleViewModel();
  const fetchVehicleList = useCallback(() => {
    void fetchList(params.id);
  }, [params.id, fetchList]);

  const navigate = useNavigate();

  const onItemClicked = useCallback(
    (record: Vehicle) => {
      navigate(DashboardPaths.unit_p_id!!.replace(":id", `${record.id}`));
    },
    [navigate, vehicleList]
  );

  useEffect(() => {
    void fetchVehicleList();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        notification.error({
          message: "Vehículos",
          description: fetchListState?.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <CustomBreadCrumb
        items={[
          {
            active: true,
            title: "...",
          },
        ]}
      />
      <ContentHeader
        title={"Flota"}
        subtitle={`${vehicleList?.length} unidades`}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              ghost
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchVehicleList}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />

      <Row gutter={[16, 16]}>
        {/* <Col span={24}>
          <Row justify={"end"}>
            <Col>
              <DataPresentationSelector
                value={selectedView}
                onChange={setSelectedView}
              />
            </Col>
          </Row>
        </Col> */}
        <Col span={24}>
          <VehicleListData
            grid={selectedView.grid}
            table={selectedView.table}
            data={vehicleList}
            onItemClicked={onItemClicked}
          />
        </Col>
      </Row>
    </ResponsiveContainer>
  );
};
