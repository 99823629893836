import { FC, useCallback } from "react";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faAt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import { Button, Form } from "antd";
import { PasswordRecoveryRequestData } from "../../../auth/domain/type/Login";
import { FormItem } from "../../../../core/presentation/component/fields/FormItem";
import ExtendedInput from "../../../../core/presentation/component/fields/ExtendedInput";
import { SubmitButton } from "../../../../core/presentation/component/fields/SubmitButton";

type Props = {
  onSubmit: (
    values: PasswordRecoveryRequestData
  ) => Promise<boolean | undefined>;
  onBackPressed: () => void;
};

const kRECOVERY_SCHEMA = yup.object({
  email: yup
    .string()
    .required("Ingresa un email")
    .email("Ingresa un email válido"),
});

export const PasswordRecoveryPageForm: FC<Props> = ({
  onSubmit,
  onBackPressed,
}) => {
  const onSubmitCapture = useCallback(
    (
      values: PasswordRecoveryRequestData,
      helpers: FormikHelpers<PasswordRecoveryRequestData>
    ) => {
      onSubmit(values).then((it) => {
        if (it) helpers.resetForm();
        helpers.setSubmitting(false);
      });
    },
    [onSubmit]
  );

  return (
    <Formik<PasswordRecoveryRequestData>
      initialValues={{
        email: "",
      }}
      onSubmit={onSubmitCapture}
      validationSchema={kRECOVERY_SCHEMA}
    >
      <Form className={"flex flex-col w-full"}>
        <FormItem name={"email"}>
          <ExtendedInput
            required
            name="email"
            autoFocus
            prefix={<FontAwesomeIcon icon={faAt} />}
          />
        </FormItem>
        <Button.Group className="ml-auto">
          <Button
            type="default"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={onBackPressed}
          >
            Atrás
          </Button>
          <SubmitButton
            type="primary"
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
          >
            Enviar
          </SubmitButton>
        </Button.Group>
      </Form>
    </Formik>
  );
};
