import { RemoteSourceResponse } from "../../../../domain/type/Source";
import { BaseRemoteSource } from "../../../../domain/model/Remote";
import { CustomerApiService } from "../../../../../app/service/RemoteClient";
import { DashboardMenu } from "../../domain/type/Menu";

export class RemoteDashboardSource extends BaseRemoteSource {
  static async getMenu(): Promise<
    RemoteSourceResponse<DashboardMenu> | undefined
  > {
    try {
      const role = await CustomerApiService.getWithAuth("/dashboard/menu");
      this.checkResponseCredentials(role);
      return role.data as RemoteSourceResponse<DashboardMenu>;
    } catch (e) {
      this.parseError(e);
    }
  }
}
