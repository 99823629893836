import { Outlet, useNavigate } from "react-router-dom";
import esES from "antd/locale/es_ES";
import { useAppViewModel } from "../ViewModel";
import { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { MainPaths } from "../../../core/layout/main/domain/implementation/Paths";
import { DashboardPaths } from "../../../core/layout/dashboard/domain/implementation/Paths";
import { AppLoader } from "../../../core/presentation/component/AppLoader";

export function AppComponent() {
  const navigate = useNavigate();
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const { refreshSession, sessionState, onRefreshSessionStateReceived } =
    useAppViewModel();

  useEffect(() => {
    void refreshSession();
  }, []);

  useEffect(() => {
    if (!!sessionState && !sessionState.loading) {
      if (sessionState.hasError) {
        if (!window.location.href.includes(MainPaths.password_update)) {
          navigate(MainPaths.auth);
        }
      } else {
        if (!window.location.href.includes(DashboardPaths.dashboard)) {
          navigate(DashboardPaths.overview!!);
        }
      }
      setSessionLoaded(true);
      onRefreshSessionStateReceived();
    }
  }, [sessionState]);
  return (
    <ConfigProvider locale={esES}>
      <AppLoader loading={!!sessionState?.loading} />
      {sessionLoaded && <Outlet />}
    </ConfigProvider>
  );
}
