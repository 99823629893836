import { FC, PropsWithChildren, createContext, useState } from "react";
import { DashboardMenu } from "../../domain/type/Menu";

type DashboardMenuContextType = {
  setMenu: (menu: DashboardMenu) => void;
  menu: DashboardMenu;
};

export const DashboardMenuContext = createContext<DashboardMenuContextType>({
  setMenu: null!,
  menu: {},
});

export const DashboardMenuProvider: FC<PropsWithChildren> = ({ children }) => {
  const [menu, setMenu] = useState<DashboardMenu>({});

  return (
    <DashboardMenuContext.Provider
      value={{
        setMenu,
        menu,
      }}
    >
      {children}
    </DashboardMenuContext.Provider>
  );
};
