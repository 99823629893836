import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { UnitAsset } from "../../domain/type/Asset";
import { RemoteUnitAssetSource } from "../source/Asset";

export class UnitAssetRepository extends BaseRemoteSource {
  static async getGallery(
    vehicleId: number | undefined
  ): Promise<RemoteSourceResponse<UnitAsset[]>> {
    return RemoteUnitAssetSource.getGallery(vehicleId);
  }
}
