import { DatePicker } from "./DatePicker";
import { RangePicker } from "./RangePicker";

type ExtendedDatePickerType = typeof DatePicker & {
  RangePicker: typeof RangePicker;
};

export const ExtendedDatePicker: ExtendedDatePickerType =
  DatePicker as ExtendedDatePickerType;

ExtendedDatePicker.RangePicker = RangePicker;
