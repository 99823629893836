import { FC, useCallback } from "react";
import { faCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Typography, Button, Alert, Form, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import { PostLoginData } from "../../domain/type/Login";
import { InputCodeFormik } from "../../../../core/presentation/component/Code";
import { CountDownButton } from "../../../../core/presentation/component/CountDown";
import { DateUtil } from "../../../../core/util/Date";
import { FormItem } from "../../../../core/presentation/component/fields/FormItem";
import { SubmitButton } from "../../../../core/presentation/component/fields/SubmitButton";
import reactUseCookie from "react-use-cookie";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type Props = {
  onSubmit: (data: PostLoginData) => Promise<boolean | undefined>;
  onBack: () => void;
  onResend: () => void;
  codeDuration: number;
};

const kCODE_VALIDATOR = yup.object({
  code: yup
    .string()
    .required("Ingresa el código de verificación.")
    .min(6, "El código debe contener 6 dígitos."),
});

export const CodeVerificationForm: FC<Props> = ({
  onBack,
  onSubmit,
  codeDuration,
  onResend,
}) => {
  const [rememberSession, setRememberSession] = reactUseCookie(
    "REMEMBER_SESSION",
    "false"
  );
  const onSubmitCaptured = useCallback(
    async (values: PostLoginData, helpers: FormikHelpers<PostLoginData>) => {
      onSubmit(values).then((it) => {
        if (it) helpers.resetForm();
        helpers.setSubmitting(false);
      });
    },
    [onSubmit]
  );

  const onChangeRememberSession = (e: CheckboxChangeEvent) => {
    setRememberSession(e.target.checked ? "true" : "false");
  };

  return (
    <div className={"flex flex-col justify-start items-start w-full gap-4"}>
      <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
        Iniciar Sesión
      </span>
      <Typography.Text>
        Se ha enviado un código de verificación a su correo electrónico. Ingrese
        el código recibido a continuación y presione sobre verificar.
      </Typography.Text>
      <Formik<PostLoginData>
        initialValues={{
          code: "",
        }}
        validationSchema={kCODE_VALIDATOR}
        onSubmit={onSubmitCaptured}
      >
        <Form className="w-full flex flex-col gap-4">
          <FormItem name={"code"}>
            <InputCodeFormik name="code" />
          </FormItem>
          <FormItem name={"rememberSession"} hidden>
            <Checkbox
              checked={rememberSession === "true"}
              onChange={onChangeRememberSession}
            >
              Mantener sesión iniciada
            </Checkbox>{" "}
          </FormItem>
          <Button.Group className="ml-auto">
            <Button
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={onBack}
              type="default"
            >
              Atrás
            </Button>
            <SubmitButton
              icon={<FontAwesomeIcon icon={faCheck} />}
              type={"primary"}
            >
              Verificar
            </SubmitButton>
          </Button.Group>
          <Alert
            type="warning"
            message={"Advertencia"}
            showIcon
            description={`El código enviado vence ${DateUtil.distanceFromNow(
              Date.now() + codeDuration
            )}`}
          />
          <CountDownButton
            buttonProps={{
              icon: <FontAwesomeIcon icon={faPaperPlane} />,
              type: "primary",
              ghost: true,
            }}
            onClick={onResend}
            disabledAtRender
            maxSeconds={60}
          >
            Reenviar Código
          </CountDownButton>
        </Form>
      </Formik>
    </div>
  );
};
