import { Button, ConfigProvider, Layout, Switch } from "antd";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

import { useAppTheme } from "../../../../app/hook/Theme";
import { MainLayoutBackgroundCarousel } from "./component/Carousel";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";

const { Content, Footer, Header } = Layout;
export const MainLayout: FC = () => {
  const { isMobileScreen } = useAppResponsiveScreen();
  const { isDarkThemeEnabled, switchDarkTheme, themes } = useAppTheme();

  return (
    <ConfigProvider theme={themes.primaryTheme}>
      <Layout
        className={"w-screen h-screen overflow-hidden z-50 dark:bg-neutral-700"}
      >
        <Header
          className={
            "flex flex-row pl-4 pr-3 absolute bg-transparent top-0 w-full z-50"
          }
        >
          <div className={"w-full h-full flex flex-row place-items-center"}>
            <Button.Group className={"place-items-center ml-auto"}>
              <Switch
                checked={isDarkThemeEnabled}
                checkedChildren={<FontAwesomeIcon icon={faSun} />}
                unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
                onChange={switchDarkTheme}
              />
            </Button.Group>
          </div>
        </Header>
        <Content className={"w-full h-full flex flex-col overflow-hidden"}>
          <Layout className="overflow-hidden w-full h-full relative">
            <div className="absolute w-full h-full top-0 right-0">
              {!isMobileScreen && <MainLayoutBackgroundCarousel />}
            </div>
            <div className="overflow-hidden md:grid md:grid-cols-2 z-30 w-full h-full">
              <div className="hidden md:block overflow-hidden w-full h-full"></div>
              <div className="h-full flex flex-col relative items-center justify-center">
                <div className="hidden md:block md:absolute w-full h-full right-0 top-0 bg-transparent  backdrop-blur-md z-40"></div>
                <div className="w-full flex-1 flex items-center justify-center z-50">
                  <Outlet />
                </div>
              </div>
            </div>
          </Layout>

          <Footer
            className={
              "flex place-content-center w-full py-2 md:py-1 m-0 z-50 md:bg-primary"
            }
          >
            <p
              style={{ padding: 0, margin: 0 }}
              className="md:text-primary-text text-center text-xs flex flex-col md:flex-row md:gap-2"
            >
              <span>2024 ACME & CIA</span>
              <span>Todos los derechos reservados.</span>
            </p>
          </Footer>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};
