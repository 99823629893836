import { Input as AntdInput, InputProps } from "antd";
import { useFormikContext } from "formik";
import { ChangeEventHandler, FC, useCallback } from "react";

type Props = Omit<InputProps, "name" | "status" | "value" | "onChange"> & {
  name: string;
};

export const Input: FC<Props> = ({ name, ...props }) => {
  const {
    errors,
    initialTouched,
    initialErrors,
    isValidating,
    isInitialValid,
    isSubmitting,
    isValid,
    touched,
    initialValues,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const fieldTouched = (initialTouched as any)[name] || (touched as any)[name];
  const fieldError = (initialErrors as any)[name] || (errors as any)[name];
  const readyToShowStatus = !isValidating || !isSubmitting;
  const fieldValid = isInitialValid || isValid;

  const status: InputProps["status"] = readyToShowStatus
    ? fieldTouched
      ? fieldError
        ? "error"
        : fieldValid
        ? ""
        : "warning"
      : ""
    : "";

  const defValue = (initialValues as any)[name] || (values as any)[name] || "";
  const onChangeCaptured = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (value) => {
      setFieldTouched(name, true);
      setFieldValue(name, value.target.value);
    },
    [setFieldValue, name, setFieldTouched]
  );

  return (
    <AntdInput
      name={name}
      status={status}
      value={defValue}
      onChange={onChangeCaptured}
      {...props}
    />
  );
};
