import { FC, Fragment } from "react";
import { VehicleListTable } from "./Table";
import { VehicleListGrid } from "./Grid";
import { Vehicle } from "../../../../features/vehicle/domain/type/Vehicle";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";
import { AppEmptyContentStateComponent } from "../../../../core/presentation/component/State";

export type VehicleListDataProps = {
  data?: Vehicle[] | null;
  onItemClicked: (item: Vehicle) => void;
  table?: boolean;
  grid?: boolean;
};
export const VehicleListData: FC<VehicleListDataProps> = ({
  data,
  onItemClicked,
  grid,
  table,
}) => {
  const { isMobileScreen } = useAppResponsiveScreen();

  return data && data.length > 0 ? (
    <Fragment>
      {grid && <VehicleListGrid data={data} onItemClicked={onItemClicked} />}
      {table && <VehicleListTable data={data} onItemClicked={onItemClicked} />}
    </Fragment>
  ) : (
    <AppEmptyContentStateComponent
      title={"Sin vehículos"}
      description={"No hay vehículos registrados..."}
    />
  );
};
