import { FC } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { VehicleListDataProps } from "./Data";
import { Vehicle } from "../../../../features/vehicle/domain/type/Vehicle";
import { DateUtil } from "../../../../core/util/Date";

type Props = {
  data: Vehicle[] | null;
  onItemClicked: (item: Vehicle) => void;
};

export const VehicleListTable: FC<Props> = ({ data, onItemClicked }) => {
  const columns: ColumnsType<Vehicle> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Registrado el",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => {
        return DateUtil.fastFormatDate(value, "DDD HH:mm");
      },
    },
  ];
  return (
    <Table
      onRow={(record, _) => {
        return {
          onClick: () => onItemClicked(record),
        };
      }}
      size={"small"}
      columns={columns}
      dataSource={data!!}
      rowKey={"id"}
    />
  );
};
