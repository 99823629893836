import { Password } from "./Password";
import { Input } from "./Input";

type ExtendedInputType = typeof Input & {
  Password: typeof Password;
};

const ExtendedInput: ExtendedInputType = Input as ExtendedInputType;

ExtendedInput.Password = Password;

export default ExtendedInput;
