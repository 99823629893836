import { FC, Fragment, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { cloneDeep, isInteger, isNil, isNumber } from "lodash";
import { EChartsOption, SeriesOption } from "echarts";
import { ChartProps } from "./Render";
import {
  CHART_MIN_HEIGHT_BY_TYPE,
  CHART_MIN_WIDTH_BY_TYPE,
} from "../../../../features/vehicle/domain/usecase/ChartLayout";
import { LinearChartItemConfig } from "../../../domain/type/ChartConfig";
import { DateUtil } from "../../../util/Date";
import { Opts } from "echarts-for-react/lib/types";
import { useAppTheme } from "../../../../app/hook/Theme";

export const LinearChart: FC<
  ChartProps<LinearChartItemConfig, LinearChartItemConfig>
> = ({ chart, data, width, height }) => {
  const { colorPalette } = useAppTheme();

  const opts: Opts = useMemo(() => {
    const splitCharts =
      chart.ChartDefinition.ChartSchema.individualGraphs === true;
    const cols = splitCharts
      ? 1
      : Math.floor(width / CHART_MIN_WIDTH_BY_TYPE.dashboard_linear);
    const rows = splitCharts
      ? 1
      : Math.floor(height / CHART_MIN_HEIGHT_BY_TYPE.dashboard_linear);
    const widthPerItem = width / Math.max(cols, 1);
    const heightPerItem = height / Math.max(rows, 1);
    return {
      width: widthPerItem,
      height: heightPerItem,
    };
  }, [width, height, chart]);

  const baseLines = useMemo<Array<EChartsOption> | EChartsOption>(() => {
    const splitCharts = chart.ChartDefinition.ChartSchema.individualGraphs;
    const usage = chart.ChartDefinition.schema.settings.usage;
    if (splitCharts) {
      if (!usage) return [];
      const multiChartOption: Array<EChartsOption> = usage.map((it) => {
        const { dependency_key, title, color, description } = it;
        const series: Array<SeriesOption> = [];
        series.push({
          type: "line",
          name: dependency_key,
          showSymbol: true,
          symbolSize: 20,
          data: [],
          lineStyle: {
            color,
            type: "solid",
          },
          smooth: true,
          id: dependency_key,
        });

        return {
          xAxis: {
            type: "category",
            data: [],
            boundaryGap: true,
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 10,
            },
            {
              start: 0,
              end: 10,
            },
          ],
          yAxis: {
            type: "value",
            boundaryGap: [0, "100%"],
            scale: true,
          },
          animationDuration: 150,
          series,
        };
      });
      return multiChartOption;
    } else {
      const titles: { [k: string]: string } = {};
      let series: Array<SeriesOption> = [];
      if (!!usage) {
        usage.forEach((it) => {
          const { dependency_key, title, color, description } = it;
          titles[dependency_key] = title || dependency_key;
          series.push({
            type: "line",
            name: dependency_key,
            data: [],
            lineStyle: {
              color,
            },
            color,
            smooth: true,
            showSymbol: false,
            id: dependency_key,
          });
        });
      }

      return {
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: true,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: colorPalette.textOnCardBackground,
            },
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          scale: true,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: colorPalette.textOnCardBackground,
            },
          },
          axisLabel: {
            formatter(value: string | number) {
              return `${
                isNumber(value)
                  ? isInteger(value)
                    ? value
                    : value.toFixed(2)
                  : value
              }`;
            },
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: colorPalette.cardSecondaryBackground,
          borderColor: colorPalette.borderSecondary,
          textStyle: {
            color: colorPalette.textOnSecondaryCardBackground,
          },
          appendToBody: true,
          formatter(params: any) {
            let html = `<div>
            <p class="font-secondary">${params?.[0]?.axisValue ?? ""}</p>
            `;
            params.forEach((it: any) => {
              html += `
              <div class="my-1">
                <p class="gap-1 items-center rounded-full px-1 py-0.5" style="background-color: ${
                  it.color
                }">
                  <span class="font-secondary mix-blend-difference" >${
                    titles[it.seriesName]
                  }</span>             
                </p>  
                <p class="font-primary px-1">${it.value}</p>
              </div>`;
            });
            html += "</div>";
            return html;
          },
        },
        series,
      };
    }
  }, []);

  const [lines, setLines] = useState<Array<EChartsOption> | EChartsOption>(
    baseLines
  );

  useEffect(() => {
    if (!isNil(data) && !isNil(data.timestamp)) {
      const copy = cloneDeep(lines);
      const newDate = DateUtil.fastFormatDate(data.timestamp, "HH:mm:ss");
      if (Array.isArray(copy)) {
        (copy as Array<EChartsOption>).forEach((it, idx) => {
          const series = (it.series as Array<SeriesOption>)[0];
          const dependencyKey = series.id!;
          const currentArray = series.data as Array<number>;
          const dateArray: Array<string> = (copy[idx].xAxis! as any).data;
          if (currentArray.length >= 50) {
            currentArray.shift();
            dateArray.shift();
          }
          currentArray.push(data[dependencyKey] || null);
          dateArray.push(newDate);
        });
      } else {
        const dateArray: Array<string> = ((copy as EChartsOption).xAxis! as any)
          .data!;
        if (dateArray.length >= 50) {
          dateArray.shift();
        }
        dateArray.push(newDate);
        const series = (copy as EChartsOption).series as Array<SeriesOption>;
        for (let i = -1; ++i < series.length; ) {
          const serie = series[i];
          const dependencyKey = serie.id!;
          const currentArray = serie.data as Array<number>;
          if (currentArray.length >= 50) {
            currentArray.shift();
          }
          currentArray.push(data[dependencyKey] || null);
        }
      }
      setLines(copy);
    }
  }, [data, setLines]);

  return (
    <Fragment>
      {Array.isArray(lines) ? (
        lines.map((it, idx) => (
          <ReactECharts lazyUpdate key={`lc-${idx}`} option={it} opts={opts} />
        ))
      ) : (
        <ReactECharts lazyUpdate option={lines} opts={opts} />
      )}
    </Fragment>
  );
};
