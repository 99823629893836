import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { notification, Typography, Avatar, Spin, Empty } from "antd";
import { MapContainer, TileLayer } from "react-leaflet";
import { Map, Marker, divIcon } from "leaflet";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { Gateway } from "../../../../../gateway/domain/type/Gateway";
import { useGatewayViewModel } from "../../../../../gateway_list/presentation/ViewModel";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { useLocationViewModel } from "../../../../../../core/presentation/viewmodel/Location";
import { GatewayLocation } from "../../../../../../core/domain/type/DeviceData";
import { SocketIOService } from "../../../../../../app/service/SocketIO";
import stringToColor from "string-to-color";
import { isNil, remove } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faLocationDot,
  faSatelliteDish,
  faSignal,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FullscreenToggle } from "../../../../../../core/presentation/component/Fullscreen";
import { renderToString } from "react-dom/server";
import { ReactComponent } from "../../../../../../assets/image/marker.svg";
import { DateUtil } from "../../../../../../core/util/Date";
import { AddressView } from "../../../../../../core/presentation/component/AddressView";

import { UnitsMap } from "../../../../../../core/presentation/component/units_map/UnitsMap";

type Props = {
  vehicle: Vehicle;
};

export const VehicleTrackingTab: FC<Props> = ({ vehicle }) => {
  const [selectedGateway, setSelectedGateway] = useState<
    Gateway | undefined | null
  >(null);
  const {
    fetchVehicleList,
    vehicleGateway,
    fetchListState,
    onFetchListStateReceived,
  } = useGatewayViewModel();

  useEffect(() => {
    void fetchVehicleList(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los gateways en el vehículo.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!vehicleGateway && vehicleGateway.length > 0 && !selectedGateway)
      setSelectedGateway(vehicleGateway[0]);
  }, [vehicleGateway]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-y-auto"}>
      {!isNil(vehicleGateway) && vehicleGateway.length > 0 ? (
        <div className="w-full h-screen overflow-hidden">
          <UnitsMap
            gatewayList={vehicleGateway}
            selectedGateway={selectedGateway}
            onGatewaySelected={setSelectedGateway}
          />
        </div>
      ) : (
        <Empty />
      )}
    </ResponsiveContainer>
  );
};

export const GatewayMap: FC<{
  gateway?: Gateway;
}> = ({ gateway }) => {
  const addressLock = useRef<number>(Date.now());
  const lastUpdate = useRef<number>(Date.now());
  const onlineTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const [online, setOnline] = useState<boolean>(false);
  const {
    fetchAddress,
    fetchAddressState,
    onFetchAddressStateReceived,
    address,
  } = useLocationViewModel();

  const startOfflineTimeout = useCallback(() => {
    const currentTimeout = onlineTimeout.current;
    if (!!currentTimeout) clearTimeout(currentTimeout);
    onlineTimeout.current = setTimeout(() => {
      setOnline(false);
    }, 5000);
  }, [onlineTimeout, setOnline]);

  const fetchAddressDinamyc = useCallback(
    (location: GatewayLocation) => {
      if (Date.now() - addressLock.current > 5000) {
        addressLock.current = Date.now();
        fetchAddress(location);
      }
    },
    [addressLock, fetchAddress]
  );

  const map = useRef<Map>(null);
  const markerRef = useRef<Marker | null>(null);
  const [locations, setLocations] = useState<
    | Array<{
        gatewayKey: string;
        location: GatewayLocation;
      }>
    | undefined
  >();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const listener = useMemo(() => {
    const currentGateway = gateway;
    if (!!currentGateway) {
      return {
        event: `data/${currentGateway?.key}/gps_live`,
        callback: (data: GatewayLocation) => {
          setLocations((old) => {
            if (!old) return [];
            else {
              const copy = [...old];
              remove(old, (it2) => it2.gatewayKey === currentGateway.key);
              copy.push({ gatewayKey: currentGateway.key, location: data });
              return copy;
            }
          });
          lastUpdate.current = Date.now();
          setOnline(true);
          startOfflineTimeout();
        },
      };
    }
  }, [gateway, setLocations, lastUpdate, setOnline, startOfflineTimeout]);

  const centerInLocation = useCallback(() => {
    const currentMarkerPosition = markerRef.current?.getLatLng();
    if (!!currentMarkerPosition)
      map.current?.setView(currentMarkerPosition, 15);
  }, [map, markerRef]);
  useEffect(() => {
    const currentListener = listener;
    if (!!currentListener)
      SocketIOService.socketOn(currentListener.event, currentListener.callback);
    return () => {
      if (!!currentListener)
        SocketIOService.socketOff(
          currentListener.event,
          currentListener.callback
        );
    };
  }, [listener]);

  useEffect(() => {
    if (!!fetchAddressState && !fetchAddressState.loading) {
      if (fetchAddressState.hasError) {
        notification.error({
          message: "Error al obtener la dirección.",
          description: fetchAddressState.error?.message,
        });
      }
      onFetchAddressStateReceived();
    }
  }, [fetchAddressState]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const loc = locations[0].location;
      fetchAddressDinamyc(loc);
      const currentMarker = markerRef.current;
      if (!!currentMarker) map.current?.removeLayer(currentMarker);
      const newMarker = new Marker([loc.latitude, loc.longitude], {
        icon: divIcon({
          iconSize: [32, 32],
          iconAnchor: [16, 32],
          html: renderToString(
            <div className="w-full h-full relative">
              <ReactComponent
                style={{
                  fill: stringToColor(gateway!!.key),
                }}
                className="w-full h-full"
              />
              <div className="inset-0 mx-auto mt-1 absolute h-1/2 bg-transparent rounded-full aspect-square fill-white text-white">
                <FontAwesomeIcon icon={faTruck} />
              </div>
            </div>
          ),
          className: "bg-transparent",
        }),
      });
      markerRef.current = newMarker;
      map?.current?.addLayer(newMarker);
    }
  }, [locations]);

  useEffect(() => {
    const currentMarker = markerRef.current;
    if (!!currentMarker) map.current?.removeLayer(currentMarker);
    setLocations(undefined);
    setOnline(false);
  }, [gateway]);

  useEffect(() => {
    return () => {
      const currentTimeout = onlineTimeout.current;
      if (!!currentTimeout) clearTimeout(currentTimeout);
    };
  }, []);

  return (
    <div className={"w-full h-fit relative"} ref={mapContainerRef}>
      <div
        className={
          "absolute right-0 top-0 p-4 z-20 flex flex-col gap-2 justify-end content-end items-end"
        }
      >
        <FullscreenToggle containerRef={mapContainerRef} />
        {locations && (
          <Avatar
            style={{
              backgroundColor: stringToColor(gateway!!.key),
              color: "#fff",
            }}
            onClick={centerInLocation}
          >
            <FontAwesomeIcon icon={faLocation} />
          </Avatar>
        )}
        <details className="grid-rows-2 transition-all duration-100" open>
          <div className="transition-all duration-100 ml-2 relative inline px-3 py-2 rounded-lg bg-white shadow">
            <div className="absolute my-auto left-0 top-0 bottom-0 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-white" />
            {online ? (
              <Typography.Text type="secondary" className="p-0 m-0">
                En línea{" "}
                {lastUpdate.current &&
                  DateUtil.fastFormatDate(lastUpdate.current, "HH:mm:ss")}
              </Typography.Text>
            ) : (
              <Typography.Text type="danger" className="p-0 m-0">
                No está en línea
              </Typography.Text>
            )}
          </div>
          <summary className="transition-all duration-100 inline">
            <Avatar className={online ? "bg-green-500" : "bg-red-500"}>
              <FontAwesomeIcon icon={faSignal} />
            </Avatar>
          </summary>
        </details>
        {gateway && (
          <details className="grid-rows-2 transition-all duration-100" open>
            <div className="transition-all duration-100 ml-2 relative inline px-3 py-2 rounded-lg bg-white shadow">
              <div className="absolute my-auto left-0 top-0 bottom-0 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-white" />
              <Typography.Text type="secondary" className="p-0 m-0">
                Vía {gateway.key}
              </Typography.Text>
            </div>
            <summary className="transition-all duration-100 inline">
              <Avatar
                style={{
                  backgroundColor: stringToColor(gateway.key),
                  color: "#fff",
                }}
              >
                <FontAwesomeIcon icon={faSatelliteDish} />
              </Avatar>
            </summary>
          </details>
        )}

        {locations && (
          <details className="grid-rows-2 transition-all duration-100" open>
            <div className="transition-all duration-100 ml-2 relative px-4 py-2 rounded-lg bg-white shadow inline-flex max-w-xs">
              <div className="flex flex-col">
                {fetchAddressState?.hasError && (
                  <Typography.Text type="danger">
                    No es posible obtener la dirección de la ubicación
                  </Typography.Text>
                )}
                {(!address || fetchAddressState?.loading) && <Spin />}
                {address && <AddressView address={address} />}
              </div>
            </div>
            <summary className="transition-all duration-100 inline">
              <Avatar
                style={{
                  backgroundColor: stringToColor(gateway!!.key),
                  color: "#fff",
                }}
              >
                <FontAwesomeIcon icon={faLocationDot} />
              </Avatar>
            </summary>
          </details>
        )}
      </div>
      <MapContainer
        className={"w-full min-h-screen z-10"}
        zoom={7}
        scrollWheelZoom={true}
        ref={map}
        center={[-12.04318, -77.02824]}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};
