import { LocalTokenUseCase } from "../../../../app/domain/usecase/Token";
import {
  LoginData,
  PasswordRecoveryProceedData,
  PasswordRecoveryRequestData,
  PasswordRecoveryValidationData,
  PostLoginData,
} from "../../domain/type/Login";
import { RemoteAuthSource } from "../source/Remote";

export class AuthRepository {
  static async login(data: LoginData) {
    return await RemoteAuthSource.login(data);
  }

  static async postLogin(data: PostLoginData) {
    await RemoteAuthSource.postLogin(data);
  }

  static async sendPasswordRecoveryRequest(data: PasswordRecoveryRequestData) {
    return await RemoteAuthSource.sendPasswordRecoveryRequest(data);
  }

  static async validatePasswordRecoveryToken(
    data: PasswordRecoveryValidationData
  ) {
    return await RemoteAuthSource.validatePasswordRecoveryToken(data);
  }

  static async proceedPasswordRecovery(data: PasswordRecoveryProceedData) {
    return await RemoteAuthSource.proceedPasswordRecovery(data);
  }

  static async getSession() {
    return await RemoteAuthSource.getSession();
  }

  static async refreshSession() {
    return await RemoteAuthSource.refreshToken();
  }

  static async logout() {
    await RemoteAuthSource.logout();
    LocalTokenUseCase.clearTokens();
    window.location.reload();
  }
}
