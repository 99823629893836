import { useState } from "react";
import { AppTaskState } from "../domain/type/State";
import { AuthRepository } from "../../features/auth/data/repository/Auth";
import { AppTaskStateG } from "../domain/model/State";

export function useAppViewModel() {
  const [sessionState, setSessionState] =
    useState<AppTaskState<boolean> | null>();

  async function refreshSession() {
    try {
      if (sessionState?.loading) return;
      setSessionState(AppTaskStateG.loading());
      await AuthRepository.refreshSession();
      setSessionState(AppTaskStateG.success(true));
    } catch (e: any) {
      setSessionState(AppTaskStateG.error(e));
    }
  }

  function onRefreshSessionStateReceived() {
    setSessionState(null);
  }

  return {
    sessionState,
    refreshSession,
    onRefreshSessionStateReceived,
  };
}
