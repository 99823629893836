import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Card, notification, Typography, Form, RadioChangeEvent } from "antd";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { useDeviceDataViewModel } from "../../../../../../core/presentation/viewmodel/DeviceData";
import { Gateway } from "../../../../../gateway/domain/type/Gateway";
import { useUnitChartViewModel } from "../device_history/UnitChartViewModel";
import { useGatewayViewModel } from "../../../../../gateway_list/presentation/ViewModel";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { AppEmptyContentStateComponent } from "../../../../../../core/presentation/component/State";
import { FormItem } from "../../../../../../core/presentation/component/fields/FormItem";
import { ExtendedDatePicker } from "../../../../../../core/presentation/component/fields/ExtendedDatePicker";
import { SubmitButton } from "../../../../../../core/presentation/component/fields/SubmitButton";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { RadioGroup } from "../../../../../../core/presentation/component/fields/RadioGroup";
import { ExtendedSelect } from "../../../../../../core/presentation/component/fields/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { find, isNumber, toNumber } from "lodash";
import { DateUtil } from "../../../../../../core/util/Date";
import { DefaultOptionType } from "antd/es/select";
import classNames from "classnames";
import { CheckboxGroup } from "../../../../../../core/presentation/component/fields/CheckboxGroup";

type Props = {
  vehicle: Vehicle;
};

type FetchData = {
  chartIds?: number[];
  dateRange?: [Date, Date];
  gatewayId?: number;
  rate?: "default" | number;
  fileType?: "csv" | "xlsx";
};

const REPORT_FORM_VALIDATION = Yup.object({
  chartIds: Yup.array(Yup.number())
    .min(1, "Selecciona al menos 1 variable.")
    .required("Debes seleccionar al menos 1 variable."),
  gatewayId: Yup.number().required("Selecciona un gateway."),
  dateRange: Yup.array(Yup.date())
    .length(2)
    .required("Selecciona un rango válido."),
  rate: Yup.string().required("Selecciona una tasa de actualización."),
  fileType: Yup.string()
    .oneOf(["csv", "xlsx"])
    .required("Selecciona un tipo de archivo."),
});

const DEFAULT_RATE_OPTTIONS: DefaultOptionType[] = [
  {
    label: "Diario",
    value: 24 * 60 * 60 * 1000,
  },
  {
    label: "Semanal",
    value: 7 * 24 * 60 * 60 * 1000,
  },
  {
    label: "Mensual",
    value: 30 * 24 * 60 * 60 * 1000,
  },
];

export const VehicleLogsTab: FC<Props> = ({ vehicle }) => {
  const { fetchState, downloadDeviceLogs, onFetchStateReceived } =
    useDeviceDataViewModel();
  const [selectedGw, setSelectedGw] = useState<Gateway | null>(null);
  const {
    onFetchListStateReceived,
    fetchChartList,
    fetchListState,
    unitChartList,
  } = useUnitChartViewModel();
  const {
    fetchVehicleList,
    vehicleGateway,
    fetchListState: fetchVehicleListState,
    onFetchListStateReceived: onFetchVehicleListStateReceived,
  } = useGatewayViewModel();

  useEffect(() => {
    void fetchChartList(vehicle.id, {
      report: true,
    });
    void fetchVehicleList(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchVehicleListState && !fetchVehicleListState.loading) {
      if (fetchVehicleListState.hasError) {
        notification.error({
          message: "Error al obtener el gateway.",
          description: fetchVehicleListState.error?.message,
        });
      } else {
        if (!!vehicleGateway && vehicleGateway.length > 0) {
          setSelectedGw(vehicleGateway[0]);
        }
      }
      onFetchVehicleListStateReceived();
    }
  }, [fetchVehicleListState]);

  useEffect(() => {
    if (!!fetchState && !fetchState.loading) {
      if (fetchState.hasError) {
        notification.error({
          message: "Error al obtener los datos.",
          description: fetchState.error?.message,
        });
        console.log(fetchState.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  const initialState = useMemo<FetchData>(() => {
    if (vehicleGateway?.length ?? -1 > 0) {
      setSelectedGw(vehicleGateway![0]);
      return { gatewayId: vehicleGateway![0].id, rate: "default" };
    }
    return {};
  }, [vehicleGateway, setSelectedGw]);

  const downloadLogs = useCallback(
    async (formData: FetchData, helpers: FormikHelpers<any>) => {
      const { chartIds, dateRange, rate, gatewayId } = formData;
      await downloadDeviceLogs(
        gatewayId!,
        chartIds!,
        dateRange![0],
        dateRange![1],
        rate!,
        formData.fileType!
      );
    },
    [downloadDeviceLogs]
  );

  const onGatewaySelected = useCallback(
    (e: RadioChangeEvent) => {
      if (isNumber(e.target.value)) {
        const first = find(vehicleGateway, { id: toNumber(e.target.value) });
        first && setSelectedGw(first);
      }
    },
    [setSelectedGw, vehicleGateway]
  );

  const rateOptions = useMemo<DefaultOptionType[]>(() => {
    if (!!selectedGw) {
      const options = new Array(3)
        .fill(0)
        .reduce((prev: Array<any>, _, index) => {
          const currentDuration = selectedGw.dataSaveRate * (index + 1);
          prev.push({
            value: currentDuration,
            label: DateUtil.toSecondsReadable(currentDuration),
          });
          return prev;
        }, []);
      return [
        {
          label: "Por defecto",
          value: "default",
        },
        ...options,
        ...DEFAULT_RATE_OPTTIONS,
      ];
    }

    return [];
  }, [selectedGw]);

  return (
    <ResponsiveContainer>
      <AppLoader
        loading={
          (!!fetchVehicleListState && fetchVehicleListState.loading) ||
          (!!fetchState && fetchState.loading)
        }
      />

      {!vehicleGateway || vehicleGateway.length === 0 ? (
        <AppEmptyContentStateComponent
          description={"El vehículo no está vinculado a ningún gateway."}
        />
      ) : (
        <div
          className={"w-full relative overflow-x-hidden flex flex-col gap-2"}
        >
          <Typography.Title level={4}>
            Descargar Reportes de flota
          </Typography.Title>
          <Card bordered>
            <Formik<FetchData>
              initialValues={initialState}
              onSubmit={downloadLogs}
              validationSchema={REPORT_FORM_VALIDATION}
            >
              <Form layout={"vertical"}>
                <FormItem
                  name={"gatewayId"}
                  label={"Gateway"}
                  className={classNames({
                    hidden: vehicleGateway.length <= 1,
                  })}
                >
                  <RadioGroup
                    name={"gatewayId"}
                    options={vehicleGateway.map((it) => ({
                      label: it.key,
                      value: it.id,
                    }))}
                    onChangeSecondary={onGatewaySelected}
                  />
                </FormItem>
                <FormItem name={"chartIds"} label={"Variables"}>
                  <CheckboxGroup<number>
                    name={"chartIds"}
                    options={unitChartList?.map((it) => ({
                      label: it.ChartDefinition.name,
                      value: it.id,
                    }))}
                  />
                </FormItem>
                <FormItem name={"dateRange"} label={"Periodo"}>
                  <ExtendedDatePicker.RangePicker
                    name={"dateRange"}
                    presets={[
                      {
                        label: "Hoy",
                        value: [dayjs().startOf("day"), dayjs().endOf("day")],
                      },
                      {
                        label: "Esta semana",
                        value: [dayjs().add(-7, "d"), dayjs()],
                      },
                      {
                        label: "Últimas 2 semanas",
                        value: [dayjs().add(-14, "d"), dayjs()],
                      },
                      {
                        label: "Este mes",
                        value: [dayjs().add(-30, "d"), dayjs()],
                      },
                      {
                        label: "Últimos 3 meses",
                        value: [dayjs().add(-90, "d"), dayjs()],
                      },
                    ]}
                  />
                </FormItem>
                <FormItem
                  name={"fileType"}
                  label={"Formato de archivo"}
                  className="w-fit"
                >
                  <RadioGroup
                    name={"fileType"}
                    className="w-fit"
                    options={[
                      {
                        label: "CSV",
                        value: "csv",
                      },
                      {
                        label: "Excel",
                        value: "xlsx",
                      },
                    ]}
                  />
                </FormItem>
                <FormItem
                  name={"rate"}
                  label={"Tasa de actualización"}
                  className="w-fit"
                >
                  <ExtendedSelect<number>
                    name={"rate"}
                    className="w-fit"
                    options={rateOptions}
                  />
                </FormItem>
                <SubmitButton icon={<FontAwesomeIcon icon={faDownload} />}>
                  Descargar
                </SubmitButton>
              </Form>
            </Formik>
          </Card>
        </div>
      )}
    </ResponsiveContainer>
  );
};
