import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import {
  GatewayLocation,
  HistoricalUnitDeviceData,
} from "../../domain/type/DeviceData";
import { DataApiService } from "../../../app/service/RemoteDataClient";

export class RemoteDeviceDataSource extends BaseRemoteSource {
  static async getDeviceData(
    gatewayId: number,
    chartIds: Array<number>,
    dateStart: Date,
    dateEnd: Date,
    rate?: number | "default"
  ): Promise<RemoteSourceResponse<HistoricalUnitDeviceData>> {
    try {
      const data = await DataApiService.getWithAuth(
        "/historical/devices",
        {},
        {
          params: {
            gatewayId,
            chartIds,
            dateStart,
            dateEnd,
            rate,
          },
        }
      );
      this.checkResponseCredentials(data);
      return data.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async downloadDeviceData(
    gatewayId: number,
    chartIds: Array<number>,
    dateStart: Date,
    dateEnd: Date,
    rate?: number | "default",
    fileType?: "csv" | "xlsx"
  ): Promise<void> {
    try {
      const data = await DataApiService.downloadWithAuth(`/reports/download`, {
        params: {
          gatewayId,
          chartIds,
          dateStart,
          dateEnd,
          rate,
          fileType,
        },
      });
      this.checkResponseCredentials(data);
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async getDeviceLocation(
    gatewayId: number,
    start: Date,
    end: Date,
    rate?: number
  ): Promise<RemoteSourceResponse<GatewayLocation[]>> {
    try {
      const gateway = await DataApiService.getWithAuth(
        "/historical/location",
        {},
        {
          params: {
            gatewayId,
            dateStart: start,
            dateEnd: end,
            rate,
          },
        }
      );
      this.checkResponseCredentials(gateway);
      return gateway.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
