import { ItemType } from "antd/lib/menu/hooks/useItems";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faGauge,
  faMapLocationDot,
  faIndustry,
  faChartLine,
  faMagnifyingGlassChart,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { DashboardMenu } from "../type/Menu";
import { DashboardPaths } from "../implementation/Paths";

export class DashboardMenuUseCase {
  static getMenuItems(paths: DashboardMenu): ItemType<MenuItemType>[] {
    const items: ItemType<MenuItemType>[] = [];

    // if (!!paths.overview) {
    //   items.push({
    //     key: DashboardPaths.overview,
    //     label: "Inicio",
    //     icon: <FontAwesomeIcon icon={faHomeAlt} />,
    //   });
    // }

    if (!!paths.overview) {
      items.push({
        key: DashboardPaths.overview,
        label: "Inicio",
        icon: <FontAwesomeIcon icon={faGauge} />,
      });
    }

    if (!!paths.tracking) {
      items.push({
        key: DashboardPaths.fleet_tracking,
        label: "Seguimiento",
        icon: <FontAwesomeIcon icon={faMapLocationDot} />,
      });
    }
    // if (!!paths.dashboard) {
    //   items.push({
    //     key: DashboardPaths.fleet_dashboard,
    //     label: "Gráficos en tiempo real",
    //     icon: <FontAwesomeIcon icon={faGauge} />,
    //   });
    // }
    if (!!paths.historical) {
      items.push({
        key: DashboardPaths.fleet_historical,
        label: "Gráficos Históricos",
        icon: <FontAwesomeIcon icon={faChartLine} />,
      });
    }
    if (!!paths.reports) {
      items.push({
        key: DashboardPaths.fleet_reports,
        label: "Reportes",
        icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
      });
    }
    if (!!paths.reports) {
      items.push({
        key: DashboardPaths.fleet_events,
        label: "Eventos",
        icon: <FontAwesomeIcon icon={faWarning} />,
      });
    }
    if (!!paths.fleet) {
      items.push({
        key: DashboardPaths.fleet_list,
        label: "Flota",
        icon: <FontAwesomeIcon icon={faIndustry} />,
      });
    }
    // if (!!paths.alerts) {
    //   items.push({
    //     key: "menu_alerts",
    //     label: "Alertas",
    //     icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
    //   });
    // }
    // if (!!paths.maintenance) {
    //   items.push({
    //     key: "maintenance",
    //     label: "Mantenimiento",
    //     icon: <FontAwesomeIcon icon={faWrench} />,
    //   });
    // }
    if (!!paths.customer) {
      items.push({
        key: DashboardPaths.customer,
        label: "Perfil de Cliente",
        icon: <FontAwesomeIcon icon={faAddressCard} />,
      });
    }
    return items;
  }
}
