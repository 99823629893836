import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import { CustomerApiService } from "../../../app/service/RemoteClient";
import { UnitEventCountItem, UnitEventItem } from "../../domain/type/UnitEvent";

export class RemoteUnityEventSource extends BaseRemoteSource {
  static async getUnityEventCount(
    vehicleId: number,
    dateStart: Date,
    dateEnd: Date
  ): Promise<RemoteSourceResponse<Array<UnitEventCountItem>>> {
    try {
      const response = await CustomerApiService.getWithAuth(
        `/units/${vehicleId}/events/count`,
        {},
        {
          params: {
            dateStart,
            dateEnd,
          },
        }
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async getUnityLastEvents(
    vehicleId: number,
    limit: number
  ): Promise<RemoteSourceResponse<Array<UnitEventItem>>> {
    try {
      const response = await CustomerApiService.getWithAuth(
        `/units/${vehicleId}/events/last/${limit}`,
        {},
        {}
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
