import { RemoteDeviceDataSource } from "../source/RemoteDeviceData";

export class DeviceDataRepository {
  static async getDeviceDataList(
    gatewayId: number,
    chartIds: Array<number>,
    dateStart: Date,
    dateEnd: Date,
    rate?: number | "default"
  ) {
    return await RemoteDeviceDataSource.getDeviceData(
      gatewayId,
      chartIds,
      dateStart,
      dateEnd,
      rate
    );
  }

  static async downloadDeviceData(
    gatewayId: number,
    chartIds: Array<number>,
    dateStart: Date,
    dateEnd: Date,
    rate?: number | "default",
    fileType?: "csv" | "xlsx"
  ) {
    await RemoteDeviceDataSource.downloadDeviceData(
      gatewayId,
      chartIds,
      dateStart,
      dateEnd,
      rate,
      fileType
    );
  }

  static async getDeviceLocation(
    gatewayId: number,
    start: Date,
    end: Date,
    rate?: number
  ) {
    return await RemoteDeviceDataSource.getDeviceLocation(
      gatewayId,
      start,
      end,
      rate
    );
  }
}
