import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { CustomerUser } from "../../../customer_user/domain/type/CustomerUser";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { AuthApiService } from "../../../../app/service/RemoteAuthClient";
import {
  LoginData,
  LoginResponseData,
  PasswordRecoveryProceedData,
  PasswordRecoveryRequestData,
  PasswordRecoveryValidationData,
  PostLoginData,
} from "../../domain/type/Login";

export class RemoteAuthSource extends BaseRemoteSource {
  static async login(
    data: LoginData
  ): Promise<RemoteSourceResponse<LoginResponseData> | undefined> {
    try {
      const loginResult = await AuthApiService.postWithAuth<LoginData>(
        "/customer/login",
        data
      );
      this.checkResponseCredentials(loginResult);
      return loginResult.data as RemoteSourceResponse<LoginResponseData>;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async logout(): Promise<RemoteSourceResponse | undefined> {
    try {
      const logoutResult =
        await AuthApiService.postWithAuth("/customer/logout");
      return logoutResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async postLogin(
    data: PostLoginData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const postLoginResult = await AuthApiService.post<PostLoginData>(
        "/customer/login/mfa",
        data
      );
      this.checkResponseCredentials(postLoginResult);
      return postLoginResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async sendPasswordRecoveryRequest(
    data: PasswordRecoveryRequestData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const passwordRequestResult =
        await AuthApiService.post<PasswordRecoveryRequestData>(
          "/customer/recovery",
          data
        );
      return passwordRequestResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async validatePasswordRecoveryToken(
    data: PasswordRecoveryValidationData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const validationResult =
        await AuthApiService.post<PasswordRecoveryValidationData>(
          "/customer/recovery/verify",
          data
        );
      return validationResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async proceedPasswordRecovery(
    data: PasswordRecoveryProceedData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const resetResult =
        await AuthApiService.post<PasswordRecoveryProceedData>(
          "/customer/recovery/proceed",
          data
        );
      return resetResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async getSession(): Promise<
    RemoteSourceResponse<CustomerUser> | undefined
  > {
    try {
      const sessionResult =
        await AuthApiService.getWithAuth("/customer/session");

      return sessionResult.data as RemoteSourceResponse<CustomerUser>;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async refreshToken(): Promise<RemoteSourceResponse | undefined> {
    try {
      const refreshResult =
        await AuthApiService.postWithAuth("/customer/refresh");
      this.checkResponseCredentials(refreshResult);
      return refreshResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }
}
