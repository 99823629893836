import { useState } from "react";
import {
  GatewayLocation,
  HistoricalUnitDeviceData,
} from "../../domain/type/DeviceData";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { DeviceDataRepository } from "../../data/repository/DeviceData";
import { AppTaskState } from "../../../app/domain/type/State";

export function useDeviceDataViewModel() {
  const [fetchState, setFetchState] = useState<AppTaskState<boolean> | null>(
    null
  );
  const [deviceData, setDeviceData] = useState<HistoricalUnitDeviceData | null>(
    null
  );
  const [gatewayLocation, setGatewayLocation] =
    useState<Array<GatewayLocation> | null>(null);

  async function fetchDeviceData(
    gatewayId: number,
    chartIds: number[],
    dateStart: Date,
    dateEnd: Date,
    rate: number | "default"
  ) {
    if (fetchState?.loading) return;
    setFetchState(AppTaskStateG.loading());
    try {
      const list = await DeviceDataRepository.getDeviceDataList(
        gatewayId,
        chartIds,
        dateStart,
        dateEnd,
        rate
      );
      if (list.ok) {
        setDeviceData(list.data!!);
        setFetchState(AppTaskStateG.success(true));
        return true;
      } else setFetchState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchState(AppTaskStateG.error(error));
    }
  }

  async function downloadDeviceLogs(
    gatewayId: number,
    chartIds: number[],
    dateStart: Date,
    dateEnd: Date,
    rate: number | "default",
    fileType: "csv" | "xlsx"
  ) {
    if (fetchState?.loading) return;
    setFetchState(AppTaskStateG.loading());
    try {
      await DeviceDataRepository.downloadDeviceData(
        gatewayId,
        chartIds,
        dateStart,
        dateEnd,
        rate,
        fileType
      );
      setFetchState(AppTaskStateG.success(true));
    } catch (error: any) {
      setFetchState(AppTaskStateG.error(error));
    }
  }

  async function fetchDeviceLocation(
    gatewayId: number,
    start: Date,
    end: Date,
    rate: number
  ) {
    if (fetchState?.loading) return;
    setFetchState(AppTaskStateG.loading());
    try {
      const list = await DeviceDataRepository.getDeviceLocation(
        gatewayId,
        start,
        end,
        rate
      );
      if (list.ok) {
        setGatewayLocation(list.data!!);
        setFetchState(AppTaskStateG.success(true));
        return true;
      } else setFetchState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchState(AppTaskStateG.error(error));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  return {
    fetchState,
    deviceData,
    fetchDeviceData,
    onFetchStateReceived,
    fetchDeviceLocation,
    gatewayLocation,
    downloadDeviceLogs,
  };
}
