import { DashboardPathsType } from "../type/Paths";

export const DashboardPaths: DashboardPathsType = {
  dashboard: "/dashboard",
  overview: "/dashboard/overview",
  fleet_list: "/dashboard/fleet",
  unit_p_id: "/dashboard/fleet/:id",
  gateway_list: "/dashboard/gateways",
  gateway_p_id: "/dashboard/gateways/:id",
  customer: "/dashboard/customer",
  user_list: "/dashboard/users",
  user_p_id: "/dashboard/users/:id",
  fleet_tracking: "/dashboard/tracking",
  // fleet_dashboard: "/dashboard/general",
  fleet_reports: "/dashboard/report",
  fleet_historical: "/dashboard/historical",
  fleet_events: "/dashboard/events",
};
