import { ThemeConfig, theme } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";
export class SecondaryThemeGenerator extends AppAntThemeGenerator {
  override build(): ThemeConfig {
    const {
      primary,
      textOnPrimary,
      textOnSecondary,
      background,
      borderSecondary,
      border,
      textLink,
      textLinkActive,
      textLinkHover,
      textLinkVisited,
      textTitle,
      textSubtitle,
      textBody,
      textLabel,
      buttonPrimary,
      buttonSecondary,
      textButtonPrimary,
      textButtonSecondary,
      sidebarBackground,
      footerBackground,
      textOnSidebarBackground,
      secondary,
      textOnSecondaryCardBackground,
      cardBackground,
      textOnCardBackground,
      textOnInputBackground,
      inputBackground,
      inputBorder,
      cardSecondaryBackground,
    } = this.palette;

    return {
      algorithm: theme.compactAlgorithm,
      token: {
        colorPrimary: primary,
        colorBorderSecondary: borderSecondary,
        colorBorder: border,
        colorBgBase: background,
        colorLink: textLink,
        colorLinkActive: textLinkActive,
        colorLinkHover: textLinkHover,
        linkFocusDecoration: textLinkVisited,
        linkDecoration: "underline",
        colorTextHeading: textTitle,
        colorText: textLabel,
        colorTextSecondary: textSubtitle,
        colorTextBase: textBody,
        colorPrimaryText: textOnPrimary,
      },
      components: {
        Button: {
          primaryColor: textButtonPrimary,
          colorPrimaryBg: buttonPrimary,
          colorPrimaryText: textButtonPrimary,
          colorPrimary: buttonPrimary,
          primaryShadow: "0 2px 0 rgba(0,0,0,0.045)",
          defaultBg: buttonSecondary,
          defaultColor: textButtonSecondary,
        },
        Layout: {
          headerBg: "transparent",
          triggerBg: "transparent",
          siderBg: sidebarBackground,
          lightTriggerBg: "transparent",
          triggerHeight: 65,
          footerBg: footerBackground,
        },
        Drawer: {
          colorIcon: textOnSidebarBackground,
          colorText: textOnSidebarBackground,
          colorIconHover: primary,
          colorSplit: borderSecondary,
          colorBgElevated: sidebarBackground,
        },
        Table: {
          colorBorder: borderSecondary,
          colorBorderBg: borderSecondary,
          colorBorderSecondary: borderSecondary,
          colorBgTextHover: textOnSecondaryCardBackground,
          colorText: textOnSecondaryCardBackground,
          headerBg: secondary,
          headerColor: textOnSecondary,
          fontWeightStrong: 400,
          rowHoverBg: cardBackground,
          headerFilterHoverBg: textOnSecondary,
          colorIcon: textOnCardBackground,
          colorPrimaryTextHover: textOnSecondaryCardBackground,
        },
        Input: {
          colorText: textOnInputBackground,
          colorBorder: inputBorder,
          colorBgBase: inputBackground,
        },
        Alert: {
          colorInfoBg: "#ccf7ff",
          colorErrorBg: "#dd1111",
          colorErrorBorder: "#640000",
          colorWarningBg: "#f5eac5",
          colorText: "#212121",
        },
        Card: {
          colorBgContainer: cardBackground,
          colorBgElevated: cardSecondaryBackground,
          boxShadowTertiary: "1px 1px 3px rgba(0,0,0,0.13)",
          zIndexBase: 20,
          zIndexPopupBase: 20,
          colorText: textOnCardBackground,
          colorTextSecondary: textOnSecondaryCardBackground,
        },
        DatePicker: {
          cellActiveWithRangeBg: secondary,
        },

        Progress: {
          colorText: textOnPrimary,
          colorTextBase: textOnPrimary,
          circleTextColor: textOnPrimary,
          colorInfoText: textOnPrimary,
          colorPrimaryText: textOnPrimary,
          colorTextLabel: textOnPrimary,
          colorTextSecondary: textOnPrimary,
        },
        Select: {
          optionSelectedBg: primary,
          optionSelectedColor: textOnPrimary,
        },
      },
    };
  }
}
