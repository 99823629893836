import { FC, Fragment, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { isInteger, isNumber } from "lodash";
import { EChartsOption, SeriesOption } from "echarts";
import {
  CHART_MIN_HEIGHT_BY_TYPE,
  CHART_MIN_WIDTH_BY_TYPE,
} from "../../../../features/vehicle/domain/usecase/ChartLayout";
import { LinearChartItemConfig } from "../../../domain/type/ChartConfig";
import { DateUtil } from "../../../util/Date";
import { Opts } from "echarts-for-react/lib/types";
import { useAppTheme } from "../../../../app/hook/Theme";
import { HistoricalChartProps } from "./RenderHistorical";

export const LinearHistoricalChart: FC<
  HistoricalChartProps<LinearChartItemConfig, LinearChartItemConfig>
> = ({ chart, data, width, height }) => {
  const { colorPalette } = useAppTheme();
  const opts: Opts = useMemo(() => {
    const splitCharts =
      chart.ChartDefinition.ChartSchema.individualGraphs === true;
    const cols = splitCharts
      ? 1
      : Math.floor(width / CHART_MIN_WIDTH_BY_TYPE.dashboard_linear);
    const rows = splitCharts
      ? 1
      : Math.floor(height / CHART_MIN_HEIGHT_BY_TYPE.dashboard_linear);

    return {
      width,
      height,
    };
  }, [width, height, chart]);

  const baseLines = useMemo<Array<EChartsOption> | EChartsOption>(() => {
    const splitCharts = chart.ChartDefinition.ChartSchema.individualGraphs;
    const usage = chart.ChartDefinition.schema.settings.usage;
    if (splitCharts) {
      if (!usage) return [];
      const titles: { [k: string]: string } = {};
      const multiChartOption: Array<EChartsOption> = usage.map((it) => {
        const { dependency_key, title, color, description } = it;
        titles[dependency_key] = title || dependency_key;
        const series: Array<SeriesOption> = [];
        const currentArray: number[] = [];
        const dateArray: string[] = [];

        data!.forEach((it) => {
          const newDate = DateUtil.fastFormatDate(it.timestamp, "HH:mm:ss");
          currentArray.push(it[dependency_key] || null);
          dateArray.push(newDate);
        });
        series.push({
          type: "line",
          name: dependency_key,
          showSymbol: false,
          symbolSize: 20,
          data: currentArray,
          lineStyle: {
            color,
            type: "solid",
          },
          color,
          smooth: true,
          id: dependency_key,
        });

        return {
          xAxis: {
            type: "category",
            data: dateArray,
            boundaryGap: true,
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colorPalette.textOnCardBackground,
              },
            },
          },
          legend: {
            show: true,
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 10,
              backgroundColor: colorPalette.cardSecondaryBackground,
              borderColor: colorPalette.borderSecondary,
              fillerColor: colorPalette.cardSecondaryBackground,
              handleStyle: {
                color: colorPalette.textOnCardBackground,
              },
              moveHandleStyle: {
                color: colorPalette.textOnCardBackground,
              },
              brushStyle: {
                color: colorPalette.textOnCardBackground,
              },
              textStyle: {
                color: colorPalette.textOnCardBackground,
              },
            },
            {
              start: 0,
              end: 10,
            },
          ],
          yAxis: {
            type: "value",
            boundaryGap: [0, "100%"],
            scale: true,
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colorPalette.textOnCardBackground,
              },
            },
            axisLabel: {
              formatter(value: string | number) {
                return `${
                  isNumber(value)
                    ? isInteger(value)
                      ? value
                      : value.toFixed(2)
                    : value
                }`;
              },
            },
          },
          animationDuration: 150,
          series,
          tooltip: {
            trigger: "axis",
            backgroundColor: colorPalette.cardSecondaryBackground,
            borderColor: colorPalette.borderSecondary,
            textStyle: {
              color: colorPalette.textOnSecondaryCardBackground,
            },
            appendToBody: true,
            formatter(params: any) {
              let html = `<div>
              <p class="font-secondary">${params?.[0]?.axisValue ?? ""}</p>
              `;
              params.forEach((it: any) => {
                html += `
                <div class="my-1">
                  <p class="gap-1 items-center rounded-full px-1 py-0.5" style="background-color: ${it.color}">
                    <span class="font-secondary mix-blend-difference" >${titles[dependency_key]}</span>             
                  </p>  
                  <p class="font-primary px-1">${it.value}</p>
                </div>`;
              });
              html += "</div>";
              return html;
            },
          },
        };
      });

      return multiChartOption;
    } else {
      const titles: { [k: string]: string } = {};
      let series: Array<SeriesOption> = [];
      const dateArray: string[] = [];
      data!.forEach((it) => {
        dateArray.push(it.timestamp as unknown as string);
      });
      if (!!usage) {
        usage.forEach((it) => {
          const { dependency_key, title, color, description } = it;

          const currentArray: number[] = [];

          data!.forEach((it) => {
            currentArray.push(it[dependency_key] || null);
          });

          titles[dependency_key] = title || dependency_key;
          series.push({
            type: "line",
            name: dependency_key,
            data: currentArray,
            lineStyle: {
              color,
            },
            color,
            smooth: true,
            showSymbol: false,
            id: dependency_key,
          });
        });
      }

      return {
        xAxis: {
          type: "category",
          data: dateArray,
          boundaryGap: true,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: colorPalette.textOnCardBackground,
            },
          },
        },
        legend: {
          show: true,
          formatter: (name: string) => {
            return titles[name];
          },
          textStyle: {
            color: colorPalette.textOnCardBackground,
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 10,
            backgroundColor: colorPalette.cardSecondaryBackground,
            borderColor: colorPalette.borderSecondary,
            fillerColor: colorPalette.cardSecondaryBackground,
            handleStyle: {
              color: colorPalette.textOnCardBackground,
            },
            moveHandleStyle: {
              color: colorPalette.textOnCardBackground,
            },
            brushStyle: {
              color: colorPalette.textOnCardBackground,
            },
            textStyle: {
              color: colorPalette.textOnCardBackground,
            },
          },
          {
            start: 0,
            end: 10,
          },
        ],
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          scale: true,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: colorPalette.textOnCardBackground,
            },
          },
          axisLabel: {
            formatter(value: string | number) {
              return `${
                isNumber(value)
                  ? isInteger(value)
                    ? value
                    : value.toFixed(2)
                  : value
              }`;
            },
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: colorPalette.cardSecondaryBackground,
          borderColor: colorPalette.borderSecondary,
          textStyle: {
            color: colorPalette.textOnSecondaryCardBackground,
          },
          appendToBody: true,
          formatter(params: any) {
            let html = `<div>
            <p class="font-secondary">${params?.[0]?.axisValue ?? ""}</p>
            `;
            params.forEach((it: any) => {
              html += `
              <div class="my-1">
                <p class="gap-1 items-center rounded-full px-1 py-0.5" style="background-color: ${
                  it.color
                }">
                  <span class="font-secondary mix-blend-difference" >${
                    titles[it.seriesName]
                  }</span>             
                </p>  
                <p class="font-primary px-1">${it.value}</p>
              </div>`;
            });
            html += "</div>";
            return html;
          },
        },
        series,
      };
    }
  }, [data]);

  return (
    <Fragment>
      {Array.isArray(baseLines) ? (
        baseLines.map((it, idx) => (
          <ReactECharts
            key={`lc-${idx}`}
            option={it}
            opts={opts}
            style={{ width: "100%", height: "100%" }}
          />
        ))
      ) : (
        <ReactECharts option={baseLines} opts={opts} />
      )}
    </Fragment>
  );
};
