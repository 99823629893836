import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Helmet from "react-helmet";
import { ThemeConfig } from "antd";
import "dayjs/locale/es";
import reactUseCookie from "react-use-cookie";
import { AppThemeUseCase } from "../domain/usecase/Theme";
import {
  AppColorPalette,
  AppRegularColorPalette,
  kDEFAULT_REGULAR_COLOR_PALETTE,
} from "../domain/type/AppPalette";
import { useAppCustomer } from "../hook/Customer";

type Themes = {
  primaryTheme: ThemeConfig;
  secondaryTheme: ThemeConfig;
  menuTheme: ThemeConfig;
  secondaryMenuTheme: ThemeConfig;
};

type Value = {
  isDarkThemeEnabled: boolean;
  switchDarkTheme: () => void;
  colorPalette: AppColorPalette;
  updateColorPalette: (palette: AppRegularColorPalette) => void;
  themes: Themes;
};

export const AppThemeContext = createContext<Value>({
  isDarkThemeEnabled: false,
  switchDarkTheme: null!,
  colorPalette: null!,
  updateColorPalette: null!,
  themes: null!,
});

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [palette, setPalette] = useState<AppColorPalette>(
    AppThemeUseCase.createPalette(kDEFAULT_REGULAR_COLOR_PALETTE)
  );
  const [darkMode, setDarkMode] = reactUseCookie(
    "darkMode",
    `${AppThemeUseCase.byDefaultUseDarkTheme()}`
  );
  const { customer } = useAppCustomer();
  const isDark = useMemo(() => darkMode === "true", [darkMode]);
  const [themes, setThemes] = useState<Themes>({
    menuTheme: AppThemeUseCase.getMenuThemeConfig(palette),
    primaryTheme: AppThemeUseCase.getPrimaryThemeConfig(isDark, palette),
    secondaryMenuTheme: AppThemeUseCase.getSecondaryMenuThemeConfig(palette),
    secondaryTheme: AppThemeUseCase.getSecondaryThemeConfig(palette),
  });

  const updateColorPalette = useCallback(
    (base: AppRegularColorPalette) => {
      setPalette(AppThemeUseCase.createPalette(base));
    },
    [setPalette]
  );

  const switchDarkTheme = useCallback(() => {
    setDarkMode(darkMode === "true" ? "false" : "true");
  }, [setDarkMode, darkMode]);

  useEffect(() => {
    setThemes({
      menuTheme: AppThemeUseCase.getMenuThemeConfig(palette),
      primaryTheme: AppThemeUseCase.getPrimaryThemeConfig(isDark, palette),
      secondaryMenuTheme: AppThemeUseCase.getSecondaryMenuThemeConfig(palette),
      secondaryTheme: AppThemeUseCase.getSecondaryThemeConfig(palette),
    });
  }, [isDark, palette]);

  useEffect(() => {
    if (!!customer?.CustomerStyle) {
      const customerStyle = customer.CustomerStyle;
      updateColorPalette({
        colorPrimary: customerStyle.primaryColor,
        colorSecondary: customerStyle.secondaryColor,
        colorTertiary: customerStyle.tertiaryColor,
        colorQuaternary: customerStyle.quaternaryColor,
      });
    } else {
      updateColorPalette(kDEFAULT_REGULAR_COLOR_PALETTE);
    }
  }, [customer, updateColorPalette]);

  const vars = useMemo<{ [k: string]: string }>(() => {
    return {
      "--primary-color": palette.primary,
      "--secondary-color": palette.secondary,
      "--tertiary-color": palette.tertiary,
      "--quaternary-color": palette.quaternary,
      "--text-primary-color": palette.textOnPrimary,
      "--text-secondary-color": palette.textOnSecondary,
      "--text-tertiary-color": palette.textOnTertiary,
      "--text-quaternary-color": palette.textOnQuaternary,
      "--background-color": palette.background,
      "--text-background-color": palette.textOnBackground,
      "--footer-color": palette.footerBackground,
      "--text-footer-color": palette.textOnFooterBackground,
      "--header-color": palette.headerBackground,
      "--text-header-color": palette.textOnHeaderBackground,
      "--sidebar-color": palette.sidebarBackground,
      "--text-sidebar-color": palette.textOnSidebarBackground,
      "--card-color": palette.cardBackground,
      "--text-card-color": palette.textOnCardBackground,
      "--card-secondary-color": palette.cardSecondaryBackground,
      "--text-card-secondary-color": palette.textOnSecondaryCardBackground,
      "--text-title-color": palette.textTitle,
      "--text-subtitle-color": palette.textSubtitle,
      "--text-label-color": palette.textLabel,
      "--text-body-color": palette.textBody,
      "--text-link-color": palette.textLink,
      "--link-hover-color": palette.textLinkHover,
      "--link-active-color": palette.textLinkActive,
      "--link-visited-color": palette.textLinkVisited,
      "--button-primary-color": palette.buttonPrimary,
      "--button-secondary-color": palette.buttonSecondary,
      "--text-button-primary-color": palette.textButtonPrimary,
      "--text-button-secondary-color": palette.textButtonSecondary,
      "--menu-item-color": palette.menuItemBackground,
      "--text-menu-item-color": palette.textOnMenuItemBackground,
      "--menu-item-hover-color": palette.menuItemHoverBackground,
      "--menu-item-active-color": palette.menuItemActiveBackground,
      "--text-menu-item-hover-color": palette.textOnMenuItemHoverBackground,
      "--text-menu-item-active-color": palette.textOnMenuItemActiveBackground,
      "--border-color": palette.border,
      "--border-secondary-color": palette.borderSecondary,
      "--input-border-color": palette.inputBorder,
      "--input-background-color": palette.inputBackground,
      "--text-input-color": palette.textOnInputBackground,
      "--font-primary": "Roboto",
      "--font-secondary": "Poppins",
      "--header-elevated-color": palette.headerElevatedBackground,
      "--text-header-elevated-color": palette.textOnHeaderElevatedBackground,
    };
  }, [palette]);

  return (
    <AppThemeContext.Provider
      value={{
        colorPalette: palette,
        isDarkThemeEnabled: isDark,
        switchDarkTheme,
        themes: themes,
        updateColorPalette,
      }}
    >
      <Helmet>
        <body
          style={
            Object.keys(vars).reduce((prev, curr) => {
              prev += `${curr}:${vars[curr]};`;
              return prev;
            }, "") as any
          }
        />
      </Helmet>
      <div
        className={`w-screen h-screen overflow-hidden ${isDark ? "dark" : ""}`}
      >
        {children}
      </div>
    </AppThemeContext.Provider>
  );
};
