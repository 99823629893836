import { Select } from "antd";
import { SelectProps } from "antd/lib";
import { useFormikContext } from "formik";
import { useCallback } from "react";
type Props<T> = Omit<
  SelectProps<T>,
  "name" | "status" | "value" | "onSelect" | "onClear"
> & {
  name: string;
};

export function ExtendedSelect<T>(p: Props<T>) {
  const { name, ...props } = p;
  const {
    errors,
    initialTouched,
    initialErrors,
    isValidating,
    isInitialValid,
    isSubmitting,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const fieldTouched = (initialTouched as any)[name] || (touched as any)[name];
  const fieldError = (initialErrors as any)[name] || (errors as any)[name];
  const readyToShowStatus = !isValidating || !isSubmitting;
  const fieldValid = isInitialValid || isValid;

  const status: SelectProps["status"] = readyToShowStatus
    ? fieldTouched
      ? fieldError
        ? "error"
        : fieldValid
        ? ""
        : ""
      : ""
    : "";

  const defValue = (values as any)[name] || null;
  const onSelectCaptured: SelectProps<T>["onSelect"] = (value) => {
    setFieldTouched(name, true);
    setFieldValue(name, value);
  };

  const onClearClicked = () => {
    setFieldValue(name, null);
  };

  const onFocused = useCallback(() => {
    setFieldTouched(name, true);
  }, [setFieldTouched, name]);

  return (
    <Select<T>
      status={status}
      value={defValue}
      onSelect={onSelectCaptured}
      onClear={onClearClicked}
      onFocus={onFocused}
      {...props}
    />
  );
}
