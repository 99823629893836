import { FC, useCallback, useEffect } from "react";
import { Card, notification, Form, Button, List, Avatar } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { FormItem } from "../../../../../../core/presentation/component/fields/FormItem";
import { SubmitButton } from "../../../../../../core/presentation/component/fields/SubmitButton";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useUnitAlertViewModel } from "./UnitAlertViewModel.js";
import { ExtendedSelect } from "../../../../../../core/presentation/component/fields/Select";
import { UnitEventItem } from "../../../../../../core/domain/type/UnitEvent";
import { DateUtil } from "../../../../../../core/util/Date";

type Props = {
  vehicle: Vehicle;
};

type FetchData = {
  limit: number;
};

const FORM_VALIDATION = Yup.object({
  limit: Yup.number().required("Este campo es requerido"),
});

const Item: FC<UnitEventItem> = (item) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={DateUtil.fastFormatDate(item.createdAt, "DD HH:mm:ss")}
        description={item.content}
        avatar={<Avatar>{item.eventId}</Avatar>}
      />
    </List.Item>
  );
};

export const VehicleAlertsHistoryTab: FC<Props> = ({ vehicle }) => {
  const {
    eventList,
    fetchEventList,
    fetchEventListState,
    onFetchEventListStateReceived,
  } = useUnitAlertViewModel();

  const onSubmit = useCallback(
    async (data: FetchData) => {
      return fetchEventList(vehicle.id, data.limit);
    },
    [fetchEventList]
  );

  useEffect(() => {
    void fetchEventList(vehicle.id, 50);
  }, []);

  useEffect(() => {
    if (!!fetchEventListState && !fetchEventListState.loading) {
      if (fetchEventListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchEventListState.error?.message,
        });
      }
      onFetchEventListStateReceived();
    }
  }, [fetchEventListState]);

  return (
    <ResponsiveContainer>
      <AppLoader
        loading={!!fetchEventListState && fetchEventListState.loading}
      />
      <div className={"w-full relative overflow-x-hidden flex flex-col gap-2"}>
        <Card bordered>
          <Formik<FetchData>
            initialValues={{
              limit: 50,
            }}
            onSubmit={onSubmit}
            validationSchema={FORM_VALIDATION}
          >
            <Form layout={"vertical"}>
              <FormItem name={"limit"} label={"Límite"}>
                <ExtendedSelect
                  name="limit"
                  options={[
                    {
                      value: 50,
                      label: "50 items",
                    },
                    {
                      value: 100,
                      label: "100 items",
                    },
                    {
                      value: 200,
                      label: "200 items",
                    },
                    {
                      value: 500,
                      label: "500 items",
                    },
                  ]}
                />
              </FormItem>

              <Button.Group>
                <SubmitButton icon={<FontAwesomeIcon icon={faDownload} />}>
                  Obtener alertas
                </SubmitButton>
              </Button.Group>
            </Form>
          </Formik>
        </Card>
        <List
          itemLayout="vertical"
          dataSource={eventList}
          size="large"
          pagination={{
            pageSize: 3,
          }}
          renderItem={Item}
        />
      </div>
    </ResponsiveContainer>
  );
};
