import { App as AntApp } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { AppThemeProvider } from "../context/Theme";
import { AppCustomerProvider } from "../context/Customer";
import { AppResponsiveScreenProvider } from "../context/Responsive";
import { AppUserProvider } from "../context/User";
import { Provider } from "react-redux";
import { store } from "../domain/model/Store";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AppRoutes } from "../../app/domain/implementation/AppRoutes";
dayjs.locale("es");

function App() {
  return (
    <Provider store={store}>
      <AntApp>
        <AppResponsiveScreenProvider>
          <AppCustomerProvider>
            <AppThemeProvider>
              <AppUserProvider>
                <RouterProvider
                  fallbackElement
                  router={createBrowserRouter(AppRoutes)}
                />
              </AppUserProvider>
            </AppThemeProvider>
          </AppCustomerProvider>
        </AppResponsiveScreenProvider>
      </AntApp>
    </Provider>
  );
}

export default App;
