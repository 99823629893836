import { ThemeConfig } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";
import { theme } from "antd";
export class PrimaryMenuThemeGenerator extends AppAntThemeGenerator {
  build(): ThemeConfig {
    const {
      menuItemBackground,
      textOnMenuItemBackground,
      menuItemHoverBackground,
      textOnMenuItemHoverBackground,
      menuItemActiveBackground,
      textOnMenuItemActiveBackground,
      primary,
    } = this.palette;

    return {
      token: {
        colorPrimary: primary,
        colorBorderSecondary: "transparent",
        colorBorder: "transparent",
        colorBgBase: "transparent",
        colorBgContainer: "transparent",
        colorBgElevated: "transparent",
        colorBgLayout: "transparent",
        colorPrimaryBg: "transparent",
        colorBgMask: "transparent",
        colorBgSpotlight: "transparent",
        colorInfoBg: "transparent",
      },
      algorithm: theme.compactAlgorithm,
      components: {
        Menu: {
          colorBgContainer: "transparent",
          colorBgBase: "transparent",
          colorBgMask: "transparent",
          colorPrimaryBg: "transparent",
          colorBgElevated: "transparent",
          colorBgLayout: "transparent",
          colorBgSpotlight: "transparent",
          colorInfoBg: "transparent",
          darkPopupBg: "transparent",
          popupBg: "transparent",
          darkItemBg: menuItemBackground,
          itemBg: menuItemBackground,
          itemSelectedBg: menuItemActiveBackground,
          itemSelectedColor: textOnMenuItemActiveBackground,
          darkItemSelectedBg: menuItemActiveBackground,
          darkItemSelectedColor: textOnMenuItemActiveBackground,
          itemColor: textOnMenuItemBackground,
          darkItemColor: textOnMenuItemBackground,
          itemHoverColor: textOnMenuItemHoverBackground,
          darkItemHoverColor: textOnMenuItemHoverBackground,
          itemHoverBg: menuItemHoverBackground,
          darkItemHoverBg: menuItemHoverBackground,
          itemMarginBlock: 8,
          itemMarginInline: 10,
          itemBorderRadius: 999,
        },
      },
    };
  }
}
