import { Button, Divider, Layout } from "antd";
import {
  FC,
  useState,
  PropsWithChildren,
  useCallback,
  useRef,
  useEffect,
  UIEventHandler,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { DashboardLayoutFooter } from "./DashboardFooter";
import { DashboardLayoutMainMenu } from "./Menu";
import { DashboardLayoutHeaderLarge } from "./HeaderLarge";
import { CustomerLogo } from "../../../../presentation/component/CustomerLogo";
type Props = {
  onActionClick: (action: string) => void;
  collapsed: boolean;
};

const { Sider, Header, Content, Footer } = Layout;
export const DashboardLargeLayout: FC<PropsWithChildren<Props>> = ({
  children,
  onActionClick,
  collapsed,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [headerElevated, setHeaderElevated] = useState<boolean>(false);
  const collapseAction = useCallback(
    () => onActionClick("menu"),
    [onActionClick]
  );
  const scrollCallback = useCallback<UIEventHandler<HTMLDivElement>>(
    (e) => {
      const target = e?.currentTarget || contentRef.current;
      const headerHeight = headerRef.current?.clientHeight || 0;
      const scrollOffset = target.scrollTop;
      if (scrollOffset >= headerHeight) {
        setHeaderElevated((old) => {
          if (old === false) return true;
          return old;
        });
      } else {
        setHeaderElevated((old) => {
          if (old === true) return false;
          return old;
        });
      }
    },
    [setHeaderElevated, headerRef, contentRef]
  );

  useEffect(() => {
    setTimeout(scrollCallback, 500);
  }, []);

  return (
    <Layout className={"w-screen h-screen overflow-hidden"}>
      <Sider
        width={200}
        collapsedWidth={80}
        trigger={null}
        collapsed={collapsed}
        className="relative"
      >
        <Button
          type="primary"
          onClick={collapseAction}
          icon={<FontAwesomeIcon icon={collapsed ? faChevronRight : faClose} />}
          className="absolute right-0 translate-x-1/2 mt-4"
          style={{
            zIndex: 1000,
          }}
        />
        <div
          className={`overflow-hidden transition-all duration-1000 w-full pt-12 pb-5 px-4 ${
            collapsed ? "opacity-0" : "opacity-100"
          }`}
        >
          <CustomerLogo />
        </div>

        <DashboardLayoutMainMenu />
      </Sider>
      <Layout
        className="overflow-x-hidden h-screen overflow-y-auto"
        ref={contentRef}
        onScroll={scrollCallback}
        onScrollCapture={scrollCallback}
      >
        <Header className={"sticky top-0"} ref={headerRef}>
          <DashboardLayoutHeaderLarge
            elevated={headerElevated}
            collapsed={collapsed}
            onActionClick={onActionClick}
          />
        </Header>
        <Layout>
          <Content className="flex flex-col">
            <div className="flex-1">{children}</div>
            <Footer className="mt-3">
              <DashboardLayoutFooter />
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
