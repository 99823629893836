import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Spin, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faHashtag,
  faTag,
  faNetworkWired,
  faHdd,
  faSatellite,
  faWifi,
  faTemperatureArrowUp,
  faBattery,
} from "@fortawesome/free-solid-svg-icons";
import { Divider } from "antd/lib";
import { toNumber } from "lodash";
import { GatewayInfo, OnlineGateway } from "../../../domain/type/Gateway";
import { SocketIOService } from "../../../../../app/service/SocketIO";
import { ResponsiveContainer } from "../../../../../core/presentation/component/Container";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../../../../core/presentation/component/DescriptionsList";
import { CustomNavigator } from "../../../../../core/presentation/component/CustomLink";
import { DateUtil } from "../../../../../core/util/Date";
import { CircularProgress } from "../../../../../core/presentation/component/Progress";
import { LiveDot } from "../../../../../core/presentation/component/LiveDot";

type Props = {
  gateway: OnlineGateway;
};

export const GatewayMainTab: FC<Props> = ({ gateway }) => {
  const [gatewayInfo, setGatewayInfo] = useState<GatewayInfo | null>(
    gateway.info
  );
  const [online, setOnline] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onTick = useCallback(() => {
    if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
    setOnline(false);
  }, [setOnline, timeoutRef]);

  const listener1 = useCallback(
    (data: any) => {
      setGatewayInfo(data);
      setOnline(true);
      if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(onTick, 5000);
    },
    [setOnline, timeoutRef]
  );

  const listener2 = useCallback(() => {
    setOnline(true);
    if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(onTick, 5000);
  }, [setOnline, timeoutRef]);

  useEffect(() => {
    const key = `data/${gateway.key}/`;
    SocketIOService.socketOn(`${key}gateway_info`, listener1);
    SocketIOService.socketOn(`${key}gps_live`, listener2);
    return () => {
      SocketIOService.socketOff(key, listener1);
      SocketIOService.socketOff(key, listener2);
      if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <ResponsiveContainer>
      <DescriptionsList rowKey={"gateway"}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Nombre"}
          label={gateway.key}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={gateway.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Descripción"}
          label={gateway.description}
          itemKey={"description"}
        />
        {gateway.Unit && (
          <CustomNavigator to={`/dashboard/vehicles/${gateway.Unit.id}`}>
            <DescriptionsItem
              icon={<FontAwesomeIcon icon={faCar} />}
              title={"Vehiculo"}
              content={<Typography.Link>{gateway.Unit?.name}</Typography.Link>}
            />
          </CustomNavigator>
        )}
      </DescriptionsList>
      <Divider />
      {online ? (
        <Typography.Text type={"success"} className="flex flex-row gap-2">
          <LiveDot /> El gateway se encuentra en funcionamiento
        </Typography.Text>
      ) : (
        <Typography.Text type={"secondary"} className="flex flex-row gap-2">
          <Spin /> El gateway no tiene conexión a internet. Esperando
          activación...
        </Typography.Text>
      )}
      <Divider plain orientationMargin={0} className="border-0 my-2" />
      <Typography.Text type={"secondary"} className="mb-4">
        Última actualización{" "}
        {gatewayInfo &&
          DateUtil.fastFormatDate(gatewayInfo.date, "DDD 'a las' HH:mm:ss")}
      </Typography.Text>
      <Divider plain orientationMargin={0} className="border-0 my-2" />
      {!!gatewayInfo && (
        <DescriptionsList>
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faNetworkWired} />}
            title={"Dirección IP"}
            label={gatewayInfo.eth_ip}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faHdd} />}
            title={"Espacio total"}
            label={gatewayInfo.total_space}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faHdd} />}
            title={"Espacio libre"}
            label={gatewayInfo.free_space}
          />
          <DescriptionsItem
            icon={
              <CircularProgress
                percent={Math.round(
                  toNumber(gatewayInfo.memory_usage_percent || "0")
                )}
              />
            }
            title={"Alm. en uso"}
            label={`${toNumber(
              gatewayInfo.memory_usage_percent || "0"
            ).toPrecision(4)}%`}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faSatellite} />}
            title={"Satélites conectados"}
            label={gatewayInfo.num_gps_sat}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faWifi} />}
            title={"Ping"}
            label={`${gatewayInfo.ping_acme} ms`}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faTemperatureArrowUp} />}
            title={"Temperatura"}
            label={`${gatewayInfo.temperature}°`}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faBattery} />}
            title={"Batería"}
            label={`${gatewayInfo.vbat}%`}
          />
        </DescriptionsList>
      )}
      <Divider />
    </ResponsiveContainer>
  );
};
