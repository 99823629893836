import { useState } from "react";
import { AuthRepository } from "../data/repository/Auth";
import { CustomerRepository } from "../../customer/data/repository/Customer";
import { AppTaskState } from "../../../app/domain/type/State";
import { Customer } from "../../customer/domain/type/Customer";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { LoginData, PostLoginData } from "../domain/type/Login";
import { FindCustomerByEmailData } from "../domain/type/Customer";
import useCookie from "react-use-cookie";
import { isNil } from "lodash";

export function useLoginViewModel() {
  const [loginState, setLoginState] = useState<AppTaskState<boolean> | null>(
    null
  );
  const [postLoginState, setPostLoginState] =
    useState<AppTaskState<boolean> | null>(null);
  const [resendCodeState, setResendCodeState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchCustomerState, setFetchCustomerState] =
    useState<AppTaskState<Customer> | null>(null);
  const [loginEmail, setLoginEmail] = useCookie("loginEmail", "");
  const [loginSnapshot, setLoginSnapshot] = useState<LoginData | null>(null);
  const [codeDuration, setCodeDuration] = useState<number>(0);

  async function login(data: LoginData) {
    setLoginState(AppTaskStateG.loading());
    try {
      setLoginEmail(data.email);
      const result = await AuthRepository.login(data);
      if (result?.data?.mfa === true) {
        setCodeDuration(result.data?.expiration ?? 0);
        setLoginState(AppTaskStateG.success(true));
        setLoginSnapshot(data);
      } else {
        setLoginState(AppTaskStateG.success(true));
        setPostLoginState(AppTaskStateG.success(true));
      }
      return true;
    } catch (e: any) {
      setLoginState(AppTaskStateG.error(e));
    }
  }

  function onLoginStateReceived() {
    setLoginState(null);
  }

  async function verificateCode(data: PostLoginData) {
    setPostLoginState(AppTaskStateG.loading());
    try {
      await AuthRepository.postLogin(data);
      setPostLoginState(AppTaskStateG.success(true));
      return true;
    } catch (e: any) {
      setPostLoginState(AppTaskStateG.error(e));
    }
  }

  function onPostLoginStateReceived() {
    setPostLoginState(null);
  }

  async function resendCode() {
    setResendCodeState(AppTaskStateG.loading());
    if (isNil(loginSnapshot)) {
      setResendCodeState(
        AppTaskStateG.error(
          new Error("No se ha encontrado un correo electrónico.")
        )
      );
      return;
    }
    try {
      const result = await AuthRepository.login(loginSnapshot);
      setCodeDuration(result?.data?.expiration ?? 0);
      setResendCodeState(AppTaskStateG.success(true));
    } catch (e: any) {
      setResendCodeState(AppTaskStateG.error(e));
    }
  }

  function onResendCodeStateReceived() {
    setResendCodeState(null);
  }

  async function fetchCustomerDomain({ email }: FindCustomerByEmailData) {
    if (fetchCustomerState?.loading) return;
    setFetchCustomerState(AppTaskStateG.loading());
    try {
      const style = await CustomerRepository.getCustomerDomainByEmail(email);
      if (style.ok) {
        setFetchCustomerState(AppTaskStateG.success(style.data!!));
        setLoginEmail(email);
        return true;
      } else {
        setFetchCustomerState(
          AppTaskStateG.error(
            new Error(style?.message ?? "Ha ocurrido un error.")
          )
        );
      }
    } catch (e: any) {
      setFetchCustomerState(AppTaskStateG.error(e));
    }
  }

  function onFetchCustomerDomainStateReceived() {
    setFetchCustomerState(null);
  }

  function resetLoginEmail() {
    setLoginEmail("");
  }

  return {
    loginState,
    postLoginState,
    login,
    onLoginStateReceived,
    verificateCode,
    onPostLoginStateReceived,
    onResendCodeStateReceived,
    resendCodeState,
    resendCode,
    fetchCustomerState,
    fetchCustomerDomain,
    onFetchCustomerDomainStateReceived,
    loginEmail,
    resetLoginEmail,
    codeDuration,
  };
}
