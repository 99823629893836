import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { Customer } from "../../domain/type/Customer";
import { CustomerApiService } from "../../../../app/service/RemoteClient";
import { CustomerStyle } from "../../domain/type/CustomerStyle";

export class RemoteCustomerSource extends BaseRemoteSource {
  static async getCustomer(): Promise<RemoteSourceResponse<Customer>> {
    try {
      const customer = await CustomerApiService.getWithAuth("/info");
      return customer.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerStyle(): Promise<
    RemoteSourceResponse<CustomerStyle>
  > {
    try {
      const customer = await CustomerApiService.getWithAuth(
        "/customer_style/get"
      );
      this.checkResponseCredentials(customer);
      return customer.data as RemoteSourceResponse<CustomerStyle>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerDomainByEmail(
    email: string
  ): Promise<RemoteSourceResponse<Customer>> {
    try {
      const customerDomain = await CustomerApiService.postWithAuth("/info", {
        email,
      });
      return customerDomain.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
